import { Grid, Slider, TextField, Tooltip, Typography } from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ColorPicker } from "mui-color";

import { makeStyles } from "@kuva/ui-components";

import { MLScoreActions } from "~/common/store/slices/ml-score";
import {
  selectMLScoreRanges,
  selectMLScoreColors
} from "~/common/selectors/ml-score-selector";

const useStyles = makeStyles()({
  colorPickerWrapper: {
    marginRight: "30px",
    "& .MuiInputBase-root": {
      width: "60px"
    }
  }
});

export const MLScoreSettings = () => {
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const selectedMLScoreRanges = useSelector(selectMLScoreRanges, shallowEqual);
  const selectedMLScoreColors = useSelector(selectMLScoreColors, shallowEqual);

  const handleMLScoreColor = (value, index) => {
    let tmp = [...selectedMLScoreColors];
    tmp[index] = `#${value.hex}`;
    dispatch(MLScoreActions.setMLScoreColors(tmp));
  };

  const handleMLScoreValues = (index, newValue) => {
    const currentMLScoreRanges = [...selectedMLScoreRanges];
    currentMLScoreRanges[index] = newValue;
    dispatch(MLScoreActions.setMLScoreRanges(currentMLScoreRanges));
  };

  const scoreLabels = ["Low", "High"];

  return (
    <>
      {selectedMLScoreRanges.map((range, index) => (
        <Grid key={index} container spacing={2}>
          <Grid item xs={12}>
            <Tooltip title="">
              <Typography>{scoreLabels[index]}</Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={10}>
            <Slider
              min={0}
              max={1}
              step={0.1}
              value={range}
              onChange={(_event, value) => handleMLScoreValues(index, value)}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              variant="standard"
              value={range}
              disabled
              size="small"
              type="number"
            />
          </Grid>
        </Grid>
      ))}

      <Grid item xs={12}>
        <Typography>ML Score Color Scheme</Typography>
      </Grid>

      {selectedMLScoreColors.map((color, index) => (
        <Tooltip
          key={index}
          title={
            index === 0
              ? `Below ${selectedMLScoreRanges[0]}`
              : scoreLabels[index - 1]
          }
        >
          <Grid item xs={3} className={classes.colorPickerWrapper}>
            <ColorPicker
              onChange={color => handleMLScoreColor(color, index)}
              value={color}
            />
          </Grid>
        </Tooltip>
      ))}
    </>
  );
};
