import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()((_theme, { editingAlarmDate, hoveredScan }) => ({
  root: {
    flex: "0 0 4em",
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  smallMargin: {
    marginLeft: "2%"
  },
  disableEmailNotif: {
    fontSize: "12px",
    color: !editingAlarmDate && "grey"
  },
  actionButtonWrapper: {
    display: "flex",
    marginLeft: "auto"
  },
  actionButton: {
    margin: "5px",
    width: "130px",
    minHeight: "52px",
    opacity: hoveredScan ? "1" : ".3"
  }
}));

export { useStyles };
