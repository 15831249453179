export const scrollbarStyles = {
  "&::-webkit-scrollbar": {
    backgroundColor: "#222222",
    width: "8px"
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#A6A6A6",
    borderRadius: "5px",
    width: "8px"
  }
};
