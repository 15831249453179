import { makeStyles } from "@kuva/ui-components";

const GlobalStyle = makeStyles()({
  /* Add Global styles here so we can reuse them */
  font14: {
    fontSize: 14
  },
  uppercase: {
    textTransform: "uppercase"
  },
  dataBox: { whiteSpace: "nowrap", margin: 10, padding: 10 },
  dataBoxTitle: {
    textAlign: "center",
    fontSize: 14
  },
  formControlSelect: {
    margin: "0 5px",
    whiteSpace: "nowrap",
    width: 75
  },
  panoramaContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "75%",
    padding: 8
  },
  imageNavWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "75%"
  },
  carouselWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "80%"
  },
  carouselImagePlaceholder: {
    maxWidth: "100%",
    opacity: 0,
    marginRight: "1em",
    flex: "none"
  },
  legacyImgDiv: {
    height: "100%",
    maxWidth: "100%",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  legacyImgContainer: {
    display: "flex",
    flexDirection: "row",
    height: "95%",
    width: "100%"
  },
  legacyImgSubContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 10px"
  },
  legacyToggleBtn: {
    marginRight: "2rem"
  }
});

export { GlobalStyle };
