import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

/** @function DeleteDialog
 * The Custom Dialog returns a draggable dialog that confirms a customizable action.
 * 
 * @param {boolean} show: determines when the dialog box should display
   @param {func} onConfirm: function to be called when 'Confirm'-custom button is pressed
   @param {func} onCancel: function to be called when 'Cancel' button is pressed
   @param {string} title: header for the dialog box
   @param {string} text: text for the dialog box
   @param {string} confirmButtonText: custom text for the confirm button
   @param {string} declineButtonText: custom text for the decline/cancel button
 */

export default function CustomDialog(props) {
  return (
    <div>
      <Dialog
        open={props.show}
        onClose={props.handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ fontSize: "15px" }}>
            {props.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            role="closeCustomDialog"
            autoFocus
            onClick={props.onCancel}
            color="primary"
          >
            {props.declineButtonText ?? "Cancel"}
          </Button>
          <Button
            role="confirmButtonDialog"
            onClick={props.onConfirm}
            color="primary"
          >
            {props.confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

CustomDialog.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  handleClose: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  declineButtonText: PropTypes.string
};
