import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import PropTypes from "prop-types";

import { useStyles } from "../styles";

const ConfirmDialog = ({
  tag,
  handleClose,
  tagFunction,
  open,
  confirmType
}) => {
  const { classes } = useStyles();

  if (!tag) return null;

  return (
    <Dialog
      disableEnforceFocus
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={"xs"}
    >
      <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
        {confirmType} Tag
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText className={classes.subtitle}>
          {"Are you sure you want to " +
            confirmType.toLowerCase() +
            " tag: " +
            tag.text +
            "?"}
        </DialogContentText>
        <DialogActions className={classes.dialogActions}>
          <Button
            role="button"
            type="submit"
            className={classes.redBtn}
            color="primary"
            onClick={() => {
              tagFunction(tag.id);
              handleClose();
            }}
          >
            {confirmType}
          </Button>
          <Button
            role="button"
            className={classes.grayBtn}
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  tag: PropTypes.shape({
    createdOn: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    orgId: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    updatedOn: PropTypes.string.isRequired
  }),
  handleClose: PropTypes.func.isRequired,
  tagFunction: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  confirmType: PropTypes.string.isRequired
};

export default ConfirmDialog;
