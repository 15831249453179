import {
  Grid,
  MenuItem,
  Popover,
  Slider,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import some from "lodash/some";
import { ColorPicker } from "mui-color";
import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";

import { ML_SCORE_SLIDER } from "../Slider/MLScoreSlider/constants";

import { MLScoreSettings } from "./MLScoreSettings";

const SliderSettingsDialog = ({
  open,
  handleClose,
  anchorEl,
  sliderType,
  setSliderType,
  noiseFloor,
  setNoiseFloor,
  noiseFloorColors,
  setNoiseFloorColors,
  confidenceColors,
  setConfidenceColors,
  confidenceValues,
  setConfidenceValues,
  noiseFloorMin,
  setNoiseFloorMin,
  scanResults
}) => {
  const hasMLScore = useMemo(() => {
    return some(scanResults, frame => frame.mlScore?.score !== undefined);
  }, [scanResults]);

  useEffect(() => {
    if (!hasMLScore) {
      setSliderType("default");
    }
  }, []);

  const handleSliderType = event => {
    setSliderType(event.target.value);
    sessionStorage.setItem("sliderType", event.target.value);
  };

  const handleConfValues = (event, value) => {
    setConfidenceValues(value);
    sessionStorage.setItem("confidenceValues", value);
  };

  const handleConf = (event, index) => {
    let tmp = [...confidenceValues];
    tmp[index] = event.target.value;
    setConfidenceValues(tmp);
  };

  const handleNoiseFloor = event => {
    setNoiseFloor(Number(event.target.value));
    sessionStorage.setItem("noiseFloor", event.target.value);
  };

  const handleNoiseFloorSlider = (event, value) => {
    setNoiseFloor(value);
    sessionStorage.setItem("noiseFloor", value);
  };

  const handleNoiseFloorMin = event => {
    setNoiseFloorMin(Number(event.target.value));
    sessionStorage.setItem("noiseFloorMin", event.target.value);
  };

  const handleNoiseFloorMinSlider = (event, value) => {
    setNoiseFloorMin(value);
    sessionStorage.setItem("noiseFloorMin", value);
  };

  const handleConfColor = (value, index) => {
    let tmp = [...confidenceColors];
    let color = "#";
    tmp[index] = color.concat(value.hex);
    setConfidenceColors(tmp);
    sessionStorage.setItem("confColors", tmp);
  };

  const handleNoiseColor = (value, index) => {
    let tmp = [...noiseFloorColors];
    let color = "#";
    tmp[index] = color.concat(value.hex);
    setNoiseFloorColors(tmp);
    sessionStorage.setItem("noiseColors", tmp);
  };

  return (
    <Popover open={open} onClose={handleClose} anchorEl={anchorEl}>
      <Grid
        container
        spacing={1}
        // alignItems="center"
        alignContent="space-between"
        style={{
          margin: 4,
          ...(sliderType !== "default" &&
            sliderType !== "alarms" && {
              width: 360
            })
        }}
      >
        <Grid item xs={6}>
          <Tooltip title="Changes Slider Type">
            <Typography> Slider Type </Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="standard"
            select
            value={sliderType}
            onChange={handleSliderType}
            size="small"
          >
            <MenuItem value="default">Default</MenuItem>
            <MenuItem value="confidence">Confidence</MenuItem>
            <MenuItem value="noiseFloor">Noise Floor</MenuItem>
            <MenuItem value="alarms">Alarms</MenuItem>
            {hasMLScore && (
              <MenuItem value={ML_SCORE_SLIDER}>ML Score</MenuItem>
            )}
          </TextField>
        </Grid>
        {sliderType === "confidence" && (
          <>
            <Grid item xs={12}>
              <Typography> Confidence Color Scheme </Typography>
            </Grid>
            <Tooltip title={`0-${confidenceValues[0]}`}>
              <Grid item xs={3}>
                <ColorPicker
                  onChange={color => handleConfColor(color, 0)}
                  value={confidenceColors[0]}
                />
              </Grid>
            </Tooltip>
            <Tooltip
              title={`${confidenceValues[0] + 1}-${confidenceValues[1]}`}
            >
              <Grid item xs={3}>
                <ColorPicker
                  onChange={color => handleConfColor(color, 1)}
                  value={confidenceColors[1]}
                />
              </Grid>
            </Tooltip>
            <Tooltip
              title={`${confidenceValues[1] + 1}-${confidenceValues[2]}`}
            >
              <Grid item xs={3}>
                <ColorPicker
                  onChange={color => handleConfColor(color, 2)}
                  value={confidenceColors[2]}
                />
              </Grid>
            </Tooltip>
            <Tooltip title={`${confidenceValues[2] + 1}+`}>
              <Grid item xs={3}>
                <ColorPicker
                  onChange={color => handleConfColor(color, 3)}
                  value={confidenceColors[3]}
                />
              </Grid>
            </Tooltip>

            <Grid item xs={12}>
              <Slider
                min={0}
                max={250}
                onChange={handleConfValues}
                value={confidenceValues}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="standard"
                value={confidenceValues[0]}
                onChange={event => handleConf(event, 0)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="standard"
                value={confidenceValues[1]}
                onChange={event => handleConf(event, 1)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="standard"
                value={confidenceValues[2]}
                onChange={event => handleConf(event, 2)}
              />
            </Grid>
          </>
        )}
        {sliderType === "noiseFloor" && (
          <>
            <Grid item xs={12}>
              <Tooltip title="">
                <Typography> Noise Floor Minimum </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={10}>
              <Slider
                min={0}
                max={5000}
                value={noiseFloorMin}
                onChange={handleNoiseFloorMinSlider}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                variant="standard"
                value={noiseFloorMin}
                onChange={handleNoiseFloorMin}
                size="small"
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <Tooltip title="">
                <Typography> Noise Floor Threshold </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={10}>
              <Slider
                min={0}
                max={5000}
                value={noiseFloor}
                onChange={handleNoiseFloorSlider}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                variant="standard"
                value={noiseFloor}
                onChange={handleNoiseFloor}
                size="small"
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography> Noise Floor Color Scheme </Typography>
            </Grid>
            <Tooltip title="No Scanning">
              <Grid item xs={3}>
                <ColorPicker
                  onChange={color => handleNoiseColor(color, 0)}
                  value={noiseFloorColors[0]}
                />
              </Grid>
            </Tooltip>
            <Tooltip
              title={`Scanning but no noise floor metric exists or noise floor is below min [${noiseFloorMin}]`}
            >
              <Grid item xs={3}>
                <ColorPicker
                  onChange={color => handleNoiseColor(color, 1)}
                  value={noiseFloorColors[1]}
                />
              </Grid>
            </Tooltip>
            <Tooltip title="Scanning and noise floor is below threshold">
              <Grid item xs={3}>
                <ColorPicker
                  onChange={color => handleNoiseColor(color, 2)}
                  value={noiseFloorColors[2]}
                />
              </Grid>
            </Tooltip>
            <Tooltip title="Scanning and noise floor is above or equal to threshold">
              <Grid item xs={3}>
                <ColorPicker
                  onChange={color => handleNoiseColor(color, 3)}
                  value={noiseFloorColors[3]}
                />
              </Grid>
            </Tooltip>
          </>
        )}
        {sliderType === ML_SCORE_SLIDER && <MLScoreSettings />}
      </Grid>
    </Popover>
  );
};

SliderSettingsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.object,
  sliderType: PropTypes.string.isRequired,
  setSliderType: PropTypes.func.isRequired,
  noiseFloor: PropTypes.number.isRequired,
  setNoiseFloor: PropTypes.func.isRequired,
  noiseFloorColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  setNoiseFloorColors: PropTypes.func.isRequired,
  confidenceColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  setConfidenceColors: PropTypes.func.isRequired,
  confidenceValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  setConfidenceValues: PropTypes.func.isRequired,
  noiseFloorMin: PropTypes.number.isRequired,
  setNoiseFloorMin: PropTypes.func.isRequired,
  scanResults: PropTypes.array
};

export default SliderSettingsDialog;
