import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()({
  addTagBtn: {
    marginTop: "30px",
    marginBottom: "15px",
    padding: "10px 20px",
    fontSize: "15px"
  },
  dialogTitle: {
    backgroundColor: "#212121",
    color: "white"
  },
  dialogContent: {
    height: "auto",
    backgroundColor: "#212121",
    color: "white"
  },
  inputField: {
    marginBottom: "15px"
  },
  dialogActions: {
    backgroundColor: "#212121",
    color: "white",
    paddingBottom: "20px"
  },
  menuItem: {
    fontSize: "18px"
  },
  subtitle: {
    fontSize: "15px",
    color: "white"
  },
  redBtn: {
    align: "right",
    color: "red",
    border: "2px red solid"
  },
  greenBtn: {
    align: "right",
    color: "yellowgreen",
    border: "2px yellowgreen solid"
  },
  grayBtn: {
    align: "right",
    color: "gray",
    border: "2px gray solid"
  }
});

export { useStyles };
