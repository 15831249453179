import PropTypes from "prop-types";

import loading from "../../media/loading.svg";

export const Loading = ({ style }) => (
  <div className="spinner" style={style}>
    <img src={loading} alt="Loading" />
  </div>
);

Loading.propTypes = {
  style: PropTypes.object
};
