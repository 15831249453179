import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()({
  mainWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
});

export { useStyles };
