import { createAsyncThunk } from "@reduxjs/toolkit";

import CameraAPI from "~/common/apis/CameraAPI";

const thunk = async ({ orgId, baseOrganization }) => {
  const response = await CameraAPI.get(orgId, baseOrganization);
  return { response };
};

export const fetchIotHubCameras = createAsyncThunk(
  "cameras/get-iot-hub-cameras",
  thunk
);
