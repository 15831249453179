// Convert minutes from base 60 (00-59) to decimal (0.00-0.99)
const normalizeTime = time => {
  time = new Date(time);
  const hours = time.getUTCHours();
  const minutes = time.getUTCMinutes();
  const decimalMinutes = minutes / 60;
  return (hours + decimalMinutes).toFixed(2);
};

// Convert minutes from decimal (0.00-0.99) to base 60 (00-59)
const unnormalizeTime = value => {
  const hours = Math.floor(value);
  const decimalMinutes = value % 1;
  const minutes = Math.round(decimalMinutes * 60);
  return `${("00" + hours).slice(-2)}:${("00" + minutes).slice(-2)}`;
};

// Returns an extra color block to prevent non-background colors from stretching over a gap in the gradient
const checkTimeGap = (index, scanResults, percentage, confidenceColors) => {
  // Checks if the next frame is more than 1% of the bar away
  if (
    index < scanResults.length - 1 &&
    (normalizeTime(
      scanResults[scanResults.length - 1 - (index + 1)].createdOn
    ) /
      24) *
      100 -
      percentage >
      1
  ) {
    // Returns an extra 0.2% background color block before the gap
    return `,${confidenceColors[0]} ${(percentage + 0.2).toFixed(2)}% ${(
      percentage + 0.4
    ).toFixed(2)}%`;
  } else {
    return "";
  }
};

export { checkTimeGap, normalizeTime, unnormalizeTime };
