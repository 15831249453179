import { useState, useEffect } from "react";

/**
 * @name useSessionState
 * @description This function is a custom hook similar to useState hook. The only difference is the state is persisted using session storage
 *
 * @author Jan A.
 * @date 12/07/2022
 *
 * @param key - the sesssion storage key
 * @param initialValue - the initial value
 *
 * @returns {state,function}
 */

export default function useSessionState(key, initialValue) {
  const [state, setState] = useState(() => {
    const jsonValue = sessionStorage.getItem(key);
    if (
      jsonValue !== null &&
      jsonValue !== "undefined" &&
      jsonValue !== undefined
    )
      return JSON.parse(jsonValue);
    return initialValue;
  });

  //update session storage every time the value is updated
  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
}
