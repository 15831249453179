import { Auth0Provider } from "@auth0/auth0-react";
import { GrowthBookProvider } from "@growthbook/growthbook-react";
import { LicenseInfo } from "@mui/x-license-pro";
import { createBrowserHistory } from "history";
import { SnackbarProvider } from "notistack";
import { StrictMode, useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { ThemeProvider, createTheme } from "@kuva/ui-components";

import KuvaTheme from "~/common/Theme";
import { appPersistStore, store } from "~/common/store/configureStore";
import { featureFlagClient } from "~/feature-flag-client";

import "./index.css";
import App from "./main/App";

const theme = createTheme(KuvaTheme);

const history = createBrowserHistory();
const onRedirectCallback = appState => {
  history.push(appState?.returnTo ?? window.location.pathname);
};

LicenseInfo.setLicenseKey(
  "3faddbd6694bbbb643559b93ef882f00Tz01ODE3MyxFPTE3MDU4NTc1ODE3NjksUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const Application = () => {
  useEffect(() => {
    featureFlagClient.loadFeatures();
  }, []);

  return (
    <StrictMode>
      <GrowthBookProvider growthbook={featureFlagClient}>
        <Provider store={store}>
          <PersistGate persistor={appPersistStore}>
            <SnackbarProvider autoHideDuration={5000} maxSnack={7}>
              <Auth0Provider
                domain={String(process.env.REACT_APP_AUTH0_DOMAIN_URL)}
                clientId={String(process.env.REACT_APP_AUTH0_CLIENT_ID)}
                onRedirectCallback={onRedirectCallback}
                authorizationParams={{
                  redirect_uri: window.location.origin,
                  audience: process.env.REACT_APP_AUTH0_API_ID
                }}
              >
                <ThemeProvider theme={theme}>
                  <App />
                </ThemeProvider>
              </Auth0Provider>
            </SnackbarProvider>
          </PersistGate>
        </Provider>
      </GrowthBookProvider>
    </StrictMode>
  );
};

ReactDOM.render(<Application />, document.getElementById("root"));
