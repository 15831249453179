import { createSlice } from "@reduxjs/toolkit";

import { getCameraFovImage } from "./thunks/get-camera-fov-image";

const initialState = {
  fovImages: {
    byDeviceId: {}
  }
};

const slice = createSlice({
  name: "camerasFov",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCameraFovImage.fulfilled, (state, action) => {
      const { deviceId, fovImage, poiDegrees } = action.payload;
      const imageKey = poiDegrees ?? "pano";

      state.fovImages.byDeviceId[deviceId] = {
        ...state.fovImages.byDeviceId[deviceId],
        [imageKey]: fovImage
      };
    });
  }
});

export const CameraFovActions = {
  ...slice.actions,
  getCameraFovImage
};

export const CamerasFovReducer = slice.reducer;
