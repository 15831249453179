import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()({
  header: {
    color: "white",
    fontSize: "18px"
  },
  row: {
    color: "white"
  }
});

export { useStyles };
