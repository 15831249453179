import { Box, LinearProgress, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import CameraAPI from "~/common/apis/CameraAPI";
import { UTCDatePicker } from "~/common/components";
import { getSelectedCamera } from "~/common/selectors/CameraSelector";
import { getPois, getValueFromSessionStorage } from "~/common/utils/dataUtils";
import { useOrganization, useAlgorithmRecords } from "~/hooks";
import { getNearestTimeFrame } from "~/pages/SessionReviewerPage/utils";

import ImageList from "./ImageList";

const TaggingPage = ({
  addEditAlarm,
  eventsLoading,
  showNonDetections,
  eventConf,
  minuteGap,
  frameMinimum,
  paddedFrames,
  setEventsLoading,
  handleDateChange,
  endDate,
  resetAlarmCreationSession,
  setReviewerMode
}) => {
  const history = useHistory();
  const location = useLocation();
  const { deviceId } = useParams();
  const { selectedOrg } = useOrganization();
  const { blobContainer, withScanResults, algoHash } = useAlgorithmRecords();

  const index = getValueFromSessionStorage("scanIndex", 0);
  const [scanIndex, setScanIndex] = useState(index);

  const [events, setEvents] = useState([]);
  const [scanResults, setScanResults] = useState([]);
  const [displayedScanResults, setDisplayedScanResults] = useState([]);
  const [selectedPoi, setSelectedPoi] = useState(
    sessionStorage.getItem("selectedPoi") ?? "All"
  );

  const selectedCamera = useSelector(getSelectedCamera, shallowEqual);

  const { enqueueSnackbar } = useSnackbar();

  const getEventsArray = useCallback(() => {
    setScanResults([]);
    setDisplayedScanResults([]);
    // Use params if available otherwise use last selected device
    const defaultDeviceId = selectedCamera?.deviceId ?? deviceId;

    if (defaultDeviceId) {
      // TODO: we should be using min confidence for this call
      const startDate = endDate?.substring(0, 10) + "T00:00:00Z";
      CameraAPI.getEvents({
        deviceId: defaultDeviceId,
        showNonDetections,
        startDate,
        endDate,
        eventConf,
        minuteGap,
        frameMinimum,
        paddedFrames,
        blobContainer,
        withScanResults,
        algoHash
      })
        .then(response => {
          setEvents(response.data.events.reverse());
          setScanResults(response.data.scanResults);
        })
        .catch(error => {
          console.error(error);
          setScanResults([]);
          enqueueSnackbar("Error: Fetching Scans Failed.", {
            variant: "error"
          });
        })
        .finally(() => {
          setEventsLoading(false);
        });
    } else {
      setEventsLoading(false);
      setScanResults([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCamera,
    endDate,
    showNonDetections,
    eventConf,
    minuteGap,
    frameMinimum,
    paddedFrames
  ]);

  useEffect(() => {
    if (!selectedOrg || !selectedCamera) return;
    setEventsLoading(true);
    getEventsArray();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedOrg,
    selectedCamera,
    endDate,
    showNonDetections,
    eventConf,
    minuteGap,
    frameMinimum,
    paddedFrames
  ]);

  const poiKeys = useMemo(() => {
    return getPois(scanResults);
  }, [scanResults]);

  useEffect(() => {
    if (!scanResults.length) {
      setDisplayedScanResults([]);
    } else {
      const prevPoi = sessionStorage.getItem("selectedPoi");
      if (selectedPoi === "All") {
        const unfilteredScanResults = [...scanResults];

        if (index < 0) {
          setScanIndex(unfilteredScanResults.length - 1);
        } else {
          //Set the nearest frame to the newly selected POI only if the POI changes.
          if (prevPoi !== selectedPoi) {
            const index = getNearestTimeFrame(
              unfilteredScanResults,
              selectedPoi
            );
            setScanIndex(index);
          } else {
            // If POI didn't change, reload the previous timeframe
            if (sessionStorage.getItem("scanIndex")) {
              setScanIndex(Number(sessionStorage.getItem("scanIndex")));
            } else if (
              history?.location?.state?.from?.action === "GO_TO_EVENT"
            ) {
              setScanIndex(history?.location?.state?.from?.scanIndex);
            } else {
              setScanIndex(unfilteredScanResults.length - 1);
            }
          }
        }

        setDisplayedScanResults([...scanResults]);
      } else {
        const filtered = scanResults.filter(
          scan => scan.poiOrientation === Number(selectedPoi)
        );
        if (filtered.length) {
          //Set the nearest frame to the newly selected POI only if the POI changes.
          if (prevPoi !== selectedPoi) {
            const index = getNearestTimeFrame(filtered, selectedPoi);
            setScanIndex(index);
            // If the nearest frame is not available, return to the start of filtered scres.
          } else {
            // If POI didn't change, reload the previous timeframe
            if (sessionStorage.getItem("scanIndex")) {
              setScanIndex(Number(sessionStorage.getItem("scanIndex")));
            } else if (
              history?.location?.state?.from?.action === "GO_TO_EVENT"
            ) {
              setScanIndex(history?.location?.state?.from?.scanIndex);
            } else {
              setScanIndex(filtered.length - 1);
            }
          }

          setDisplayedScanResults(filtered);
        } else {
          setSelectedPoi("All");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanResults, selectedPoi]);

  useEffect(() => {
    sessionStorage.setItem("selectedPoi", selectedPoi);
  }, [selectedPoi]);

  useEffect(() => {
    // Make sure it gets reset if the parameters or mode changes
    setReviewerMode("REVIEWER");
    // only when the camera changes
    if (deviceId !== selectedCamera?.deviceId) {
      sessionStorage.removeItem("scanIndex");
      sessionStorage.removeItem("seletedPoi");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const url = window.location.origin.replace("reviewer", "rnd");
  return eventsLoading ? (
    <div
      style={{
        width: "80%",
        height: "80%",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <LinearProgress style={{ width: "100%" }} />
    </div>
  ) : scanResults?.length > 0 && displayedScanResults?.length > 0 ? (
    <ImageList
      addEditAlarm={addEditAlarm}
      scanIndex={scanIndex}
      setScanIndex={setScanIndex}
      scanResults={displayedScanResults}
      setScanResults={setDisplayedScanResults}
      poiKeys={poiKeys}
      events={events}
      eventConf={eventConf}
      endDate={endDate}
      handleDateChange={handleDateChange}
      resetAlarmCreationSession={resetAlarmCreationSession}
      selectedPoi={selectedPoi}
      setSelectedPoi={setSelectedPoi}
      deviceId={deviceId}
      rawScanResults={scanResults}
    />
  ) : (
    <div
      style={{
        margin: "1em"
      }}
    >
      <UTCDatePicker date={endDate} onChange={handleDateChange} />
      <Box p="1em" m="2em">
        <Typography
          style={{
            textAlign: "center",
            fontSize: "1.5em"
          }}
        >
          &empty; There are no scan results from this day.
        </Typography>
      </Box>
      {deviceId && (
        <a
          href={`${url}/#/cameras/${deviceId}/main`}
          style={{
            backgroundColor: "#1a1c1e",
            textDecoration: "none",
            color: "#ed5d2a",
            border: "1px solid #ed5d2a",
            borderRadius: "12px",
            padding: "10px 24px",
            textAlign: "center",
            display: "inlineBlock",
            fontSize: "16px",
            fontFamily: "centuryGothic,Roboto,Helvetica,Arial,sansSerif"
          }}
        >
          Link to RND App
        </a>
      )}
    </div>
  );
};

TaggingPage.propTypes = {
  addEditAlarm: PropTypes.func.isRequired,
  eventsLoading: PropTypes.bool.isRequired,
  showNonDetections: PropTypes.bool.isRequired,
  eventConf: PropTypes.number.isRequired,
  minuteGap: PropTypes.number.isRequired,
  frameMinimum: PropTypes.number.isRequired,
  paddedFrames: PropTypes.number.isRequired,
  setEventsLoading: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  resetAlarmCreationSession: PropTypes.func.isRequired,
  setReviewerMode: PropTypes.func.isRequired
};

export default TaggingPage;
