export const getAlgorithmRecordIsLoading = state =>
  state.algorithmRecordState.isLoading;

export const getAlgorithmRecords = state => state.algorithmRecordState.records;

export const getSelectedAlgorithmRecord = state =>
  state.algorithmRecordState.selectedRecord;

export const getRecordAlias = state =>
  state.algorithmRecordState.selectedRecord.alias ?? "scanresult";
