export const groupCameraListsByIotHub = data => {
  return data.reduce((result, item) => {
    const { iotHub, ...rest } = item;

    if (!result[iotHub]) {
      result[iotHub] = [];
    }

    result[iotHub].push({ iotHub, ...rest });

    return result;
  }, {});
};
