import axios from "axios";
import Cookies from "js-cookie";

export const client = axios.create({
  baseURL: `${process.env.REACT_APP_KUVA_API_URL}/`,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json"
  }
});

client.interceptors.request.use(async config => {
  const token = Cookies.get("kuva.auth.jwt");

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

export const request = async (
  url,
  options = {
    method: "GET",
    withCredentials: process.env.NODE_ENV !== "development"
  }
) =>
  await client.request({
    ...options,
    url
  });
