export const flags = Object.freeze({
  HIDE_LEAK_SOURCE_ON_NON_QUANT:
    "ui.reviewer.hide-leak-source-on-non-quantification-cameras",
  TAGS_MANAGEMENT: "ui.reviewer.tags-management",
  TAGS_FILTERING_ALARM_CREATION: "ui.reviewer.tags-filtering-alarm-creation",
  TAGS_FILTERING_NAV_BAR: "ui.reviewer.tags-filtering-nav-bar",
  ALARM_CREATION_SELECT_TAGS: "ui.reviewer.tags-alarm-creation-select-tags",
  CAMERA_PRESET_LISTS_MGMT: "ui-camera-preset-lists-management",
  SEMI_AUTOMATIC_QUANT: "ui.semi-automatic-quantification"
});
