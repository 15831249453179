export const DEFAULT_SCALE = 1.4;

export const defaultImageSize = Object.freeze({
  width: 313,
  height: 410
});

export const anchorSettings = Object.freeze({
  anchorColor: "#008000",
  anchorRadius: 3,
  strokeWidth: 1
});

export const originAnchorSettings = Object.freeze({
  defaultFillColor: "transparent",
  invalidFillColor: "#FF0000",
  validFillColor: "#008000"
});

export const segmentConstructorSettings = Object.freeze({
  strokeColor: "#FF0000",
  strokeWidth: 1,
  opacity: 1,
  lineJoin: "round",
  minLineSegments: 3,
  defaultWidth: defaultImageSize.width,
  defaultHeight: defaultImageSize.height
});

export const segmentSettings = Object.freeze({
  stroke: "#FF0000",
  lineJoin: "round",
  fill: "#0000FF",
  selectedOpacity: 0.7
});

export const segmentOpacitySettings = Object.freeze({
  min: 0.1,
  max: 1,
  step: 0.1,
  marks: [
    { value: 0.1, label: "0.1" },
    { value: 0.2, label: "0.2" },
    { value: 0.3, label: "0.3" },
    { value: 0.4, label: "0.4" },
    { value: 0.5, label: "0.5" },
    { value: 0.6, label: "0.6" },
    { value: 0.7, label: "0.7" },
    { value: 0.8, label: "0.8" },
    { value: 0.9, label: "0.9" },
    { value: 1, label: "1" }
  ]
});
