import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()({
  frameContainer: {
    overflowX: "scroll",
    overflowY: "hidden",
    width: "100%",
    whiteSpace: "nowrap",
    cursor: "pointer",
    "&::-webkit-scrollbar": {
      backgroundColor: "#222222",
      width: "8px"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#A6A6A6",
      borderRadius: "5px",
      width: "5px"
    }
  },
  imageIcon: {
    display: "inline-block"
  }
});

export { useStyles };
