import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()({
  root: {
    height: "min-content",
    margin: "0 10px",
    flexDirection: "column",
    display: "flex"
  },
  mainWrapper: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    width: "100%"
  },
  imageInfo: {
    fontSize: 14,
    display: "inline-block",
    paddingRight: "15px"
  },
  font14: {
    fontSize: 14
  },
  deviceData: {
    position: "absolute",
    marginTop: "70px",
    fontSize: "14px"
  }
});

export { useStyles };
