import { makeStyles } from "@kuva/ui-components";

import Theme from "~/common/Theme";
import { scrollbarStyles } from "~/common/styles";

export const useStyles = makeStyles()({
  root: { width: "100%" },
  headerInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  imageGrid: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 4,
    alignContent: "flex-start",
    overflowY: "auto",
    position: "relative",
    width: "100%",
    maxHeight: "calc(100% - 35px)",
    ...scrollbarStyles
  },
  singleImageBlock: {
    position: "relative",
    width: "calc(25% - 16px)",
    height: "auto",
    margin: "8px",
    display: "flex"
  },
  singleImage: {
    width: "100%",
    height: "auto",
    backgroundColor: "#222"
  }
});

export const useImageStyles = makeStyles()(
  (_theme, { editingAlarmDate, isWithinDateRange }) => {
    return {
      alarmImage: {
        outline:
          editingAlarmDate !== "" && isWithinDateRange
            ? `2px solid ${Theme.kuvaColors.blue}`
            : "none",
        opacity: isWithinDateRange ? "1" : "0.4",
        width: "100%",
        height: "auto",
        backgroundColor: "#222"
      }
    };
  }
);
