import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";

import { styled } from "@kuva/ui-components";

const TooltipLink = styled("a")(({ theme }) => ({
  display: "inline-block",
  color: theme.palette.grey[50],
  textDecoration: "underline",
  cursor: "pointer"
}));

const TooltipContent = ({ handleExtendAlarmPeriod, date, isAlarmEditing }) =>
  !isAlarmEditing && (
    <>
      This scan occurred outside of the alarm period.&nbsp;
      <TooltipLink onClick={handleExtendAlarmPeriod(date)}>
        Click to extend
      </TooltipLink>
      .
    </>
  );

export const DateInRangeWarningTooltip = ({
  children,
  date,
  handleExtendAlarmPeriod,
  ...props
}) => (
  <Tooltip
    {...props}
    title={
      <TooltipContent
        handleExtendAlarmPeriod={handleExtendAlarmPeriod}
        date={date}
      />
    }
  >
    {children}
  </Tooltip>
);

TooltipContent.propTypes = {
  handleExtendAlarmPeriod: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  isAlarmEditing: PropTypes.bool
};

DateInRangeWarningTooltip.propTypes = {
  children: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  handleExtendAlarmPeriod: PropTypes.func.isRequired
};
