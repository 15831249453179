import { makeStyles } from "@kuva/ui-components";

import Theme from "~/common/Theme";

const useStyles = makeStyles()({
  redRectangle: {
    border: `2.5px solid ${Theme.kuvaColors.orange}`,
    filter: "drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.12))",
    position: "absolute"
  }
});

export { useStyles };
