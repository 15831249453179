import {
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import PropTypes from "prop-types";

import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()(theme => ({
  root: {
    backgroundColor: theme.kuvaColors.black,
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    verticalAlign: "middle"
  },
  select: {
    fontSize: 14,
    padding: theme.spacing(0.5)
  },
  loadMoreButton: {
    minWidth: 100,
    fontSize: 10
  },
  pageButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1)
  },
  typography: {
    padding: theme.spacing(1.5) // Adjust padding as needed
  },
  loading: {
    color: theme.kuvaColors.grey
  }
}));

function AlarmsPagination({
  alarmPerPageOptions = [],
  selectedAlarmsPerPage,
  onChange,
  loadMoreAlarms,
  disabled,
  loading
}) {
  const { classes } = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <Typography className={classes.typography}>
          Alarms per page:{" "}
        </Typography>
        <Select
          variant="standard"
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={selectedAlarmsPerPage}
          onChange={onChange}
          className={classes.select}
        >
          {alarmPerPageOptions.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={classes.pageButtonContainer}>
        <Button
          color="primary"
          variant="outlined"
          onClick={loadMoreAlarms}
          className={classes.loadMoreButton}
          disabled={disabled}
        >
          {loading ? (
            <CircularProgress className={classes.loading} size={18} />
          ) : (
            "LOAD MORE"
          )}
        </Button>
      </div>
    </div>
  );
}

AlarmsPagination.propTypes = {
  alarmPerPageOptions: PropTypes.array,
  selectedAlarmsPerPage: PropTypes.number,
  onChange: PropTypes.func,
  loadMoreAlarms: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
};

export default AlarmsPagination;
