import { createAsyncThunk } from "@reduxjs/toolkit";

import CameraAPI from "~/common/apis/CameraAPI";

const thunk = async payload => {
  const { deviceId, distanceSegments } = payload;
  const response = await CameraAPI.createPoiDistanceSegments(
    deviceId,
    distanceSegments
  );
  return response;
};

export const createPoiDistanceSegments = createAsyncThunk(
  "camera/createPoiDistanceSegments",
  thunk
);
