import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()((theme, { displayType }) => ({
  textfieldLabel: {
    margin: "0 5px"
  },
  textfield: {
    width: "100px"
  },
  filterTags: {
    position: "absolute",
    top: "5px",
    left: displayType === "carousel" ? "230px" : "430px",
    padding: "5px",
    borderRadius: "5px",
    background: "black"
  },
  toggleBtn: {
    width: "60px",
    height: "30px",
    fontSize: "12px"
  }
}));

export { useStyles };
