export const groupByDate = alarms => {
  const map = new Map();
  for (const alarm of alarms) {
    const date = alarm.start.substring(0, 10);
    if (!date) continue;
    if (map.has(date)) {
      const currentAlarms = map.get(date);
      const dontAddIfExists = currentAlarms.some(a => a.id === alarm.id);
      if (dontAddIfExists) continue;
      map.get(date).push(alarm);
    } else {
      map.set(date, [alarm]);
    }
  }
  return map;
};
