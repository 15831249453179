export const handleImgUrl = (imgUrl, imgType) => {
  const paramPrefix = imgUrl?.includes("?") ? "&" : "?";

  switch (imgType) {
    case "colOD":
    case "colorOD":
      return `${imgUrl}${paramPrefix}falsecolorod=true`;
    case "unmasked":
      return `${imgUrl}${paramPrefix}unmasked=true`;
    case "colDen":
    default:
      return imgUrl;
  }
};
