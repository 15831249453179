import CloseIcon from "@mui/icons-material/Close";
import { Dialog } from "@mui/material";
import { useFormik } from "formik";
import { max } from "lodash";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch } from "react-redux";
import useImage from "use-image";
import { v4 as uuidv4 } from "uuid";

import { PoiDistanceSegmentsActions } from "~/common/store/slices/poi-distance-segments";

import { CanvasWorkspace } from "./CanvasWorkspace";
import { ControlPannelContent } from "./ControlPannelContent";
import { DEFAULT_SCALE } from "./graphic-settings";
import { findOverlappingSegmentsInFrame } from "./segmenting-utils";
import {
  CanvasContainer,
  CanvasPanel,
  MainContainer
} from "./styled-components";
import { useDistanceMapperStyles } from "./styles";
import { validationSchema } from "./validation-schema";

const DistanceMapper = ({
  open,
  onClose,
  selectedCamera,
  selectedPoi,
  selectedScan,
  imageSrc,
  handleSegmentingPoiChange,
  poiDistanceSegmentState,
  poiKeys
}) => {
  const { classes } = useDistanceMapperStyles();
  const dispatch = useDispatch();
  const [image] = useImage(imageSrc);
  const { enqueueSnackbar } = useSnackbar();

  const [scale, setScale] = useState(DEFAULT_SCALE);

  const { poiDistanceSegments } = poiDistanceSegmentState;

  const {
    dirty,
    errors,
    handleSubmit,
    handleChange,
    resetForm,
    setFieldTouched,
    setFieldValue,
    setValues,
    isValid,
    touched,
    values
  } = useFormik({
    enableReinitialize: true,
    initialValues: poiDistanceSegments[selectedPoi] || { segments: [] },
    onSubmit,
    validationSchema
  });

  const formikProps = {
    dirty,
    errors,
    handleChange,
    handleSubmit,
    isValid,
    resetForm,
    setFieldTouched,
    setFieldValue,
    setValues,
    touched,
    useFormik,
    values
  };

  async function onSubmit(distanceSegments) {
    let upsertResult = null;
    if (distanceSegments?.id) {
      upsertResult = await dispatch(
        PoiDistanceSegmentsActions.updatePoiDistanceSegments({
          id: distanceSegments?.id,
          deviceId: selectedCamera?.deviceId,
          distanceSegments
        })
      );
    } else {
      upsertResult = await dispatch(
        PoiDistanceSegmentsActions.createPoiDistanceSegments({
          deviceId: selectedCamera?.deviceId,
          distanceSegments
        })
      );
    }

    if (upsertResult?.error) {
      enqueueSnackbar(upsertResult?.error?.message, {
        variant: "error"
      });
    } else {
      enqueueSnackbar("Your changes have been successfully saved.", {
        variant: "success"
      });
    }
  }

  const handleZoomIn = () => setScale(scale => scale + 0.5);

  const handleZoomOut = () =>
    setScale(scale => max([scale - 0.5, DEFAULT_SCALE]));

  const createNewSegment = points => {
    const updatedSegments = values.segments.map(segment => ({
      ...segment,
      isSelected: false
    }));
    const newSegment = {
      segmentId: uuidv4(),
      points,
      isSelected: true
    };

    const updatedPolygons = [...updatedSegments, { ...newSegment }];

    if (values.segments.length > 0) {
      if (findOverlappingSegmentsInFrame(values.segments, points)) {
        enqueueSnackbar(
          "Segments must not overlap. Please ensure they are distinct.",
          {
            variant: "error"
          }
        );
      } else {
        setValues({ ...values, segments: updatedPolygons });
      }
    } else {
      const newPoiSegment = {
        organizationId: selectedCamera?.orgID,
        deviceId: selectedCamera?.deviceId,
        poi: selectedPoi.toString(),
        segments: updatedPolygons,
        eventDate: selectedScan?.createdOn
      };
      setValues(newPoiSegment);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      fullScreen
      disableEnforceFocus
      sx={{
        "& .MuiDialog-paper": { backgroundImage: "unset" }
      }}
    >
      <MainContainer>
        <CanvasContainer>
          <CanvasWorkspace
            image={image}
            scale={scale}
            segments={values.segments}
            createNewSegment={createNewSegment}
          />
        </CanvasContainer>
        <CanvasPanel>
          <ControlPannelContent
            formikProps={formikProps}
            handleZoomIn={handleZoomIn}
            handleZoomOut={handleZoomOut}
            handleSegmentingPoiChange={handleSegmentingPoiChange}
            poiKeys={poiKeys}
            selectedPoi={selectedPoi}
          />
        </CanvasPanel>
      </MainContainer>

      <CloseIcon onClick={() => onClose(false)} className={classes.closeIcon} />
    </Dialog>
  );
};

DistanceMapper.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedCamera: PropTypes.object,
  selectedPoi: PropTypes.string,
  selectedScan: PropTypes.object,
  imageSrc: PropTypes.string,
  handleSegmentingPoiChange: PropTypes.func,
  poiKeys: PropTypes.arrayOf(PropTypes.string)
};

export default DistanceMapper;
