import { useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
  getSelectedAlgorithmRecord,
  getAlgorithmRecords
} from "~/common/selectors/algorithm-records-selector";
import { AlgorithmRecordActions } from "~/common/store/slices/algorithm-records/algorithm-slice";

const useAlgorithmRecords = () => {
  const dispatch = useDispatch();

  const selectedRecord = useSelector(getSelectedAlgorithmRecord, shallowEqual);
  const records = useSelector(getAlgorithmRecords, shallowEqual);

  const selectRecord = hash =>
    dispatch(AlgorithmRecordActions.selectRecord(hash));
  const fetchRecords = useMemo(
    () => dispatch(AlgorithmRecordActions.getAlgorithmRecords()),
    [dispatch]
  );

  return {
    blobContainer: "scanresult",
    withScanResults: !selectedRecord.hash,
    algoHash: selectedRecord.hash,
    records,
    selectedRecord,
    selectRecord,
    fetchRecords
  };
};

export default useAlgorithmRecords;
