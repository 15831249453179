import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";

const InformationItem = ({ label, value }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        margin: "5px 0 5px 0"
      }}
    >
      <Typography variant="body2" sx={{ color: "#737373" }}>
        {label}:
      </Typography>
      <Typography variant="body2">{value}</Typography>
    </Box>
  );
};

InformationItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default InformationItem;
