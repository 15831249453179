import { grey } from "@mui/material/colors";

import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()(theme => ({
  root: {
    "& .makeStyles-closeButton": {
      top: "-12px",
      right: "-6px"
    }
  },
  dialogRoot: {
    position: "unset !important",
    "& .MuiPaper-root": {
      overflow: "hidden"
    }
  },
  dialogTitle: { minWidth: "245px" },
  dialog: {
    position: "absolute",
    right: -20,
    top: 40,
    backgroundColor: grey[900],
    width: "auto",
    maxHeight: "74px"
  },
  dialogMinimized: {
    position: "absolute",
    right: "-256px",
    top: 40,
    backgroundColor: grey[900],
    width: "auto",
    maxHeight: "30px"
  },
  actionButton: {
    minWidth: "100px"
  },
  closeButton: {
    position: "absolute",
    color: theme.palette.grey[500],
    top: "-10px",
    right: "2px"
  },
  openButton: {
    marginTop: "-15px",
    marginLeft: "-220px"
  },
  dialogContent: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: "5px"
  },
  dialogContentMinimized: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: "-4px"
  },
  typography: {
    fontSize: "12px",
    color: "white"
  },
  "&.MuiDialogTitle-root": {
    minWidth: "245px"
  },
  buttonContainer: {
    "& .MuiButton-outlined.Mui-disabled": {
      border: "1px solid #696969"
    }
  }
}));

export { useStyles };
