import PropTypes from "prop-types";
import { useEffect, useMemo, useRef } from "react";
import { shallowEqual, useSelector } from "react-redux";

import {
  selectMLScoreRanges,
  selectMLScoreColors
} from "~/common/selectors/ml-score-selector";

import { ImageIcon } from "./ImageIcon";
import { useStyles } from "./styles";

export const MLScoreSlider = ({
  scanResults,
  setScanIndex,
  getScanIndexById,
  value
}) => {
  const { classes } = useStyles();

  const selectedMLScoreRanges = useSelector(selectMLScoreRanges, shallowEqual);
  const selectedMLScoreColors = useSelector(selectMLScoreColors, shallowEqual);

  const sliderRefs = useRef({});

  const sliderData = useMemo(() => {
    return scanResults
      .map(item => {
        return {
          id: item?.id,
          createdOn: item?.createdOn,
          score: item?.mlScore?.score,
          isActive: item?.id === value?.id
        };
      })
      .reverse();
  }, [scanResults, value]);

  useEffect(() => {
    const activeMark = sliderRefs.current[value?.id];

    if (activeMark) {
      activeMark.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center"
      });
    }
  }, [value]);

  return (
    <div>
      <div className={classes.frameContainer}>
        {sliderData?.map(item => (
          <div
            key={item?.id}
            ref={el => (sliderRefs.current[item?.id] = el)}
            id={`labeller-slider-${item?.id}`}
            className={classes.imageIcon}
          >
            <ImageIcon
              scanResultId={item?.id}
              key={item?.id}
              isActive={item?.isActive}
              getScanIndexById={getScanIndexById}
              setScanIndex={setScanIndex}
              score={item?.score}
              scoreRanges={selectedMLScoreRanges}
              scoreColors={selectedMLScoreColors}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

MLScoreSlider.propTypes = {
  scanResults: PropTypes.array.isRequired,
  setScanIndex: PropTypes.func.isRequired,
  getScanIndexById: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired
};
