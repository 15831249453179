import { createAsyncThunk } from "@reduxjs/toolkit";

import SessionAPI from "~/common/apis/SessionAPI";

const thunk = async iotHub => {
  const response = await SessionAPI.getCameraListsByHub(iotHub);
  return response;
};

export const fetchCameraListsByHub = createAsyncThunk(
  "sessions/fetchCameraListsByHub",
  thunk
);
