import MuiLeakRoiIcon from "@mui/icons-material/BrandingWatermarkOutlined";
import Badge from "@mui/material/Badge";
import PropTypes from "prop-types";

export const LeakRoiIcon = ({ hasLeakRoi = false }) => {
  return (
    <Badge badgeContent="" color="secondary" invisible={!hasLeakRoi}>
      <MuiLeakRoiIcon sx={{ color: hasLeakRoi ? "#008000" : "#FFFFFF" }} />
    </Badge>
  );
};

LeakRoiIcon.propTypes = {
  hasLeakRoi: PropTypes.bool
};
