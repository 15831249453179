export const REVIEWERS_SUMMARY = "reviewers-summary";
export const SESSIONS_SUMMARY = "sessions-summary";

export const sessionSetting = Object.freeze({
  ONE_HOUR: "1 Hour",
  TWENTY_FOUR_HOURS: "24 Hours",
  HOURLY_OPTIONS: "HOURLY-OPTIONS",
  DAILY_DURATION: "00:00-23:59",
  DAILY_LABEL: "Daily",
  HOURLY_LABEL: "Hourly"
});

export const cameraSelectionModes = Object.freeze({
  CAMERA_SELECTION: "camera-selection",
  LIST_SELECTION: "list-selection"
});

export const cameraSelectionModeLookup = Object.freeze([
  {
    id: 1,
    value: cameraSelectionModes.CAMERA_SELECTION,
    name: "Camera Selection"
  },
  {
    id: 2,
    value: cameraSelectionModes.LIST_SELECTION,
    name: "List Selection"
  }
]);

export const summaryModeLookup = Object.freeze([
  {
    id: 1,
    value: REVIEWERS_SUMMARY,
    name: "Reviewers"
  },
  {
    id: 2,
    value: SESSIONS_SUMMARY,
    name: "Sessions"
  }
]);

export const sessionDurationLookup = Object.freeze([
  {
    id: 1,
    value: sessionSetting.ONE_HOUR,
    name: "1 Hour"
  },
  {
    id: 2,
    value: sessionSetting.TWENTY_FOUR_HOURS,
    name: "24 Hours"
  }
]);

export const hours = Object.freeze({
  1: sessionSetting.ONE_HOUR,
  24: sessionSetting.TWENTY_FOUR_HOURS
});

export const shiftStatuses = Object.freeze({
  STARTED: "started",
  COMPLETED: "completed"
});
