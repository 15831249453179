import Slider from "@mui/material/Slider";
import PropTypes from "prop-types";

import { segmentOpacitySettings } from "./graphic-settings";

export const SegmentOpacitySlider = ({ opacity, handleChange }) => {
  const { marks, min, max, step } = segmentOpacitySettings;

  return (
    <Slider
      defaultValue={opacity}
      value={opacity}
      onChange={handleChange}
      valueLabelDisplay="auto"
      step={step}
      marks={marks}
      min={min}
      max={max}
      sx={{
        "& .MuiSlider-markLabel": {
          fontSize: "8px"
        }
      }}
    />
  );
};

SegmentOpacitySlider.propTypes = {
  opacity: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired
};
