import produce from "immer";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import {
  getSelectedAlarm,
  selectIsEditing
} from "~/common/selectors/AlarmSelector";
import { getSelectedCamera } from "~/common/selectors/CameraSelector";
import {
  selectCurrentSession,
  selectSelectedDeviceHourlySessions
} from "~/common/selectors/SessionSelector";
import { getCurrentShift } from "~/common/selectors/ShiftSelector";
import { getValueFromSessionStorage } from "~/common/utils/dataUtils";
import { getLeakCoordinates } from "~/containers/LeakSourceLocationTool/Utils";

const getSessionByAlarmStart = (
  selectedSessions,
  startTime,
  currentSession
) => {
  let closestSession = null;
  let minTimeDifference = Infinity;

  for (const session of selectedSessions) {
    const sessionStartTime = new Date(session.startTime).getTime();
    const inputStartTime = new Date(startTime).getTime();
    const timeDifference = Math.abs(sessionStartTime - inputStartTime);

    if (
      timeDifference < minTimeDifference &&
      inputStartTime >= sessionStartTime &&
      inputStartTime <= sessionStartTime + 3600000 // 1 hour in milliseconds
    ) {
      minTimeDifference = timeDifference;
      closestSession = session;
    }
  }

  if (!closestSession) {
    return currentSession;
  }

  return closestSession;
};

const getInitialAlarmDuration = ({ isAlarmEditing, selectedAlarm }) => {
  let initialAlarmStart = null;
  let initialAlarmEnd = null;
  if (isAlarmEditing && !isEmpty(selectedAlarm)) {
    initialAlarmStart = selectedAlarm?.start;
    initialAlarmEnd = selectedAlarm?.end;
  }
  return { initialAlarmStart, initialAlarmEnd };
};

/**
 * @name useAlarmCreation Shared alarm creation logic. Can be reused in any page
 * where users can create an alarm (e.g. "Sessions", "Labeller", "Tagging")
 *
 * @param {Function} setScanResults   Setter for `scanResults` object which gets
 *                                    updated with alarm leak source(s)
 */
export const useAlarmCreation = ({
  addEditAlarm,
  eventConf,
  setScanResults,
  selectedPoi
}) => {
  const getter = getValueFromSessionStorage;

  const currentShift = useSelector(getCurrentShift, shallowEqual);
  const currentSession = useSelector(selectCurrentSession, shallowEqual);
  const selectedDeviceHourlySessions = useSelector(
    selectSelectedDeviceHourlySessions,
    shallowEqual
  );
  const selectedCamera = useSelector(getSelectedCamera, shallowEqual);
  const selectedAlarm = useSelector(getSelectedAlarm, shallowEqual);
  const isAlarmEditing = useSelector(selectIsEditing);
  const { initialAlarmStart, initialAlarmEnd } = getInitialAlarmDuration({
    isAlarmEditing,
    selectedAlarm
  });
  const leakRois = isAlarmEditing && selectedAlarm?.leakRois;

  const [creatingAlarm, setCreatingAlarm] = useState(
    getter("creatingAlarm", false)
  );
  const [alarmStart, setAlarmStart] = useState(initialAlarmStart);
  const [alarmEnd, setAlarmEnd] = useState(initialAlarmEnd);
  const [showLeakSourceTool, setShowLeakSourceTool] = useState(false);
  const [alarmLeakSources, setAlarmLeakSources] = useState({
    leakRois: leakRois,
    leakOrigin: []
  });
  const [distanceSegment, setDistanceSegment] = useState(null);

  /**
   * Checks whether the provided date is within the alarm's date range.
   */
  const isInAlarmDateRange = date => {
    const afterStart = +new Date(date) >= +new Date(alarmStart);
    const beforeEnd = +new Date(date) <= +new Date(alarmEnd);

    return afterStart && beforeEnd;
  };

  /**
   * An event handler wrapper to extend the alarm's date range to
   * include the provided date.
   */
  const handleExtendAlarmPeriod = date => () => {
    const beforeStart = +new Date(date) <= +new Date(alarmStart);
    const afterEnd = +new Date(date) >= +new Date(alarmEnd);

    if (beforeStart) {
      setAlarmStart(date);
    } else if (afterEnd) {
      setAlarmEnd(date);
    }
  };

  /**
   * Submits an alarm to the Alarm API.
   */
  const handleNewAlarm =
    ({
      alarmStart,
      alarmEnd,
      disableNotification = false,
      distanceFromCamera,
      humanActivity,
      numberOfFrames,
      scans,
      tags,
      source
    }) =>
    () => {
      let session = currentSession;
      if (selectedDeviceHourlySessions?.length > 1) {
        session = getSessionByAlarmStart(
          selectedDeviceHourlySessions,
          alarmStart
        );
      }
      const { leakRoisCoordinates, leakOriginCoordinates } =
        getLeakCoordinates(scans);

      const payload = {
        ...(isAlarmEditing &&
          !isEmpty(selectedAlarm) && { id: selectedAlarm?.id }),
        start: alarmStart,
        end: alarmEnd,
        createdOn: new Date().toISOString(),
        deviceId: selectedCamera?.deviceId,
        numberOfFrames,
        overallConf: eventConf,
        deviceName: selectedCamera?.name || selectedCamera?.deviceId,
        orgId: selectedCamera?.orgID,
        orgName: selectedCamera?.orgID
          .replaceAll("-", " ")
          .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()), // capitalize every first letter in word (eg. kuva-canada to Kuva Canada)
        poiOrientation: Number(selectedPoi),
        disableNotification,
        humanActivity,
        tags,
        source,
        shiftId: currentShift?.id,
        sessionId: session?.id,
        leakSource: {
          coords: leakOriginCoordinates,
          rangeMeters: distanceFromCamera ?? null
        },
        leakRois: leakRoisCoordinates,
        falseAlarm: false,
        ...(distanceSegment?.segmentId
          ? { distanceSegmentId: distanceSegment.segmentId }
          : {}),
        ...(distanceSegment?.name
          ? { distanceSegmentName: distanceSegment.name }
          : {})
      };

      const isRequestSent = addEditAlarm(payload);

      // reset
      isRequestSent && handleAlarmCancel();
    };

  /**
   * Shows the alarm creation dialog by initiating its state.
   */
  const handleCreatingAlarm = date => {
    setCreatingAlarm(true);
    setAlarmStart(date);
    setAlarmEnd(date);
  };

  /**
   * Cancels an alarm in progress and hides all alarm creation controls.
   */
  const handleAlarmCancel = () => {
    setCreatingAlarm(false);
    setShowLeakSourceTool(false);
    setAlarmStart(null);
    setAlarmEnd(null);
  };

  /**
   * Updates the `scanResults` state with the alarm's leak sources as they
   * are updated.
   */
  useEffect(() => {
    setScanResults(
      produce(draft => {
        draft.map((scan, idx) => {
          const withinRange = isInAlarmDateRange(scan.createdOn);
          if (withinRange) {
            draft[idx].leakRois = alarmLeakSources?.leakRois ?? [];
            draft[idx].leakOrigin = alarmLeakSources?.leakOrigin ?? [];
          } else {
            draft[idx].leakRois = [];
            draft[idx].leakOrigin = [];
          }
        });
      })
    );
  }, [alarmLeakSources, alarmStart, alarmEnd]);

  return {
    alarmEnd,
    alarmStart,
    creatingAlarm,
    showLeakSourceTool,

    handleAlarmCancel,
    handleCreatingAlarm,
    handleExtendAlarmPeriod,
    handleNewAlarm,
    isInAlarmDateRange,

    setAlarmEnd,
    alarmLeakSources,
    setAlarmLeakSources,
    setAlarmStart,
    setShowLeakSourceTool,

    distanceSegment,
    setDistanceSegment
  };
};
