/**
 * @description
 * @author Sean W.
 * @date 16/10/2021
 * @interface ICameraAppReportedAttributes
 *
 *  an interface for the cameraApp module twin
 *
 */

/**
 * @description
 * @author Sean W.
 * @date 16/10/2021
 * @export
 * @interface ITwinChangeMessage
 *
 * an interface for the stored module twin current state
 *
 */

/**
 * @description
 * @author Sean W.
 * @date 16/10/2021
 * @class BaseTwinChangeMessage
 * @implements {ITwinChangeMessage}
 *
 * base class to store module twin attributes
 */
class BaseTwinChangeMessage {
  type;
  deviceId;
  enqueuedTimeUtc;

  constructor(message) {
    this.type = message.type;
    // this.reported = {
    //   scanning: message.reported.scanning,
    //   illuminating: message.reported?.illuminating
    // };
    this.deviceId = message.deviceId;
    this.enqueuedTimeUtc = new Date(message.enqueuedTimeUtc);
  }

  static messageIsValidTwinChange = message => {
    // check that the message is a valid device twin change event
    if (message.messageSource !== "twinChangeEvents") return false;
    const validReporetd = BaseTwinChangeMessage.reportedAttributesAreValid(
      message.body?.properties?.reported
    );
    if (!validReporetd) return false;
    if (!message.messageDate) return false;
    if (!message.deviceId) return false;
    return true;
  };

  static reportedAttributesAreValid = message => {
    // check that the message is a valid module twin change event
    if (message === undefined) return false;
    if (message.scanning === undefined) return false;
    if (message.illuminating === undefined) return false;
    return true;
  };

  checkIfNewerMessage = newAttributes => {
    return (
      this.enqueuedTimeUtc &&
      this.enqueuedTimeUtc < newAttributes.enqueuedTimeUtc
    );
  };
}

/**
 * @description
 * @author Sean W.
 * @date 15/10/2021
 * @export
 * @class TwinChangeMessage
 * @extends {BaseTwinChangeMessage}
 *
 * This class is constructed from an IoT hub event notification
 * from the events.ts file
 *
 */
export class TwinChangeMessage extends BaseTwinChangeMessage {
  reported;

  constructor(message) {
    const attr = {
      type: message.messageSource,
      deviceId: message.deviceId,
      enqueuedTimeUtc: message.messageDate
    };
    super(attr);
    this.reported = message.body?.properties?.reported;
  }
}
/**
 * @description
 * @author Sean W.
 * @date 15/10/2021
 * @export
 * @class CameraAppModuleTwin
 * @extends {BaseTwinChangeMessage}
 *
 * This class is constructed from the module twin that we get from
 * the result of CameraAPI.getModuleTwinForCamera function
 */
export class CameraAppModuleTwin extends BaseTwinChangeMessage {
  constructor(message, deviceId) {
    const reported = {
      type: "CameraAppModuleTwin",
      deviceId: deviceId,
      enqueuedTimeUtc: new Date()
    };
    super(reported);
    this.reported = message;
  }
}
