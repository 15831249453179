import { createSlice } from "@reduxjs/toolkit";

import {
  moveResourceGroupToTopLevel,
  normalizeOrgs
} from "~/common/utils/organization";

const initialState = {
  organizations: [],
  selectedOrg: null,
  normalizedOrgs: {},
  baseOrg: null,
  loading: false
};

const slice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    setOrganizations: (state, action) => {
      const newOrganizations = moveResourceGroupToTopLevel(action.payload);
      state.organizations = newOrganizations;
      state.normalizedOrgs = normalizeOrgs(newOrganizations);
      state.loading = false;
    },
    setSelectedOrg: (state, action) => {
      state.selectedOrg = action.payload;
      state.baseOrg = null;
      state.loading = false;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setBaseOrg: (state, action) => {
      state.baseOrg = action.payload;
    }
  }
});

export const OrganizationActions = {
  ...slice.actions
};

export const OrganizationsReducer = slice.reducer;
