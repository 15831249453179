import { List, ListSubheader } from "@mui/material";
import { compareDesc, format, parseISO } from "date-fns";
import { shallowEqual, useSelector } from "react-redux";

import { getSelectedAlarm } from "~/common/selectors/AlarmSelector";
import { getSelectedCamera } from "~/common/selectors/CameraSelector";
import { useGetAlarms, useOrganization } from "~/hooks";

import AlarmsPagination from "./components/AlarmsPagination";
import AlarmFilter from "./components/Filter";
import { AlarmListItem } from "./components/AlarmListItem";
import { useStyles } from "./styles";

export const AlarmsList = () => {
  const { classes } = useStyles();
  const {
    groupedAlarmsArrayOfMap,
    loadMoreAlarms,
    isPaginationDisabled,
    alarmsPerPage,
    handleAlarmsPerPageChange,
    loading,
    alarmContainerRef,
    alarmPerPageOptions
  } = useGetAlarms();

  const { selectedOrg } = useOrganization();
  const selectedCamera = useSelector(getSelectedCamera, shallowEqual);
  const selectedAlarm = useSelector(getSelectedAlarm, shallowEqual);

  let sortedGroupedAlarms = [...groupedAlarmsArrayOfMap()];

  for (let i = 0; i < sortedGroupedAlarms.length; i++) {
    sortedGroupedAlarms[i][1].sort((a, b) => {
      const aStart = parseISO(a.start);
      const bStart = parseISO(b.start);
      return compareDesc(aStart, bStart);
    });
  }

  return (
    <div
      id="alarms-container"
      ref={alarmContainerRef}
      className={classes.container}
    >
      <AlarmFilter />
      <List dense data-testid="alarms-list">
        {sortedGroupedAlarms.map(([date, alarmsOnDate]) => {
          return (
            <li key={date}>
              <ul style={{ padding: 0 }}>
                <ListSubheader className={classes.listSubheader}>
                  {format(parseISO(date), "MMM do, yyyy")}
                </ListSubheader>
                {alarmsOnDate.map(alarm => (
                  <AlarmListItem
                    key={alarm?.id}
                    alarm={alarm}
                    selectedAlarm={selectedAlarm}
                    selectedOrg={selectedOrg}
                    selectedCamera={selectedCamera}
                  />
                ))}
              </ul>
            </li>
          );
        })}
        <AlarmsPagination
          alarmPerPageOptions={alarmPerPageOptions}
          loadMoreAlarms={loadMoreAlarms}
          disabled={isPaginationDisabled}
          selectedAlarmsPerPage={alarmsPerPage}
          onChange={handleAlarmsPerPageChange}
          loading={loading}
        />
      </List>
    </div>
  );
};
