import { configureStore } from "@reduxjs/toolkit";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import { createMigrate, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import rootReducer from "../reducers";

const version = 5; // Set the current version number

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["organizationState", "sessionState", "mlScoreState"], // Add state-key here - List of persisted State
  version,
  migrate: createMigrate(getMigrationManifest(), { debug: false })
};

function getMigrationManifest() {
  const migrationManifest = {};

  if (version > 1) {
    migrationManifest[version] = state => ({
      ...state,
      sessionState: undefined // Reset sessionState for the current version
    });
  }

  return migrationManifest;
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk, reduxImmutableStateInvariant()]
});

export const appPersistStore = persistStore(store);
