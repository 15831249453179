import CameraAPI from "../apis/CameraAPI";

import { CameraLocation } from "./CameraLocation";

export class Camera {
  name;
  deviceId;
  location;
  orgID;
  status;
  connectionState;
  version;
  lastHeartBeat;
  lastTwinUpdate;
  board_temperature;
  sensor_temperature;
  scanning;
  illuminating;
  desired;
  heartbeat;
  timezone;
  quantification;

  constructor(camera) {
    this.lastHeartBeat = new Date(0); //camera.lastHeartBeat);
    this.lastTwinUpdate = new Date(0); //camera.lastTwinUpdate);

    this.deviceId = camera?.deviceId;
    this.name = camera?.tags?.name || camera?.deviceId;
    this.location = camera?.tags?.location;

    this.location = new CameraLocation(camera?.tags?.location);
    this.orgID = camera?.tags?.organization;
    this.status = camera?.status;
    this.version = camera?.version;

    this.timezone = camera?.tags?.timezone ?? "America/Denver";
    this.quantification = camera?.tags?.quantification ?? false;

    this.scanning = false;
    this.illuminating = false;

    this.connectionState = camera.connectionState || "Disconnected";

    if (camera.properties.desired) this.desired = camera.properties.desired;

    if (camera.properties.reported) {
      // this.reported = camera.properties.reported;
      if (camera.properties.reported) {
        return Object.assign(
          this,
          this.updateReportedAttributes(camera.properties.reported)
        );
      }
    }
  }

  /**
    @function updateReportedAttributes
    @description Updates the current camera object when a device twin change event is received from a socket 
    @author Sean W.
    @Date 2020-11-06
    @param {IReportedAttributes} reportedAttributes - the content of the message
    @param {Date} updateTime - the time in which the event was created
    @returns {ICamera} an object to update the current camera with
  */
  updateReportedAttributes = reportedAttributes => {
    // check if this update is the latest
    let updateObject = {};

    const eventTime = new Date(reportedAttributes.messageDate);

    const reportedCamera = reportedAttributes?.properties?.reported?.camera;

    if (this.lastTwinUpdate.getTime() < eventTime.getTime() && reportedCamera) {
      updateObject = { ...updateObject, ...reportedCamera };
      // updateObject.scanning = reportedCamera.scanning || false;
      // updateObject.illuminating = reportedCamera.illuminating || false;
      updateObject.lastTwinUpdate = eventTime;
    }
    return updateObject;
  };

  /**
    @function updateHeartbeat
    @description Updates the current camera object when a new heartbeat is received from a socket 
    @author Sean W.
    @Date 2020-11-06
    @param {IHeartbeatAttributes} reportedAttributes - the content of the message
    @returns {ICamera} an object to update the current camera with
  */
  updateHeartbeat = reportedAttributes => {
    let updateObject = {};
    let heartbeat = {};

    heartbeat.board_temperature = reportedAttributes.board_temperature;
    heartbeat.sensor_temperature = reportedAttributes.sensor_temperature;
    heartbeat.last_pan_position_deg = reportedAttributes.last_pan_position_deg;
    heartbeat.wind_direction = reportedAttributes.wind_direction;
    heartbeat.wind_speed = reportedAttributes.wind_speed;
    heartbeat.wind_timestamp = reportedAttributes.wind_timestamp;
    heartbeat.primary_timestamp = reportedAttributes.primary_timestamp;

    updateObject.heartbeat = heartbeat;
    updateObject.lastHeartBeat = new Date(reportedAttributes.primary_timestamp);

    return updateObject;
  };

  illuminate = () => {
    //toggle using device twin
    console.log("illuminate", this.deviceId);
    return CameraAPI.toggleIlluminator(this.deviceId);
  };

  /** start/stop controls normal scanning function on/off*/
  scan = () => {
    //toggle using device twin
    console.log("scan", this.deviceId);
    return CameraAPI.toggleCameraScan(this.deviceId);
  };

  /**
    @function updateDeviceAttributes
    @description Updates the current camera name and location 
    @author Daniela S.
    @Date 2020-06-09
    @param {string} name - the name of the camera
    @param {number} latitude - the latitude of the location of the camera
    @param {number} longitude - the longitude of the location of the camera
    @param {string} timezone - the timezone of the camera
    @returns {Promise}
  */
  // updateDeviceAttributes = (attributes: { timezone: string }) => {
  //   return CameraAPI.updateDeviceAttributes(this.deviceId, attributes);
  // };

  updateCameraAttributes = attributes => {
    // takes the current camera and create an update object for the camera
    let updateObject = { location: {} };

    updateObject.name = attributes.name;
    updateObject.location.latitude = attributes.lat;
    updateObject.location.longitude = attributes.lon;
    updateObject.timezone = attributes.timezone;

    return updateObject;
  };
}
