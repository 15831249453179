import { isNil } from "lodash";

import { ALGO_CUBIC_METERS_PER_DAY, UnitNames } from "~/constants/units";
import { intlFormatNumber } from "~/utils/intl-format-number";

export const cubicMeterPerDayToScfhRatio = 1.471;

export const metricGas = UnitNames[ALGO_CUBIC_METERS_PER_DAY];

export const metricToStandardCubicFeetPerHour = n =>
  n * cubicMeterPerDayToScfhRatio;

export const standardCubicFeetPerHourToMetric = n =>
  n / cubicMeterPerDayToScfhRatio;

export const convertGasUnit = (n, unit, isImperial, toFormat = true) => {
  if (isNil(n)) return "N/A";

  let result = n;

  const isMetricGas = unit === metricGas;
  const needsConversion =
    (!isImperial && !isMetricGas) || (isImperial && isMetricGas);

  if (needsConversion) {
    result = isMetricGas
      ? metricToStandardCubicFeetPerHour(n)
      : standardCubicFeetPerHourToMetric(n);
  }

  return toFormat ? intlFormatNumber(result) : result;
};
