import { isEmpty } from "lodash";

import SessionAPI from "../apis/SessionAPI";

/**
 * @name getShifts
 * @description this function is a middleware function that
 * 1. calls the get sessions api endpoint
 * 2. dispatches an action to redux to update it with the new data
 *
 * @author Patrick B.
 * @date 31/03/2022
 *
 * @returns {function}
 */
export const getShifts = (start = undefined, end = undefined) => {
  return async dispatch => {
    return SessionAPI.getShifts(start, end)
      .then(response => {
        if (response.data) {
          let shifts = response.data;
          dispatch({ type: "FETCH_SHIFTS_SUCCESS", shifts });
          return shifts;
        } else {
          console.log("Empty shifts response");
        }
      })
      .catch(error => {
        console.log("Fetch shifts error:", error);
      });
  };
};

export const getShiftByStatus = status => {
  return async dispatch => {
    dispatch({ type: "SET_SHIFT_IS_LOADING", isLoading: true });
    try {
      const response = await SessionAPI.getShiftByStatus(status);
      if (response.status === 200) {
        const currentShift = response.data;
        dispatch({ type: "FETCH_CURRENT_SHIFT_SUCCESS", currentShift });
        dispatch({
          type: "SET_SHIFT_IS_STARTED",
          isStarted: !isEmpty(response.data)
        });
      }
    } catch (error) {
      dispatch({ type: "FETCH_CURRENT_SHIFT_ERROR", error });
      console.log("Fetch shifts error:", error);
    } finally {
      dispatch({ type: "SET_SHIFT_IS_LOADING", isLoading: false });
    }
  };
};

export const createShift = () => {
  return async dispatch => {
    dispatch({ type: "SET_SHIFT_IS_LOADING", isLoading: true });
    try {
      const response = await SessionAPI.createShift();
      const shift = response?.data;
      dispatch({ type: "START_NEW_SHIFT_SUCCESS", shift });
      return shift;
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: "SET_SHIFT_IS_LOADING", isLoading: false });
    }
  };
};

export const completeShift = (id, startTime) => {
  return async dispatch => {
    dispatch({ type: "SET_SHIFT_IS_LOADING", isLoading: true });
    try {
      await SessionAPI.completeShift(id, startTime);
      dispatch({ type: "SET_SHIFT_IS_STARTED", isStarted: false });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: "SET_SHIFT_IS_LOADING", isLoading: false });
    }
  };
};

/**
 * @name continuePreviousShift
 * @description this function is a middleware function that dispatches an action to redux to update it with the new data (current)
 *
 * @author Patrick B.
 * @date 31/03/2022
 *
 * @param previousShift - the previously open/incomplete shift
 *
 * @returns {function}
 */
export const continuePreviousShift = previousShift => {
  return dispatch => {
    dispatch({ type: "CONTINUE_PREVIOUS_SHIFT_SUCCESS", shift: previousShift });
  };
};
