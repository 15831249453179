import { addDays, isAfter } from "date-fns";

const convertToHHMMSS = numberOfSecs => {
  let secNum = parseInt(numberOfSecs, 10);

  let hours = Math.floor(secNum / 3600)
    .toString()
    .padStart(2, "0");
  let minutes = Math.floor((secNum - hours * 3600) / 60)
    .toString()
    .padStart(2, "0");
  let seconds =
    secNum - hours * 3600 - (minutes * 60).toString().padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
};

const getStartDateTimeInSecs = startDate => {
  const currentDateTime = new Date();
  return (currentDateTime.getTime() - startDate.getTime()) / 1000; // number of seconds
};

export { convertToHHMMSS, getStartDateTimeInSecs };
