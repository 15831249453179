import { useState } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { Line, Rect } from "react-konva";

import { segmentConstructorSettings } from "./graphic-settings";
import { SegmentOriginAnchor } from "./SegmentOriginAnchor";

const {
  strokeColor,
  strokeWidth,
  opacity,
  lineJoin,
  minLineSegments,
  defaultWidth,
  defaultHeight
} = segmentConstructorSettings;

export const SegmentConstructor = ({
  width = defaultWidth,
  height = defaultHeight,
  onComplete = noop
}) => {
  const [points, setPoints] = useState([]);
  const [nextPoint, setNextPoint] = useState({ x: 0, y: 0 });
  const [isComplete, setIsComplete] = useState(false);

  const handleClick = ({ x, y }) => {
    setPoints(points.concat({ x, y }));
  };

  return (
    <>
      <Line
        strokeWidth={strokeWidth}
        stroke={strokeColor}
        opacity={opacity}
        lineJoin={lineJoin}
        closed={isComplete}
        points={points
          .flatMap(point => [point.x, point.y])
          .concat([nextPoint.x, nextPoint.y])}
      />

      <Rect
        x={0}
        y={0}
        stroke={strokeColor}
        width={width}
        height={height}
        onClick={event => {
          if (!isComplete) {
            const x = event.evt.offsetX;
            const y = event.evt.offsetY;
            handleClick({ x, y });
          }
        }}
        onMouseMove={event => {
          if (!isComplete) {
            const x = event.evt.offsetX;
            const y = event.evt.offsetY;

            setNextPoint({ x, y });
          } else if (isComplete && points.length > 0) {
            setPoints([]);
            setIsComplete(false);
          }
        }}
      />

      {points[0] && !isComplete && (
        <SegmentOriginAnchor
          point={points[0]}
          onValidClick={() => {
            onComplete(points);
            setNextPoint(points[0]);
            setIsComplete(true);
          }}
          onValidMouseOver={() => {
            setNextPoint(points[0]);
          }}
          validateMouseEvents={() => {
            return points.length >= minLineSegments;
          }}
        />
      )}
    </>
  );
};

SegmentConstructor.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  onComplete: PropTypes.func.isRequired
};
