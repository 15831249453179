import { makeStyles } from "@kuva/ui-components";

import { scrollbarStyles } from "~/common/styles";

const useStyles = makeStyles()(theme => ({
  container: {
    width: "15%",
    borderRadius: "10px",
    backgroundColor: theme.kuvaColors.mediumBlack,
    height: "100%",
    overflowY: "auto",
    ...scrollbarStyles
  },
  button: {
    fontSize: 10,
    position: "sticky",
    zIndex: 2,
    top: 0,
    background: "rgb(35 37 39)",
    width: "100%",
    paddingTop: 15,
    height: 48,
    "& img": {
      maxHeight: 40
    }
  },
  listSubheader: {
    backgroundColor: "#111"
  }
}));

export { useStyles };
