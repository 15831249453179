import { createSlice } from "@reduxjs/toolkit";

import { getAlgorithmRecords } from "./thunks/get-algorithm-records";

const scanResult = { hash: "scanresult", alias: "camera result" };

const initialState = {
  selectedRecord: scanResult,
  isLoading: false,
  records: [scanResult]
};

const slice = createSlice({
  name: "algorithm-records",
  initialState,
  reducers: {
    selectRecord(state, action) {
      const record = state.records.find(algo => algo.hash === action.payload);
      state.selectedRecord = record;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAlgorithmRecords.pending, state => {
        state.isLoading = true;
      })

      .addCase(getAlgorithmRecords.rejected, state => {
        state.isLoading = false;
        state.error = true;
      })

      .addCase(getAlgorithmRecords.fulfilled, (state, action) => {
        const { payload } = action;
        state.records = [scanResult, ...payload.data];
        state.isLoading = false;
      });
  }
});

export const AlgorithmRecordActions = { ...slice.actions, getAlgorithmRecords };

export const AlgorithmRecord = slice.reducer;
