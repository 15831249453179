import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import Badge from "@mui/material/Badge";

import { styled } from "@kuva/ui-components";

import { DEFAULT_POINT_SIZE } from "./constants";

const Point = styled(Badge)(({ left, top, width, height }) => ({
  position: "absolute",
  left: left,
  top: top,
  "& .MuiBadge-badge": {
    background: "red",
    width,
    height,
    borderRadius: "50%"
  }
}));

const LeakSourcePoint = ({ leakOrigin, img }) => {
  const [coords, setCoords] = useState([]);
  const [pointSize, setPointSize] = useState([]);

  const handleCoords = () => {
    const imgWidth = img?.current?.naturalWidth || 313;
    const imgHeight = img?.current?.naturalHeight || 410;

    const width = img?.current?.width;
    const height = img?.current?.height;
    if (!isEmpty(leakOrigin)) {
      setPointSize([
        DEFAULT_POINT_SIZE * (width / imgWidth),
        DEFAULT_POINT_SIZE * (height / imgHeight)
      ]);
      setCoords([
        leakOrigin[0] * (width / imgWidth),
        leakOrigin[1] * (height / imgHeight)
      ]);
    }
  };

  useEffect(() => {
    handleCoords();
    window.addEventListener("resize", handleCoords);

    return () => {
      window.removeEventListener("resize", handleCoords);
    };
  }, [window.innerWidth, leakOrigin]);

  return (
    !isEmpty(leakOrigin) && (
      <Point
        variant="dot"
        color="primary"
        left={coords[0]}
        top={coords[1]}
        width={pointSize[0]}
        height={pointSize[1]}
      />
    )
  );
};

LeakSourcePoint.propTypes = {
  leakOrigin: PropTypes.arrayOf(PropTypes.number).isRequired,
  img: PropTypes.oneOfType([
    PropTypes.shape({
      current: PropTypes.instanceOf(HTMLInputElement)
    }),
    PropTypes.oneOf([null])
  ]).isRequired
};

export default LeakSourcePoint;
