import { makeStyles, styled } from "@kuva/ui-components";

export const StyledNavContainer = styled("div")(({ width }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  width,
  backgroundColor: "rgb(26, 28, 30)",
  zIndex: 2,
  "& .MuiListItem-root": {
    paddingLeft: "unset",
    paddingRight: "unset"
  }
}));

export const StyledNavWrapper = styled("div")(({ width }) => ({
  width,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "space-around",
  height: "30%"
}));

export const useNavigationBarStyles = makeStyles()(() => ({
  button: {
    width: "100%"
  },
  logo: {
    width: "70%",
    margin: "15% 15% 50%"
  },
  listItemContent: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  icon: {
    color: "#FFFFFF"
  },
  label: {
    color: "rgb{196, 197, 198)"
  }
}));
