import isEmpty from "lodash/isEmpty";

const getLeakCoordinates = scanResults => {
  const leakRoisCoordinates = [];
  let leakOriginCoordinates = [];
  for (let scan of scanResults) {
    if (!isEmpty(scan?.leakRois)) {
      const unscaledCoords = scan.leakRois.at(0)?.coords;

      const scaledCoords = [
        unscaledCoords[0],
        unscaledCoords[1],
        unscaledCoords[2],
        unscaledCoords[3],
        unscaledCoords[4],
        unscaledCoords[5],
        unscaledCoords[6],
        unscaledCoords[7]
      ];

      const leakRois = {
        frameId: scan.id,
        scanDate: scan.createdOn,
        coords: scaledCoords
      };
      leakRoisCoordinates.push(leakRois);
    }
    if (!isEmpty(scan?.leakOrigin)) {
      leakOriginCoordinates = scan?.leakOrigin;
    }
  }
  return { leakRoisCoordinates, leakOriginCoordinates };
};
export { getLeakCoordinates };
