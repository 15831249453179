// constants to filter the tags data by

const organization = {
  all: "All",
  default: "Default",
  custom: "Custom"
};

const tagTypes = {
  all: "",
  alarm: "Alarm",
  image: "Image",
  user: "User"
};

export { organization, tagTypes };
