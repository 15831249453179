import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()({
  mainWrapper: {
    position: "absolute",
    zIndex: 4,
    top: "15px",
    left: "20px",
    border: "2px solid transparent",
    borderRadius: "12px",
    backgroundColor: "#000000"
  }
});

export { useStyles };
