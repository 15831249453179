import { Layer, Image, Stage } from "react-konva";
import { noop } from "lodash";
import PropTypes from "prop-types";

import { CanvasWrapper } from "./styled-components";
import { Segment } from "./Segment";
import { SegmentConstructor } from "./SegmentConstructor";
import { defaultImageSize } from "./graphic-settings";

export const CanvasWorkspace = ({
  image,
  scale,
  segments,
  createNewSegment = noop
}) => {
  return (
    <CanvasWrapper scale={scale}>
      <Stage
        width={image?.width || defaultImageSize.width}
        height={image?.height || defaultImageSize.height}
      >
        <Layer>
          <Image image={image} />
          <Segment segments={segments} />
          <SegmentConstructor
            width={image?.width}
            height={image?.height}
            onComplete={points => {
              createNewSegment(points);
            }}
          />
        </Layer>
      </Stage>
    </CanvasWrapper>
  );
};

CanvasWorkspace.propTypes = {
  image: PropTypes.object,
  scale: PropTypes.number.isRequired,
  segments: PropTypes.array.isRequired,
  createNewSegment: PropTypes.func.isRequired
};
