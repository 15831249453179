import { Box } from "@mui/material";

import { styled } from "@kuva/ui-components";

import { scrollbarStyles } from "~/common/styles";

export const AlarmDetailsPageWrapper = styled("div")(() => ({
  backgroundColor: "#2D2D2D",
  width: "84%",
  display: "flex",
  flexDirection: "column"
}));

export const AlarmDetailsContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-evenly",
  width: "100%",
  flex: 1,
  minHeight: 0,
  marginTop: 8
}));

export const LeftColumnWrapper = styled("div")(() => ({
  flex: 2,
  paddingRight: "8px",
  margin: "8px 16px",
  overflowY: "auto",
  ...scrollbarStyles
}));

export const RightColumnWrapper = styled("div")(() => ({
  flex: 7,
  margin: "8px 16px",
  display: "flex"
}));

export const LabelInputWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%"
}));
