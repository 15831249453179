import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField
} from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { useStyles } from "../styles";

const AddTagDialog = ({ addTag }) => {
  const [open, setOpen] = useState(false);
  const [tagText, setTagText] = useState("");
  const [type, setType] = useState("");
  const [textError, setTextError] = useState(false);
  const [typeError, setTypeError] = useState(false);

  const { classes } = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
    setTextError(false);
    setTypeError(false);
  };

  const handleClose = () => {
    setOpen(false);
    setTagText("");
    setType("");
  };

  const handleTextChange = event => setTagText(event.target.value);

  const handleTypeChange = event => {
    setType(event.target.value);
  };

  const handleSubmit = () => {
    const isTagTextValid = tagText.trim().length > 2;
    const isTypeValid = type !== "";
    if (isTagTextValid && isTypeValid) {
      setTextError(false);
      addTag(tagText, type);
      setOpen(false);
      setTagText("");
      setType("");
    } else {
      if (!isTagTextValid) setTextError(true);
      if (!isTypeValid) setTypeError(true);
    }
  };

  useEffect(() => {
    if (tagText.trim().length > 2) setTextError(false);
  }, [tagText]);

  useEffect(() => {
    if (type !== "") setTypeError(false);
  }, [type]);

  return (
    <div>
      <Button
        variant="contained"
        className={classes.addTagBtn}
        onClick={handleClickOpen}
        color="secondary"
      >
        New Tag
      </Button>
      <Dialog
        role="dialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={"xs"}
      >
        <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
          Create a New Tag
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <TextField
            autoFocus
            className={classes.inputField}
            color="primary"
            error={textError}
            fullWidth
            helperText={textError ? "Must contain at least 3 characters" : ""}
            InputLabelProps={{ style: { color: "gray", fontSize: "18px" } }}
            InputProps={{ style: { color: "white", fontSize: "18px" } }}
            label="Tag Name"
            margin="dense"
            onChange={handleTextChange}
            role="text field"
            variant="outlined"
          />
          <TextField
            className={classes.inputField}
            fullWidth
            variant="outlined"
            value={type}
            onChange={handleTypeChange}
            select
            label="Tag Type"
            InputProps={{ style: { color: "white", fontSize: "18px" } }}
            InputLabelProps={{ style: { color: "gray", fontSize: "18px" } }}
            color="primary"
            error={typeError}
            helperText={
              typeError
                ? "Must specify a tag type"
                : "(Where this tag is applicable)"
            }
          >
            <MenuItem value="" className={classes.menuItem}>
              <em>None</em>
            </MenuItem>
            <MenuItem value="Alarm" className={classes.menuItem}>
              Alarm
            </MenuItem>
            <MenuItem value="Image" className={classes.menuItem}>
              Image
            </MenuItem>
            <MenuItem value="User" className={classes.menuItem}>
              User
            </MenuItem>
          </TextField>
          <DialogActions className={classes.dialogActions}>
            <Button
              className={classes.redBtn}
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              className={classes.greenBtn}
              onClick={handleSubmit}
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

AddTagDialog.propTypes = {
  addTag: PropTypes.func.isRequired
};

export default AddTagDialog;
