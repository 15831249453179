import { useAuth0 } from "@auth0/auth0-react";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { ConfirmProvider } from "material-ui-confirm";
import { useState } from "react";
import { HashRouter, Route } from "react-router-dom";

import { makeStyles } from "@kuva/ui-components";

import Landing from "../pages/landingPage/Landing";

import AppInitialize from "./AppInitialize";
import MainContent from "./MainContent";

const useStyles = makeStyles()({
  error: {
    position: "absolute",
    zIndex: 99,
    "-webkit-font-smoothing:": "antialiased",
    color: "#fff",
    fontSize: "1rem",
    wordBreak: "break-word",
    fontFamily: '"Roboto, "Helvetica", "Arial", "sans-serif"',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
    boxSizing: "inherit",
    margin: "24px 0",
    padding: "4px 24px",
    borderLeft: "5px solid #ffe564",
    backgroundColor: "rgba(255,229,100,0.2)",
    left: 0,
    bottom: 0
  }
});

function App() {
  const { classes } = useStyles();

  const { isAuthenticated, error, logout } = useAuth0();
  const [appInitialized, setAppInitialized] = useState(false);
  const [appInitializing, setAppInitializing] = useState(false);
  const [appError, setAppError] = useState(null);

  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ConfirmProvider>
          <HashRouter>
            <Route
              path="/logout"
              render={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
            />
            {!isAuthenticated || error || appError ? (
              <>
                {error ||
                  (appError && (
                    <blockquote className={classes.error}>
                      <p>⚠️ Oops... {error?.message ?? appError?.message}</p>
                    </blockquote>
                  ))}
                <Landing />
              </>
            ) : !appInitialized || appInitializing ? (
              <AppInitialize
                setAppError={setAppError}
                setAppInitialized={setAppInitialized}
                setAppInitializing={setAppInitializing}
              />
            ) : (
              <MainContent setAppError={setAppError} />
            )}
          </HashRouter>
        </ConfirmProvider>
      </LocalizationProvider>
    </div>
  );
}

export default App;
