import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField
} from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { useOrganization } from "~/hooks";

import { useStyles } from "../styles";

const EditDialog = ({ tag, handleEditClose, editTag, open }) => {
  const { classes } = useStyles();

  const { selectedOrg } = useOrganization();

  const [tagText, setTagText] = useState("");
  const [type, setType] = useState("");
  const [textError, setTextError] = useState(false);
  const [editsMade, setEditsMade] = useState(false);

  // set the variables after `tag` is loaded
  useEffect(() => {
    if (tag) {
      setTagText(tag.text);
      setType(tag.type);
    }
  }, [tag]);

  const handleTextChange = event => {
    setTagText(event.target.value);
  };

  useEffect(() => {
    if (tagText.trim().length > 2) {
      setTextError(false);
    }
  }, [tagText]);

  const handleTypeChange = event => {
    setType(event.target.value);
  };

  const handleClose = () => {
    setTextError(false);
    setTagText(tag.text);
    setType(tag.type);
    handleEditClose();
  };

  const handleSave = () => {
    if (tagText.trim().length > 2) {
      setTextError(false);
      editTag(tag.id, tagText, type, selectedOrg ? selectedOrg.orgId : "N/A");
      handleClose();
    } else {
      setTextError(true);
    }
  };

  useEffect(() => {
    // if nothing has changed, disable the Save button
    if (tag) {
      if (tagText.trim() === tag.text && type === tag.type) {
        setEditsMade(false);
      } else {
        setEditsMade(true);
      }
    }
  }, [tag, tagText, type]);

  if (!tag) return null;

  return (
    <Dialog
      disableEnforceFocus
      role="dialog"
      key={"editDialog" + tag.id}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={"xs"}
    >
      <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
        Edit Tag
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField
          className={classes.inputField}
          label="Tag Name"
          value={tagText}
          variant="outlined"
          color="primary"
          error={textError}
          helperText={textError ? "Must contain at least 3 characters" : ""}
          role="text field"
          InputProps={{ style: { color: "white", fontSize: "18px" } }}
          onChange={handleTextChange}
          InputLabelProps={{ style: { color: "gray", fontSize: "18px" } }}
          autoFocus
          margin="dense"
          fullWidth
        />
        <TextField
          className={classes.inputField}
          value={type}
          variant="outlined"
          onChange={handleTypeChange}
          select
          label="Tag Type"
          InputProps={{ style: { color: "white", fontSize: "18px" } }}
          InputLabelProps={{ style: { color: "gray", fontSize: "18px" } }}
          color="primary"
          fullWidth
        >
          <MenuItem value="Alarm" className={classes.menuItem}>
            Alarm
          </MenuItem>
          <MenuItem value="Image" className={classes.menuItem}>
            Image
          </MenuItem>
          <MenuItem value="User" className={classes.menuItem}>
            User
          </MenuItem>
        </TextField>
        <DialogActions className={classes.dialogActions}>
          <Button
            role="button"
            className={classes.grayBtn}
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            role="button"
            type="submit"
            className={editsMade ? classes.greenBtn : classes.grayBtn}
            color="primary"
            onClick={handleSave}
            disabled={!editsMade}
          >
            Save
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

EditDialog.propTypes = {
  tag: PropTypes.shape({
    createdOn: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    orgId: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    updatedOn: PropTypes.string.isRequired
  }),
  handleEditClose: PropTypes.func.isRequired,
  editTag: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default EditDialog;
