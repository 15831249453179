import { makeStyles } from "@kuva/ui-components";

import Theme from "~/common/Theme";

const useStyles = makeStyles()((_theme, { imgType }) => ({
  root: {},
  displayedImage: {
    position: "relative",
    width: "100%",
    height: "auto",
    backgroundColor: "#222",
    display: "flex",
    marginBottom: 6
  },
  image: {
    width: "100%",
    height: "100%",
    backgroundColor: "#222"
  },
  imageTypeSelector: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  rgbPreview: {
    width: "20%",
    height: "auto",
    backgroundColor: "#222",
    outline:
      imgType === "rgb" ? `1px solid ${Theme.kuvaColors.blue}` : "transparent"
  },
  swirPreview: {
    width: "20%",
    height: "auto",
    backgroundColor: "#222",
    outline:
      imgType === "swir" ? `1px solid ${Theme.kuvaColors.blue}` : "transparent"
  },
  unmaskedPreview: {
    width: "20%",
    height: "auto",
    backgroundColor: "#222",
    outline:
      imgType === "unmasked"
        ? `1px solid ${Theme.kuvaColors.blue}`
        : "transparent"
  },
  colorODPreview: {
    width: "20%",
    height: "auto",
    backgroundColor: "#222",
    outline:
      imgType === "colorOD"
        ? `1px solid ${Theme.kuvaColors.blue}`
        : "transparent"
  }
}));

export { useStyles };
