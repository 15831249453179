import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@mui/material";
import PropTypes from "prop-types";

const AddTagDialog = ({ setOpenDialog, open }) => {
  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleSubmit = () => {
    console.log("submitting");
    // TODO (lily): this functionality is not implemented at all, bc it should be replaced with
    // the other dialog within the tags settings
  };

  return (
    <Dialog
      disableEnforceFocus
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={"xs"}
    >
      <DialogTitle>Add Tag</DialogTitle>
      <DialogContent>
        <TextField
          label="Tag Name"
          variant="outlined"
          style={{ width: "100%" }}
        />
        <TextField
          label="Tag Type"
          variant="outlined"
          style={{ width: "100%" }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddTagDialog.propTypes = {
  setOpenDialog: PropTypes.func,
  open: PropTypes.bool
};

export default AddTagDialog;
