import { theme as defaultTheme } from "@kuva/ui-components";

let mediumAlpha = 0.8;
let lightAlpha = 0.4;

let theme = {
  ...defaultTheme,
  kuvaColors: {
    orange: "rgb(237, 93, 42)",
    mediumOrange: `rgba(237, 93, 42, ${mediumAlpha})`,
    lightOrange: `rgba(237, 93, 42, ${lightAlpha})`,
    blue: "rgb(46, 194, 204)",
    red: "rgb(255,0,0)",
    mediumBlue: `rgba(46, 194, 204, ${mediumAlpha})`,
    lightBlue: `rgba(46, 194, 204, ${lightAlpha})`,
    blueGreen: "rgb(25, 114, 120)",
    mediumBlueGreen: `rgba(25, 114, 120, ${mediumAlpha})`,
    lightBlueGreen: `rgba(25, 114, 120, ${lightAlpha})`,
    grey: "rgb(187, 184, 178)",
    mediumGrey: `rgba(187, 184, 178, ${mediumAlpha})`,
    lightGrey: `rgba(187, 184, 178, ${lightAlpha})`,
    black: "rgb(26, 28, 30)",
    mediumBlack: `rgba(26, 28, 30, ${mediumAlpha})`,
    lightBlack: `rgba(26, 28, 30, ${lightAlpha})`,
    yellowOrange: "#db7f2ed9",
    peach: "#f6be4e",
    green: "green",
    yellow: "rgb(255, 240, 0)"
  },
  overrides: {
    ...defaultTheme.overrides,
    MuiButton: {
      root: {
        padding: "0 4px"
      },
      contained: {
        "&:hover": {
          backgroundColor: "#212121"
        }
      }
    },
    MuiSwitch: {
      track: {
        "&": {
          opacity: 1.0,
          backgroundColor: "#808080"
        }
      }
    }
  },
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: "#2EC2CC" //kuvaColors.blue
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "rgb(237, 93, 42)" //kuvaColors.
    },
    error: {
      main: "#FF5100"
    },
    warning: {
      main: "#05BEC3"
    },
    info: {
      main: "#05BEC3"
    },
    success: {
      main: "#05BEC3"
    },
    text: {
      primary: "#808080",
      secondary: "#808080",
      disabled: "#05BEC3",
      hint: "#39362e"
    },
    background: {
      paper: "#000000",
      default: "#090F17"
    },
    divider: "#5F5D5D",
    action: {
      active: "#FFFFFF",
      hover: "#212121",
      hoverOpacity: 0.08,
      selected: "#212121",
      selectedOpacity: 0.04,
      disabled: "#808080",
      disabledBackground: "#808080",
      disabledOpacity: 0.38,
      focus: "#05BEC3",
      focusOpacity: 0.12,
      activatedOpacity: 0.12
    },

    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2
    // type: "dark",
  },
  typography: {
    ...defaultTheme.typography,
    htmlFontSize: 12,
    fontFamily: [
      "century-gothic",
      "Roboto",
      "Helvetica",
      "Arial",
      "sans-serif"
    ].join(","),
    fontSize: 10,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    subtitle1: {
      fontSize: 24,
      color: "white"
    },
    subtitle2: {
      fontSize: 10,
      color: "#9FA4AB"
    },
    body1: {
      fontSize: 10,
      color: "white"
    },
    body2: {
      fontSize: 14,
      color: "white"
    }
  }
};

export default theme;
