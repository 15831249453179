import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button, MenuItem, Popover, Typography } from "@mui/material";
import { noop } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";

import { useCameraSelectStyles } from "./styles";
export const CameraSelect = ({
  selectedCameras = [],
  cameras = [],
  selectedOrg = {},
  handleReset = noop,
  handleSelectAll = noop,
  handleSelectCamera = noop
}) => {
  const { classes } = useCameraSelectStyles();
  const [camAnchorEl, setCamAnchorEl] = useState(null);

  const availableCameras = cameras.filter(
    camera =>
      !selectedCameras?.some(
        selectedCamera => selectedCamera.deviceId === camera.deviceId
      )
  );

  const handleClick = camera => {
    const selectedCamera = {
      deviceId: camera.deviceId,
      orgId: camera.orgID,
      deviceName: camera.name
    };
    handleSelectCamera(selectedCamera);
  };

  const handleClickSelectAll = () => {
    if (cameras?.length > 0) {
      const selectedCameras = cameras.map(camera => ({
        deviceId: camera.deviceId,
        orgId: camera.orgID,
        deviceName: camera.name
      }));
      handleSelectAll(selectedCameras);
    }
  };

  const handleCamClick = event => {
    setCamAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setCamAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      {cameras && (
        <>
          <Button
            className={classes.button}
            onClick={handleCamClick}
            endIcon={<ArrowDropDownIcon />}
            variant="outlined"
          >
            Cameras
          </Button>
          <Popover
            anchorEl={camAnchorEl}
            anchorOrigin={{
              vertical: "bottom"
            }}
            open={Boolean(camAnchorEl)}
            onClose={handleClose}
            PaperProps={{
              className: classes.popover
            }}
          >
            <MenuItem onClick={handleClickSelectAll} key="select-all">
              <Typography className={classes.menuItemLabel}>All</Typography>
            </MenuItem>
            <MenuItem onClick={handleReset} key="reset">
              <Typography className={classes.menuItemLabel}>Reset</Typography>
            </MenuItem>
            {availableCameras.map(cam => (
              <MenuItem
                onClick={() => handleClick(cam)}
                key={cam.deviceId}
                value={cam}
              >
                <Typography className={classes.menuItemLabel}>
                  {cam.name || cam.deviceId}
                </Typography>
              </MenuItem>
            ))}
          </Popover>
        </>
      )}
    </div>
  );
};

CameraSelect.propTypes = {
  cameras: PropTypes.array,
  handleReset: PropTypes.func,
  handleSelectAll: PropTypes.func,
  handleSelectCamera: PropTypes.func,
  selectedCameras: PropTypes.array,
  selectedOrg: PropTypes.object
};
