import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import Stack from "@mui/material/Stack";

import { getFloatingNumber } from "@kuva/ui-helpers";

import { calculateOverallConf } from "~/common/utils/dataUtils";

const AlarmMoreInformation = ({ alarm, hoveredScan }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(prev => !prev);
  };

  const renderMoreInfo = () => (
    <>
      <Typography
        variant="subtitle2"
        sx={{ cursor: "pointer", marginBottom: "10px" }}
        onClick={toggleShowMore}
      >
        <RemoveCircleOutlineOutlinedIcon style={{ verticalAlign: "middle" }} />
        Show Less
      </Typography>
      <Stack spacing={1}>
        <InfoRow
          label="Date"
          value={hoveredScan.createdOn?.split("T")[0] ?? "n/a"}
        />
        <InfoRow
          label="Confidence"
          value={getFloatingNumber(calculateOverallConf(hoveredScan))}
        />
        <InfoRow
          label="Noise Floor"
          value={getFloatingNumber(hoveredScan.noiseFloorMetric)}
        />
        <InfoRow
          label="Wind Speed"
          value={getFloatingNumber(hoveredScan?.windSpeed)}
        />
        <InfoRow
          label="Wind Direction"
          value={hoveredScan?.windDirection ?? "n/a"}
        />
        <InfoRow
          label="Sensor Temperature"
          value={getFloatingNumber(hoveredScan.telemetry?.sensor_temp)}
        />
        <InfoRow
          label="Board Temperature"
          value={getFloatingNumber(hoveredScan.telemetry?.board_temp)}
        />
        <InfoRow
          label="Frame Poi"
          value={hoveredScan?.poiOrientation ?? "None"}
        />
      </Stack>
    </>
  );

  return (
    <Box sx={{ marginTop: "10px" }}>
      {!showMore ? (
        <Typography
          variant="subtitle2"
          onClick={toggleShowMore}
          sx={{ cursor: "pointer" }}
        >
          <AddCircleOutline style={{ verticalAlign: "middle" }} />
          More Information
        </Typography>
      ) : (
        renderMoreInfo()
      )}
    </Box>
  );
};

const InfoRow = ({ label, value }) => (
  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
    <Typography variant="body1">{label}:</Typography>
    <Typography variant="body1">{value}</Typography>
  </Box>
);

export default AlarmMoreInformation;
