import AlarmAPI from "../apis/AlarmAPI";

/**
 * @name getAlarmById
 * @description this function is a middleware function that
 * 1. calls the get alarm by id api endpoint
 * 2. dispatches an action to redux to update it with the new data
 *
 * @author Sean W.
 * @date 25/03/2022
 *
 * @param {string} deviceId - the device id for a alarm to be fetched
 * @param {string} alarmId - the alarm to be saved
 *
 * @returns Promise({number} result)
 */
export const getAlarmById = (deviceId, alarmId) => {
  return dispatch => {
    dispatch({
      type: "SET_LOADING",
      loading: true
    });
    return new Promise((resolve, reject) => {
      AlarmAPI.getById(deviceId, alarmId)
        .then(response => {
          if (response.data) {
            dispatch({
              type: "FETCH_ALARM_BY_ID_SUCCESS",
              alarm: response.data
            });
            resolve(200);
          } else reject("empty response");
        })
        .catch(error => {
          console.log("error", error);
          reject(error);
        })
        .finally(() => {
          dispatch({
            type: "SET_LOADING",
            loading: false
          });
        });
    });
  };
};

/**
 * @name getAlarmByPagination
 * @description this function is a middleware function that
 * 1. calls the get alarm by pagination api endpoint
 * 2. dispatches an action to redux to update it with the new data
 *
 * @author Yves G.
 * @date 19/05/2022
 *
 * @param {string} deviceId - the device id for a alarm to be fetched
 * @param {string} alarmId - the specific alarm to be fetched
 * @param {number} page     - the number to return
 * @param {number} pageSize - the number of records in a page
 *
 * @returns Promise({number} result)
 *  Outputs (on success)
 *    status: html code
 *    data: array of records
 *    total: total records on dataset
 *    start: starting record index
 *    end: ending record index
 *    page: page echoed back
 *    pagesize: pagesize echoed back
 * Outputs (on error)
 *    status: html code
 *    message: error message
 *    stack: stack trace
 *    data: []
 *    total: 0
 *    start: null
 *    end: null
 *    page: page echoed back
 *    pagesize: pagesize echoed back
 */
export const getAlarmByPagination = (
  deviceId,
  page,
  pageSize,
  resetRequired = true
) => {
  return dispatch => {
    dispatch({
      type: "SET_LOADING",
      loading: true
    });
    return new Promise((resolve, reject) => {
      AlarmAPI.getByPagination(deviceId, page, pageSize)
        .then(response => {
          if (response.data) {
            const { data: alarms, ...pagination } = response.data;
            dispatch({
              type: "FETCH_ALARMS_BY_PAGE_SUCCESS",
              alarms,
              pagination,
              resetRequired
            });
            const day = alarms[alarms.length - 1];
            resolve(day);
          } else reject("empty response");
        })
        .catch(error => {
          console.log("error", error);
          dispatch({
            type: "SET_LOADING",
            loading: false
          });
          reject(error);
        });
    });
  };
};

export const getAlarmByMonth = (
  deviceId,
  year,
  month,
  deviceChanged = false
) => {
  return dispatch => {
    month = month.toString();
    if (month.split("")[0] === "0") month = month.split("")[1];
    return AlarmAPI.getAlarmsPerMonth(deviceId, month, year)
      .then(response => {
        if (response.data) {
          const alarms = response.data.data;
          dispatch({
            type: "FETCH_ALARMS_BY_MONTH_SUCCESS",
            alarms,
            deviceChanged
          });
          return 200;
        } else {
          throw new Error("empty response");
        }
      })
      .catch(error => {
        console.log("error", error);
        dispatch({
          type: "FETCH_ALARMS_BY_MONTH_ERROR",
          error
        });
        throw error;
      });
  };
};

/**
 * @name addEditAlarm
 * @description this function is a middleware function that
 * 1. calls the upsert api endpoint
 * 2. dispatches an action to redux to update it with the new data
 *
 * @author Sean W.
 * @date 25/03/2022
 *
 * @param {string} deviceId - the device id for a alarm to be fetched
 * @param {IAlarm} alarm - the alarm to be saved
 *
 * @returns Promise({string} alarmId)
 */

export const addEditAlarm = (deviceId, alarm) => {
  return () => {
    return new Promise((resolve, reject) => {
      //TODO: (sean) these are params that can go into the alarm saving
      const imageProps = {
        falsecolorod: "false",
        unmasked: "false",
        nonDetections: "false",
        minThreshold: 0
      };

      AlarmAPI.upsert(deviceId, alarm, imageProps)
        .then(response => {
          console.log("Add Edit Alarm Success:", response.data);

          if (response.data) {
            //dispatch({ type: "FETCH_ALARM_BY_ID_SUCCESS", alarm: response.data });
            resolve(response.data);
          } else reject("empty response");
        })
        .catch(error => {
          console.log("error", error);
          reject(error);
        });
    });
  };
};

/**
 * @name deleteAlarmByID
 * @description this function is a middleware function that
 * 1. calls the delete api endpoint
 * 2. dispatches an action to redux to update it with the new data
 *
 * @author Sean W.
 * @date 25/03/2022
 *
 * @param {string} deviceId - the device id for a alarm to be fetched
 * @param {string} alarmId - the alarm to be saved
 *
 * @returns Promise({string} alarmId)
 */
export const deleteAlarmByID = (deviceId, alarmId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      AlarmAPI.delete(deviceId, alarmId)
        .then(response => {
          console.log("ALARM DELETED:", response);
          dispatch({ type: "DELETE_ALARM_BY_ID_SUCCESS", id: alarmId });
          resolve(alarmId);
        })
        .catch(error => {
          console.log("error", error);
          reject(error);
        });
    });
  };
};

export const setLoading = loading => {
  return dispatch => {
    dispatch({
      type: "SET_LOADING",
      loading
    });
  };
};

export const getAlarmsPerSession = ({ sessionIds }) => {
  return new Promise((resolve, reject) => {
    AlarmAPI.getAlarmsPerSession({ sessionIds })
      .then(response => {
        if (response.data) {
          resolve(response.data);
        } else reject("empty response");
      })
      .catch(error => {
        console.log("error", error);
        reject(error);
      });
  });
};

export const updateAlarmStatusAction = payload => {
  return dispatch => {
    dispatch({
      type: "SET_LOADING",
      loading: true
    });
    return new Promise((resolve, reject) => {
      AlarmAPI.updateAlarmStatus(payload)
        .then(response => {
          dispatch({
            type: "FETCH_ALARM_BY_ID_SUCCESS",
            alarm: response.data
          });
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch({
            type: "SET_LOADING",
            loading: false
          });
        });
    });
  };
};
