import { GrowthBook } from "@growthbook/growthbook-react";

export const featureFlagClient = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: process.env.REACT_APP_FEATURE_FLAG_CLIENT_KEY,
  enableDevMode: process.env.NODE_ENV !== "production",
  trackingCallback: (/* experiment, result */) => {
    // This callback can be used by an analytics client to track feature flag
    // based variations and experiments.
    //
    // {
    //   experimentId: experiment.key,
    //   variationId: result.key
    // }
  }
});
