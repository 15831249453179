import { makeStyles } from "@kuva/ui-components";

export const useStyles = makeStyles()(theme => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%"
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  tagsInputLabel: {
    marginLeft: "4px",
    marginTop: "3px",
    fontSize: "12px"
  },
  toggle: {
    top: 0,
    zIndex: 10,
    position: "sticky",
    textAlign: "center",
    background: "black",
    padding: "5px"
  },
  "&.MuiSelect-icon": {
    right: "10px"
  }
}));
