import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()({
  root: {
    height: 30,
    background: "#000",
    position: "sticky",
    top: 0,
    zIndex: 2
  },
  autocomplete: {
    width: 450
  },
  iconButton: {
    float: "right"
  },
  popoverBody: {
    padding: 20
  },
  poiCheckBox: {
    marginRight: 8
  },
  checkboxLabel: {
    fontSize: 12
  },
  textField: { border: "1px solid", borderRadius: "5px" }
});

export { useStyles };
