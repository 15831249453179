import { IconButton, Tooltip } from "@mui/material";
import {
  Edit as EditIcon,
  Clear as ClearIcon,
  Undo as RevertIcon
} from "@mui/icons-material";
import PropTypes from "prop-types";

const UpdateTagButton = ({ tagId, handleClick, tooltipTitle, variant }) => {
  return (
    <Tooltip title={tooltipTitle} placement="top" arrow>
      <IconButton
        role="button"
        style={{ color: "lightgrey" }}
        variant="outlined"
        color="primary"
        onClick={() => handleClick(tagId)}
        size="large"
      >
        {variant === "edit" ? (
          <EditIcon />
        ) : variant === "revert" ? (
          <RevertIcon />
        ) : (
          <ClearIcon />
        )}
      </IconButton>
    </Tooltip>
  );
};

UpdateTagButton.propTypes = {
  tagId: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  tooltipTitle: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired
};

export default UpdateTagButton;
