import { useFeatureIsOn } from "@growthbook/growthbook-react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import SettingsIcon from "@mui/icons-material/Settings";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import { Badge, Button, ListItem, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { flags } from "~/constants/feature-flags";
import { useAuthenticatedUser } from "~/hooks";

import KuvaLogo from "../common/media/Kuva_Symbol_Rev.svg";

import {
  StyledNavContainer,
  StyledNavWrapper,
  useNavigationBarStyles
} from "./styles";

const NavigationBar = ({ drawerWidth, alarmCount, orgId, deviceId }) => {
  const { classes } = useNavigationBarStyles();
  const isCameraPresetListsMgmtOn = useFeatureIsOn(
    flags.CAMERA_PRESET_LISTS_MGMT
  );
  const { hasReviewerAccessOrQA } = useAuthenticatedUser();

  return (
    <StyledNavContainer width={drawerWidth}>
      <img className={classes.logo} alt="Kuva Systems Inc." src={KuvaLogo} />{" "}
      <StyledNavWrapper>
        {hasReviewerAccessOrQA && (
          <ListItem component={Link} to={"/sessions-landing"}>
            <Button className={classes.button}>
              <div>
                <ViewCarouselIcon className={classes.icon} />
                <Typography className={classes.label}>Sessions</Typography>
              </div>
            </Button>
          </ListItem>
        )}
        <ListItem component={Link} to={`/events/${orgId}/${deviceId}`}>
          <Button className={classes.button}>
            <div>
              <ViewCarouselIcon className={classes.icon} />
              <Typography className={classes.label}>Tagging</Typography>
            </div>
          </Button>
        </ListItem>
        {hasReviewerAccessOrQA && (
          <ListItem component={Link} to={`/labeller/${orgId}/${deviceId}`}>
            <Button className={classes.button}>
              <div>
                <ViewCarouselIcon className={classes.icon} />
                <Typography className={classes.label}>Labeler</Typography>
              </div>
            </Button>
          </ListItem>
        )}
        <ListItem component={Link} to={`/alarms/${orgId}/${deviceId}`}>
          <Button className={classes.button}>
            <div>
              <Badge
                badgeContent={alarmCount}
                color="primary"
                overlap="circular"
              >
                <ErrorOutlineIcon className={classes.icon} />
              </Badge>
              <Typography className={classes.label}>Alarms</Typography>
            </div>
          </Button>
        </ListItem>
        {hasReviewerAccessOrQA && isCameraPresetListsMgmtOn && (
          <ListItem component={Link} to="/camera-lists">
            <Button className={classes.button}>
              <div>
                <PhotoCameraIcon className={classes.icon} />
                <Typography className={classes.label}>Presets</Typography>
              </div>
            </Button>
          </ListItem>
        )}
        <ListItem component={Link} to="/settings">
          <Button className={classes.button}>
            <div>
              <SettingsIcon className={classes.icon} />
              <Typography className={classes.label}>Settings</Typography>
            </div>
          </Button>
        </ListItem>
      </StyledNavWrapper>
    </StyledNavContainer>
  );
};

NavigationBar.propTypes = {
  drawerWidth: PropTypes.string.isRequired,
  alarmCount: PropTypes.number,
  orgId: PropTypes.string.isRequired,
  deviceId: PropTypes.string.isRequired
};

export default NavigationBar;
