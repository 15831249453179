const sortMixedByDate = scans => {
  return scans.sort((a, b) => {
    if ((a.createdOn || a.created_time) > (b.createdOn || b.created_time)) {
      return -1;
    }
    if ((a.createdOn || a.created_time) < (b.createdOn || b.created_time)) {
      return 1;
    }
    return 0;
  });
};

// not in use now
export const matchTelemetryWithScans = (_telemetryList, _scansList) => {
  if (
    // skip if scanList is empty or telemetry is null
    !_telemetryList ||
    !_scansList ||
    !_scansList.length
  )
    return _scansList;

  console.time("matchTelemetryWithScans");
  const retval = [];
  // with scanResults, time is reversed, first is latest.
  // first, we will create a single date ordered list from the scanResults and telemetry
  const newList = sortMixedByDate(
    _scansList.concat(
      _telemetryList.filter(telem => telem.created_time !== "n/a") // skip items with no dates
    )
  );
  // now we loop over each item in the new ordered list
  for (let i = 0; i < newList.length; i++) {
    const element = newList[i];
    if (element.created_time) continue; // telemetry items have field created_time so skip them

    //in a normal stuation every scanResult should be sandwiched between telemetry items
    // so we get the previous and next item (reversed becaus latest is first)
    const previous = newList[i + 1];
    const next = newList[i - 1];

    // if the next item exists and is a telemetry object and is no later than 90 seconds after the scanResult time
    if (
      next?.created_time &&
      Math.abs(
        new Date(next.created_time).getTime() -
          new Date(element.createdOn).getTime()
      ) <
        90 * 1000
    ) {
      // attach telemetry data to the scanResult
      element.telemetry = {
        windSpeed: next.wind_speed.toFixed(2),
        windDirection: next.wind_direction,
        timestamp: next.created_time
      };

      // if the previous item exists and is a telemetry object and is no earlier than 90 seconds before the scanResult time
    } else if (
      previous?.created_time &&
      Math.abs(
        new Date(element.createdOn).getTime() -
          new Date(previous.created_time).getTime()
      ) <
        90 * 1000
    ) {
      // attach telemetry data to the scanResult
      element.telemetry = {
        windSpeed: previous.wind_speed.toFixed(2),
        windDirection: previous.wind_direction,
        timestamp: previous.created_time
      };
    } else {
      // if no telemetry is found before or after just add "N/A"
      element.telemetry = {
        windSpeed: "N/A",
        windDirection: "N/A",
        timestamp: "N/A"
      };
    }
    // add the scan result to the returned array
    retval.push(element);
  }
  console.timeEnd("matchTelemetryWithScans");
  // return the new scan results
  return retval;
};

//TODO: This is a temp solution to remove duplicate images and should happen in the backend
//TODO: should be handled in the backend!!!!
export const filterDuplicates = response => {
  let newArray = [];
  response.forEach(image => {
    let duplicate = false;
    newArray.forEach(newImage => {
      if (image.createdOn === newImage.createdOn) {
        duplicate = true;
      }
    });
    if (!duplicate) {
      newArray.push(image);
    }
  });
  return newArray;
};

export const getPois = (scans = []) => {
  if (!scans.length) return [];

  const storageSet = new Set(["All"]);

  scans.forEach(scan => {
    if (scan?.poiOrientation !== undefined && scan?.poiOrientation !== null) {
      storageSet.add(String(scan?.poiOrientation));
    }
  });

  return Array.from(storageSet);
};

export const convertStringOfNumbersToArray = string => {
  let stringsArray = string.split(",");
  let numbersArray = stringsArray.map(string => {
    return Number(string);
  });
  return numbersArray;
};

export const getValueFromSessionStorage = (name, defaultValue) => {
  let value = sessionStorage.getItem(name);

  if (value) {
    if (name === "confidenceValues") {
      return convertStringOfNumbersToArray(value);
    } else if (name === "confColors" || name === "noiseColors") {
      return value.split(",");
    } else if (typeof defaultValue === "number") {
      return Number(value);
    } else if (typeof defaultValue === "boolean") {
      return value === "true";
    }
    return value;
  }
  return defaultValue;
};

export const calculateOverallConf = scan => {
  let calculatedConf = scan?.overallConf;

  if (scan?.detections) {
    scan?.detections.forEach(detection => {
      if (detection.tag === "invalid") {
        calculatedConf -= detection.sumconf;
      }
    });
  }

  return calculatedConf;
};

export const getCurrentYearMonth = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  return { year, month };
};
