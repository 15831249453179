import isEmpty from "lodash/isEmpty";

export function isCounterClockwise(startPoint, endPoint, testPoint) {
  return (
    (testPoint.y - startPoint.y) * (endPoint.x - startPoint.x) >
    (endPoint.y - startPoint.y) * (testPoint.x - startPoint.x)
  );
}

export function isSegmentsIntersect(
  segment1Start,
  segment1End,
  segment2Start,
  segment2End
) {
  return (
    isCounterClockwise(segment1Start, segment2Start, segment2End) !==
      isCounterClockwise(segment1End, segment2Start, segment2End) &&
    isCounterClockwise(segment1Start, segment1End, segment2Start) !==
      isCounterClockwise(segment1Start, segment1End, segment2End)
  );
}

export function pointInSegment(point, segment) {
  let inside = false;

  // Iterate over each edge of the segment
  for (let i = 0, j = segment.length - 1; i < segment.length; j = i++) {
    const xi = segment[i].x,
      yi = segment[i].y;
    const xj = segment[j].x,
      yj = segment[j].y;

    const intersect =
      // Check if the y-coordinate of the point is within the y-range of the edge
      yi > point.y !== yj > point.y &&
      // Check if the x-coordinate of the point lies to the left of the edge
      point.x < ((xj - xi) * (point.y - yi)) / (yj - yi) + xi;

    // Toggle the inside flag if the edge intersects with the ray passing through the point
    if (intersect) inside = !inside;
  }

  // Return true if the number of intersections with edges is odd, indicating that the point is inside the polygon/segment
  return inside;
}

export function detectSegmentOverlap(segment1, segment2) {
  // Check if any vertex of segment1 lies inside segment2
  for (const vertex of segment1) {
    if (pointInSegment(vertex, segment2)) return true;
  }

  // Check if any vertex of segment2 lies inside segment1
  for (const vertex of segment2) {
    if (pointInSegment(vertex, segment1)) return true;
  }

  // Check if any segment of segment1 intersects with any segment of segment2
  for (let i = 0; i < segment1.length; i++) {
    const p1 = segment1[i];
    const p2 = segment1[(i + 1) % segment1.length];
    for (let j = 0; j < segment2.length; j++) {
      const p3 = segment2[j];
      const p4 = segment2[(j + 1) % segment2.length];
      if (isSegmentsIntersect(p1, p2, p3, p4)) return true;
    }
  }

  return false;
}

export const findOverlappingSegmentsInFrame = (segments, newSegment) => {
  if (!isEmpty(segments)) {
    for (let segment of segments) {
      if (detectSegmentOverlap(segment.points, newSegment)) {
        return true;
      }
    }
    return false;
  } else {
    return false;
  }
};

export const getDistanceSegment = (segments, leakSourceOrigin) => {
  const distanceSegment = segments?.find(segment =>
    detectSegmentOverlap(segment.points, leakSourceOrigin)
  );
  return distanceSegment || {};
};

export const handleSegmentingPoiChange = ({
  rawScanResults,
  poi,
  handleImgUrl,
  setSelectedPoi,
  setFullScreenImgUrl,
  imageType = "colDes"
}) => {
  const scanResultsFromSelectedPoi = rawScanResults.filter(
    scan => Number(scan.poiOrientation) === Number(poi)
  );
  const newSelectedScan = scanResultsFromSelectedPoi?.at(0);
  const url = handleImgUrl(newSelectedScan.jpg, imageType);
  setSelectedPoi(poi);
  setFullScreenImgUrl(url);
};
