import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import PropTypes from "prop-types";

import { organization } from "~/pages/settingsPage/TagManagement/filterOptions";

const FilterTagsToggle = ({ tagsFilter, setTagsFilter }) => {
  const handleClick = (event, newFilter) => {
    setTagsFilter(newFilter);
  };

  return (
    <ToggleButtonGroup
      value={tagsFilter}
      exclusive
      onChange={handleClick}
      aria-label="filter by"
    >
      <ToggleButton value={organization.default} aria-label="default">
        <div>Default</div>
      </ToggleButton>
      <ToggleButton value={organization.custom} aria-label="custom">
        <div>Custom</div>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

FilterTagsToggle.propTypes = {
  tagsFilter: PropTypes.string,
  setTagsFilter: PropTypes.func
};

export default FilterTagsToggle;
