import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { TreeView } from "@mui/x-tree-view/TreeView";
import clsx from "clsx";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";

import { makeStyles } from "@kuva/ui-components";

import { useOrganization } from "~/hooks";

const useStyles = makeStyles()(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    marginLeft: 4
  },
  nestedListItem: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: "inherit"
    // color: "#fff",
  },
  selected: {
    color: theme.palette.primary.main
  }
}));

export const OrganizationSelect = ({ organizations: customOrgs, onSelect }) => {
  const { classes } = useStyles();
  const location = useLocation();
  const params = location.pathname.split("/");
  const pageBaseUrl = params[1];

  const { organizations, selectedOrg } = useOrganization();
  const selectedOrgs = !isEmpty(customOrgs) ? customOrgs : organizations;

  const renderTree = nodes => (
    <TreeItem
      component={ListItem}
      key={nodes.id}
      nodeId={nodes.id}
      label={
        <Link
          onClick={() => onSelect(nodes)}
          to={`/${pageBaseUrl}/${nodes.id}`}
          className={clsx(classes.nestedListItem, {
            [classes.selected]: nodes.id === selectedOrg
          })}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
          selected={nodes.id === selectedOrg}
        >
          <ListItemAvatar>
            <Avatar
              style={{
                height: "30px",
                width: "30px"
              }}
              alt={nodes.orgname}
              src={nodes.logoURL}
            />
          </ListItemAvatar>

          <ListItemText primaryTypographyProps={{ variant: "body2" }}>
            {nodes.orgname}
          </ListItemText>
        </Link>
      }
    >
      {nodes.children?.length > 0 &&
        nodes.children.map(node => renderTree(node))}
    </TreeItem>
  );

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={selectedOrgs.map(o => o.id)}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {selectedOrgs.map(o => renderTree(o))}
    </TreeView>
  );
};

OrganizationSelect.propTypes = {
  organizations: PropTypes.array,
  onSelect: PropTypes.func
};
