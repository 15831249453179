import { isNil } from "lodash";

export const determineColor = (score, scoreRanges, scoreColors) => {
  if (isNil(score)) {
    return "gray"; // Fallback color for undefined or null scores
  }

  const index = scoreRanges.findIndex(range => score < range);

  // If the score is higher than all the ranges, return the last color
  return index === -1
    ? scoreColors[scoreColors.length - 1]
    : scoreColors[index];
};
