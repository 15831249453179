export const units = Object.freeze({
  IMPERIAL: "imperial",
  METRIC: "metric"
});

export const MILES_PER_HOUR = "MILES_PER_HOUR";
export const KILOMETERS_PER_HOUR = "KILOMETERS_PER_HOUR";
export const METERS_PER_SECOND = "METERS_PER_SECOND";
export const CUBIC_METERS_PER_DAY = "CUBIC_METERS_PER_DAY";
export const ALGO_CUBIC_METERS_PER_DAY = "ALGO_CUBIC_METERS_PER_DAY";

export const STANDARD_CUBIC_FEET_PER_HOUR = "STANDARD_CUBIC_FEET_PER_HOUR";
export const STANDARD_CUBIC_METERS_PER_HOUR = "STANDARD_CUBIC_METERS_PER_HOUR";

export const WindSpeedUnits = Object.freeze({
  [MILES_PER_HOUR]: "IMPERIAL_MPH",
  [KILOMETERS_PER_HOUR]: "METRIC_KMH",
  [METERS_PER_SECOND]: "METRIC_MPS"
});

export const LeakRateUnits = Object.freeze({
  [STANDARD_CUBIC_FEET_PER_HOUR]: "IMPERIAL_SCFH",
  [STANDARD_CUBIC_METERS_PER_HOUR]: "METRIC_SCMH"
});

export const UnitNames = Object.freeze({
  [MILES_PER_HOUR]: "mph",
  [KILOMETERS_PER_HOUR]: "km/h",
  [METERS_PER_SECOND]: "m/s",
  [CUBIC_METERS_PER_DAY]: "Sm³/d",
  [STANDARD_CUBIC_FEET_PER_HOUR]: "SCFH",
  // Units of Measurement names used for Quantification Results:
  [ALGO_CUBIC_METERS_PER_DAY]: "m3/d"
});
