import { createSlice } from "@reduxjs/toolkit";

import { mlScoreSliderSettings } from "~/constants/ml-score";

const initialState = {
  mlScoreSliderSettings
};

const slice = createSlice({
  name: "ml-score",
  initialState,
  reducers: {
    setMLScoreRanges: (state, action) => {
      state.mlScoreSliderSettings.mlScoreRanges = action.payload;
    },
    setMLScoreColors: (state, action) => {
      state.mlScoreSliderSettings.mlScoreColors = action.payload;
    }
  }
});

export const MLScoreActions = {
  ...slice.actions
};

export const MLScoreReducer = slice.reducer;
