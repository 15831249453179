import { createAsyncThunk } from "@reduxjs/toolkit";

import SessionAPI from "~/common/apis/SessionAPI";

export const upsertCameraList = createAsyncThunk(
  "sessions/upsertCameraList",
  async (cameraList, { rejectWithValue }) => {
    try {
      const cameras = cameraList.cameras.map(
        ({ isNewlyAdded, ...rest }) => rest
      );
      const payloadData = {
        ...cameraList,
        cameras
      };

      const response = await SessionAPI.upsertCameraList(payloadData);
      return response;
    } catch (error) {
      if (error?.response && error?.response?.data) {
        return rejectWithValue({
          error: true,
          errorMessage: error.response.data?.message?.replace(/\\/g, "")
        });
      } else {
        return rejectWithValue({
          error: true,
          errorMessage: "Something went wrong."
        });
      }
    }
  }
);
