import { styled } from "@kuva/ui-components";

export const MainContainer = styled("div")({ display: "flex", height: "100%" });

export const CanvasContainer = styled("div")({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%"
});

export const CanvasPanel = styled("div")({
  flex: 1,
  background: "black",
  position: "relative"
});

export const CanvasWrapper = styled("div")(
  ({ scale, hasBorder, borderColour = "#00FFFF" }) => ({
    cursor: "default",
    border: hasBorder ? `1px solid ${borderColour}` : "none",
    transform: `scale(${scale})`,
    transformOrigin: "center"
  })
);

export const CanvasControls = styled("div")({
  margin: "100px 60px 0 20px"
});
