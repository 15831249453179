import AlarmAPI from "./AlarmAPI";
import AlgoAPI from "./AlgorithmAPI";
import CameraAPI from "./CameraAPI";
import OrganizationAPI from "./OrganizationAPI";
import SessionAPI from "./SessionAPI";

//TODO: this file will be removed when we implement APIM
export const setBaseURIs = (isGatewayFeatureOn, newState) => {
  CameraAPI.setBaseURI(
    isGatewayFeatureOn
      ? `${process.env.REACT_APP_KUVA_API_URL}/camera`
      : newState.REACT_APP_API_URL
  );
  AlarmAPI.setBaseURI(
    isGatewayFeatureOn
      ? `${process.env.REACT_APP_KUVA_API_URL}/alarm`
      : newState.REACT_APP_ALARM_API_URL
  );
  SessionAPI.setBaseURI(
    isGatewayFeatureOn
      ? `${process.env.REACT_APP_KUVA_API_URL}/session`
      : newState.REACT_APP_SESSION_API_URL
  );
  AlgoAPI.setBaseURI(
    isGatewayFeatureOn
      ? `${process.env.REACT_APP_KUVA_API_URL}/algo`
      : newState.REACT_APP_CLOUD_ALGOS_API_URL
  );
};

export const setAuthToken = token => {
  OrganizationAPI.setAuthToken(token);
  CameraAPI.setAuthToken(token);
  SessionAPI.setAuthToken(token);
  AlarmAPI.setAuthToken(token);
  AlgoAPI.setAuthToken(token);
};

export const setBaseOrgsHeaders = baseOrg => {
  CameraAPI.setBaseOrgHeader(baseOrg);
  AlarmAPI.setBaseOrgHeader(baseOrg);
  AlgoAPI.setBaseOrgHeader(baseOrg);
};
