import { TableCell, TableRow } from "@mui/material";
import PropTypes from "prop-types";

import UpdateTagButton from "../UpdateTagButton";

import { useStyles } from "./styles";

const TagRow = ({
  tag,
  handleRemoveClick,
  handleEditClick,
  handleRevertClick
}) => {
  const { classes } = useStyles();
  const className = handleRemoveClick === null ? classes.header : classes.row;

  return (
    <TableRow id={"row" + tag.id} key={"rowKey" + tag.id}>
      <TableCell className={className}>{tag.id}</TableCell>
      <TableCell className={className}>{tag.orgId}</TableCell>
      <TableCell className={className}>{tag.text}</TableCell>
      <TableCell className={className}>{tag.type}</TableCell>
      <TableCell className={className}>{tag.createdOn}</TableCell>
      <TableCell className={className}>{tag.updatedOn}</TableCell>
      {handleRemoveClick === null ? (
        <TableCell></TableCell>
      ) : (
        <>
          <TableCell style={{ position: "relative" }}>
            <UpdateTagButton
              tagId={tag.id}
              handleClick={handleEditClick}
              tooltipTitle="Edit Tag"
              variant="edit"
            />
            {tag.id > 14 ? (
              <UpdateTagButton
                tagId={tag.id}
                handleClick={handleRemoveClick}
                tooltipTitle="Delete Tag"
                variant="delete"
              />
            ) : tag.orgId === 0 ? (
              <span></span>
            ) : (
              // if the default tag has been edited, display a revert button
              <UpdateTagButton
                tagId={tag.id}
                handleClick={handleRevertClick}
                tooltipTitle="Revert to Default Tag"
                variant="revert"
              />
            )}
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

TagRow.propTypes = {
  tag: PropTypes.shape({
    createdOn: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    orgId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    text: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    updatedOn: PropTypes.string.isRequired
  }),
  handleRemoveClick: PropTypes.func,
  handleEditClick: PropTypes.func,
  handleRevertClick: PropTypes.func
};

export default TagRow;
