import { useAuth0 } from "@auth0/auth0-react";
import { Button, Paper, Typography } from "@mui/material";

import { makeStyles } from "@kuva/ui-components";

import { Loading } from "~/common/components";

const useStyles = makeStyles()({
  root: {
    position: "absolute",
    top: "0px",
    bottom: "0px",
    left: "0px",
    right: "0px",
    display: "flex",
    justifyContent: "center"
  },
  button: {
    margin: "auto",
    width: "20%",
    height: "auto",
    backgroundColor: "transparent",
    "&:hover": {
      background: "transparent"
    }
  },
  image: {
    width: "50%",
    height: "auto",
    display: "block"
  },
  version: {
    position: "absolute",
    bottom: 5,
    right: 5
  },
  spinnerHold: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: "fixed",
    backgroundColor: "#242E3B"
  },
  spinner2: {
    transform: "translate(-50%, -50%)",
    margin: 0,
    position: "absolute",
    top: "50%",
    left: "50%"
  }
});

const buttonSx = {
  textTransform: "none",
  color: "#808080"
};

const Landing = () => {
  const { classes } = useStyles();
  const { loginWithRedirect, isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className={classes.spinnerHold}>
        <div className={classes.spinner2}>
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <Paper className={classes.root}>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Typography style={{ fontSize: "20px" }}>
          Kuva Systems : Data Labeler
        </Typography>
        <Button onClick={loginWithRedirect} sx={buttonSx}>
          Log In
        </Button>
        <a href="https://rnd.kuva.cloud/" style={{ textDecoration: "none" }}>
          <Button sx={buttonSx}>Research and Development</Button>
        </a>
        <a
          href="https://app.kuvasystems.com/"
          style={{ textDecoration: "none" }}
        >
          <Button sx={buttonSx}>Client Portal</Button>
        </a>
        <a
          href="https://www.kuvasystems.com/"
          style={{ textDecoration: "none" }}
        >
          <Button sx={buttonSx}>Learn More</Button>
        </a>
      </div>

      <Typography className={classes.version} variant="subtitle2">
        Version {process.env.REACT_APP_VERSION}
      </Typography>
    </Paper>
  );
};

export default Landing;
