import { Box } from "@mui/material";
import PropTypes from "prop-types";

import { Tags } from "~/common/components";

export const DisplayTag = ({ tags, updateTags }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 25,
        right: 175,
        border: "1px solid grey",
        borderRadius: 1,
        width: 150,
        height: 100,
        p: 1,
        overflow: "auto"
      }}
    >
      {tags.length === 0 ? (
        <Box>No tags available</Box>
      ) : (
        <Tags tags={tags} updateTags={updateTags} />
      )}
    </Box>
  );
};

DisplayTag.propTypes = {
  tags: PropTypes.array.isRequired,
  updateTags: PropTypes.func.isRequired
};
