import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Avatar, Button, Divider, Tooltip, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { shallowEqual, useSelector } from "react-redux";

import {
  getWindDirection,
  getWindSpeed,
  getFloatingNumber
} from "@kuva/ui-helpers";

import {
  ImageTypeSelect,
  MetadataInfo,
  PoiSelect,
  TagTextField,
  TelemetryInfo
} from "~/common/components/Bar/components";
import { selectIsEditing } from "~/common/selectors/AlarmSelector";
import { getSelectedCamera } from "~/common/selectors/CameraSelector";

import { useStyles } from "./styles";

export const Bar = ({
  displayType,
  handleImgTypeButton,
  imgType,
  selectedScan,
  creatingAlarm,
  handleCreatingAlarm,
  selectedPoi,
  setSelectedPoi,
  poiKeys,
  setScanIndex,
  currentImageConfidence,
  tags,
  updateTags,
  setTagsFocus
}) => {
  const dividerStyle = { height: "3rem", width: 1, margin: "0 5px" };
  const avatarStyle = {
    fontSize: 13.33,
    height: 25,
    width: 25,
    background: "#05BEC3",
    margin: "0 5px",
    cursor: "pointer"
  };
  const { classes } = useStyles();
  const tooltipStyle = { background: "black" };
  const { telemetry } = selectedScan || {};
  const selectedCamera = useSelector(getSelectedCamera, shallowEqual);
  const isAlarmEditing = useSelector(selectIsEditing);

  return (
    <div className={classes.root}>
      <div className={classes.mainWrapper}>
        <>
          {displayType !== "panorama" && (
            <ImageTypeSelect
              handleImgTypeButton={handleImgTypeButton}
              imgType={imgType}
            />
          )}
          <Divider style={dividerStyle} />
          <PoiSelect
            displayType={displayType}
            selectedPoi={selectedPoi}
            setSelectedPoi={setSelectedPoi}
            poiKeys={poiKeys}
            setScanIndex={setScanIndex}
          />
          <Divider style={dividerStyle} />
        </>
        {displayType === "image" && (
          <>
            <Tooltip style={tooltipStyle} title="Shortcut: 'Ctrl + Z'">
              <span>
                <Button
                  style={{ width: "max-content" }}
                  disabled={creatingAlarm || isAlarmEditing}
                  variant="contained"
                  color="primary"
                  onClick={() => handleCreatingAlarm(selectedScan.createdOn)}
                  startIcon={<ErrorOutlineIcon />}
                >
                  Create Alarm
                </Button>
              </span>
            </Tooltip>
            <Tooltip
              style={tooltipStyle}
              title={
                <MetadataInfo
                  selectedScan={selectedScan}
                  currentImageConfidence={getFloatingNumber(
                    currentImageConfidence
                  )}
                />
              }
            >
              <Avatar style={avatarStyle}>M</Avatar>
            </Tooltip>
            <Tooltip
              style={tooltipStyle}
              title={<TelemetryInfo telemetry={telemetry} />}
            >
              <Avatar style={avatarStyle}>T</Avatar>
            </Tooltip>
          </>
        )}

        {displayType !== "panorama" && (
          <TagTextField
            tags={tags}
            updateTags={updateTags}
            setTagsFocus={setTagsFocus}
            displayType={displayType}
          />
        )}
      </div>
      <div>
        {displayType !== "panorama" && (
          <>
            <Typography className={classes.imageInfo}>
              Wind Direction: {getWindDirection(selectedScan?.windDirection)}
            </Typography>
            <Typography className={classes.imageInfo}>
              Wind Speed: {getWindSpeed(selectedScan?.windSpeed, "m/s")}
            </Typography>
          </>
        )}

        {displayType === "image" && (
          <>
            <Typography className={classes.imageInfo}>
              Time: {selectedScan?.createdOn.substring(11, 19)}
            </Typography>
            <Typography className={classes.imageInfo}>
              Device ID: {selectedCamera?.deviceId}
            </Typography>
            <Typography className={classes.imageInfo}>
              Device Name: {selectedCamera?.name}
            </Typography>
            <Typography className={classes.imageInfo}>
              ML Score: {selectedScan?.mlScore?.score}
            </Typography>
          </>
        )}
      </div>
      {displayType === "carousel" && (
        <div className={classes.deviceData}>
          <Typography className={classes.font14}>
            Device ID: {selectedCamera?.deviceId}
          </Typography>
          <Typography className={classes.font14}>
            Device Name: {selectedCamera?.name}
          </Typography>
        </div>
      )}
    </div>
  );
};

Bar.propTypes = {
  displayType: PropTypes.string.isRequired,
  handleImgTypeButton: PropTypes.func.isRequired,
  imgType: PropTypes.string.isRequired,
  selectedScan: PropTypes.object.isRequired,
  creatingAlarm: PropTypes.bool.isRequired,
  handleCreatingAlarm: PropTypes.func.isRequired,
  selectedPoi: PropTypes.number.isRequired,
  setSelectedPoi: PropTypes.func.isRequired,
  poiKeys: PropTypes.array.isRequired,
  setScanIndex: PropTypes.func.isRequired,
  currentImageConfidence: PropTypes.number.isRequired,
  tags: PropTypes.array.isRequired,
  updateTags: PropTypes.func.isRequired,
  setTagsFocus: PropTypes.func.isRequired
};
