import { styled } from "@kuva/ui-components";

const CanvasWrapper = styled("div")(({ scale, $interactive }) => ({
  border: "1px solid cyan",
  cursor: $interactive ? "crosshair" : "default",
  transform: `scale(${scale}) translate(-50%, -50%)`,
  position: "absolute",
  left: "50%",
  top: "50%",
  transformOrigin: "0% 0% 0px"
}));

export default CanvasWrapper;
