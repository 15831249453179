import PropTypes from "prop-types";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import PanoramaWideAngleIcon from "@mui/icons-material/PanoramaWideAngle";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";

export const DisplayToggle = ({ displayType, onChange, className }) => {
  return (
    <ToggleButtonGroup
      className={className}
      value={displayType}
      exclusive
      onChange={onChange}
    >
      <ToggleButton value="panorama">
        <PanoramaWideAngleIcon />
      </ToggleButton>
      <ToggleButton value="image">
        <ViewColumnIcon />
      </ToggleButton>
      <ToggleButton value="carousel">
        <ViewCarouselIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

DisplayToggle.propTypes = {
  displayType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string
};
