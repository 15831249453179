import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { isEmpty } from "lodash";

export const NoAlarmSelectedMessage = ({
  reviewerMode,
  handleGoBackToSession,
  currentSession
}) => {
  return (
    <Box m="2em auto" sx={{ textAlign: "center" }}>
      <Typography sx={{ fontSize: "1.5em" }}>No alarm is selected.</Typography>
      {reviewerMode === "REVIEWER-SESSION" && !isEmpty(currentSession) && (
        <Button
          variant="contained"
          color="primary"
          sx={{ marginTop: "20px" }}
          onClick={handleGoBackToSession}
        >
          Go back to session
        </Button>
      )}
    </Box>
  );
};

NoAlarmSelectedMessage.propTypes = {
  reviewerMode: PropTypes.string.isRequired,
  handleGoBackToSession: PropTypes.func.isRequired,
  currentSession: PropTypes.object.isRequired
};
