import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { GlobalStyle } from "GlobalStyle";
import PropTypes from "prop-types";

export const ImageTypeSelect = ({ handleImgTypeButton, imgType }) => {
  const { classes: globalStyle } = GlobalStyle();
  const handleChange = event => {
    const type = event.target.value;
    handleImgTypeButton(type);
  };

  return (
    <FormControl variant="standard" className={globalStyle.formControlSelect}>
      <InputLabel className={[globalStyle.font14, globalStyle.uppercase]}>
        Image type
      </InputLabel>
      <Select
        variant="standard"
        value={imgType}
        displayEmpty
        onChange={handleChange}
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem value={"colDen"}>Column Density</MenuItem>
        <MenuItem value={"colOD"}>Colorized OD</MenuItem>
        <MenuItem value={"unmasked"}>Unmasked</MenuItem>
      </Select>
    </FormControl>
  );
};

ImageTypeSelect.propTypes = {
  handleImgTypeButton: PropTypes.func,
  imgType: PropTypes.string
};
