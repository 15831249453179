export const getAlarmState = state => {
  return state.alarmState;
};

export const getAlarms = state => {
  return state.alarmState?.alarms;
};

export const getSelectedAlarm = state => {
  return state.alarmState?.selected;
};

export const selectIsEditing = state => state.alarmState?.isEditing;

export const getPagination = state => {
  return state.alarmState?.pagination;
};

export const selectSelectedPois = state => {
  return state.alarmState?.selectedPois;
};

export const selectPoiList = state => {
  return state.alarmState?.poiList;
};
