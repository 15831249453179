import { Badge } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { useStyles } from "./styles";

const LeakSourceBox = ({ leakSource, img, fullScreenMode, scan }) => {
  const { classes } = useStyles();
  const [coords, setCoords] = useState({});

  /** translates from an XY coordinate system to css attributes we can work with
   * the original object has an 8 integer array representing 4 pairs of X/Y pixel coordinates
   * We translate the four corners to top, left, height and width integers and multiply by the aspect ratio (clientDimensions/naturalDimentions).
   */
  const handleCoords = () => {
    const imgWidth = img?.current?.naturalWidth || 313;
    const imgHeight = img?.current?.naturalHeight || 410;
    const imgRatio = imgWidth / imgHeight;
    const imgScaleX = scan?.imgscaleX || 1;
    if (fullScreenMode) {
      const screenRatio = window.innerWidth / window.innerHeight;
      const predictedWidth =
        screenRatio > imgRatio
          ? parseInt(window.innerHeight * imgRatio)
          : window.innerWidth;
      const predictedHeight =
        screenRatio < imgRatio
          ? parseInt(window.innerWidth / imgRatio)
          : window.innerHeight;
      const xOffset = (window.innerWidth - predictedWidth) / 2;
      const yOffset = (window.innerHeight - predictedHeight) / 2;
      setCoords({
        left:
          (leakSource?.coords[0] * (predictedWidth * imgScaleX)) / imgWidth +
          xOffset,
        width:
          (leakSource?.coords[2] - leakSource?.coords[0]) *
          ((predictedWidth * imgScaleX) / imgWidth),
        top: (leakSource?.coords[1] * predictedHeight) / imgHeight + yOffset,
        height:
          (leakSource?.coords[5] - leakSource?.coords[1]) *
          (predictedHeight / imgHeight)
      });
    } else {
      let width = img?.current?.width * imgScaleX;
      let height = img?.current?.height;
      setCoords({
        left: leakSource?.coords[0] * (width / imgWidth),
        width:
          (leakSource?.coords[2] - leakSource?.coords[0]) * (width / imgWidth),
        top: leakSource?.coords[1] * (height / imgHeight),
        height:
          (leakSource?.coords[5] - leakSource?.coords[1]) * (height / imgHeight)
      });
    }
  };

  useEffect(() => {
    handleCoords();

    window.addEventListener("resize", handleCoords);

    return () => {
      window.removeEventListener("resize", handleCoords);
    };
  }, [window.innerWidth, leakSource]);

  return (
    <Badge
      className={classes.redRectangle}
      style={{
        ...coords,
        display: "flex"
      }}
    />
  );
};

LeakSourceBox.propTypes = {
  leakSource: PropTypes.object.isRequired,
  img: PropTypes.oneOfType([
    PropTypes.shape({
      current: PropTypes.instanceOf(HTMLInputElement)
    }),
    PropTypes.oneOf([null])
  ]).isRequired,
  fullScreenMode: PropTypes.bool
};

export default LeakSourceBox;
