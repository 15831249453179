import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Checkbox, IconButton, Popover, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
  selectPoiList,
  selectSelectedPois
} from "~/common/selectors/AlarmSelector";

import { useStyles } from "./styles";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AlarmFilter = () => {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const selectedPois = useSelector(selectSelectedPois, shallowEqual);
  const poiList = useSelector(selectPoiList, shallowEqual);

  const dispatch = useDispatch();
  const open = Boolean(anchorEl);

  return (
    <Box className={classes.root}>
      <IconButton
        className={classes.iconButton}
        onClick={e => setAnchorEl(e.currentTarget)}
        size="small"
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <div className={classes.popoverBody}>
          <Autocomplete
            className={classes.autocomplete}
            multiple
            data-testid="select-poi"
            value={selectedPois}
            options={poiList}
            disableCloseOnSelect
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={option => option?.id}
            ChipProps={{ color: "primary", size: "small" }}
            onChange={(_event, selected) => {
              dispatch({
                type: "SET_SELECTED_POIS",
                selectedPois: selected
              });
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  className={classes.poiCheckBox}
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected}
                />
                <span className={classes.checkboxLabel}>{option?.id}</span>
              </li>
            )}
            renderInput={params => (
              <>
                <div>Filter by POI</div>
                <TextField
                  {...params}
                  variant="outlined"
                  className={classes.textField}
                />
              </>
            )}
          />
        </div>
      </Popover>
    </Box>
  );
};
export default AlarmFilter;
