import { makeStyles } from "@kuva/ui-components";

import { scrollbarStyles } from "~/common/styles";

export const useCameraSelectStyles = makeStyles()(({ spacing }) => ({
  button: {
    width: "240px",
    textAlign: "left",
    padding: spacing(0.8, 1, 0.7, 1),
    fontSize: "14px",
    border: "0.5px solid #A6A6A6",
    color: "white",
    justifyContent: "left"
  },
  actions: {
    marginTop: 6
  },
  popover: {
    backgroundColor: "#222222",
    width: "250px",
    maxHeight: "40vh",
    ...scrollbarStyles
  },
  menuItemLabel: {
    color: "inherit",
    fontSize: "14px"
  }
}));
