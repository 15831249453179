import { utcToZonedTime } from "date-fns-tz";

export const selectIsLoading = state => state.sessionState?.isLoading;

export const selectSelectionMode = state => state.sessionState?.selectionMode;

export const selectCameraLists = state => state.sessionState?.cameraLists;

export const selectSelectedCameraList = state =>
  state.sessionState?.selectedCameraList;

export const selectSelectedCameras = state =>
  state.sessionState?.selectedCameras;

export const selectSelectedDuration = state =>
  state.sessionState?.selectedDuration;

export const selectSelectedDate = state => {
  if (!state.sessionState?.selectedDate) return new Date();
  return utcToZonedTime(new Date(state.sessionState?.selectedDate));
};

export const selectSelectedDateRange = state =>
  state.sessionState?.selectedDateRange;

export const selectSelectedHourlyTimeslots = state =>
  state.sessionState?.selectedHourlyTimeslots;

export const selectSelectedTime = state => state.sessionState?.selectedTime;

export const selectCurrentSession = state => state.sessionState?.current;

export const selectCamerasSessions = state => state.sessionState?.sessions;

export const selectCamerasSessionEvents = state => state.sessionState?.events;

export const selectOpenHourlyDialog = state =>
  state.sessionState?.openHourlyDialog;

export const selectSessionsWithScres = state =>
  state.sessionState?.sessionsWithScres;

export const selectActiveHourlySessions = state =>
  state.sessionState?.activeHourlySessions;

export const selectSelectedDeviceHourlySessions = state =>
  state.sessionState?.selectedDeviceHourlySessions;

export const selectSelectedDeviceScresPerHour = state =>
  state.sessionState?.selectedDeviceScresPerHour;

export const selectIsSelectedAllHourlyTimeslots = state =>
  state.sessionState?.isSelectedAllHourlyTimeslots;
