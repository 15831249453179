import { initialState } from "./initialState";

// creates POI look-up data
const getPOIs = alarms => {
  let newPoiList = alarms.map(alarm => alarm.poiOrientation);
  newPoiList = [...new Set(newPoiList)].sort((a, b) => a - b).filter(Number);

  return newPoiList.map(poi => ({
    id: Math.round(poi)?.toString(),
    poi
  }));
};

export default function alarmsReducer(state = initialState.alarmState, action) {
  switch (action.type) {
    case "RESET_ALARMS_BY_MONTH": {
      return {
        ...state,
        alarmsByMonth: []
      };
    }

    case "FETCH_ALARMS_BY_PAGE_SUCCESS": {
      const { pagination, resetRequired, alarms: alarmList } = action;
      const mergedAlarms = getUnique([...state.alarms, ...alarmList]);
      const alarms = resetRequired ? alarmList : sortByDate(mergedAlarms);
      const poiList = getPOIs(alarms);

      return {
        ...state,
        pagination,
        alarms,
        poiList,
        selectedPois: [],
        loading: false
      };
    }

    case "FETCH_ALARMS_BY_MONTH_SUCCESS": {
      const { deviceChanged, alarms: alarmList } = action;
      const mergedAlarms = getUnique([...state.alarmsByMonth, ...alarmList]);
      const alarmsByMonth = deviceChanged
        ? alarmList
        : sortByDate(mergedAlarms);
      const poiList = getUnique([...state.poiList, ...getPOIs(alarmsByMonth)]);

      return {
        ...state,
        alarmsByMonth,
        poiList
      };
    }

    case "FETCH_ALARM_BY_ID_SUCCESS":
      return { ...state, selected: action.alarm };

    case "DELETE_ALARM_BY_ID_SUCCESS":
      return {
        ...state,
        alarms: sortByDate(state.alarms.filter(a => a.id !== action.id))
      };

    case "SET_SELECTED_POIS":
      return {
        ...state,
        selectedPois: action.selectedPois
      };

    case "SET_IS_EDITING":
      return { ...state, isEditing: action.isEditing };

    case "SET_LOADING":
      return { ...state, loading: action.loading };

    case "SET_ALARM_STATUS":
      return {
        ...state,
        alarms: state.alarms.map(alarm =>
          alarm.id === action.payload.alarmId
            ? { ...alarm, falseAlarm: action.payload.alarmStatus }
            : alarm
        )
      };

    default:
      return { ...state };
  }
}

const getUnique = (alarms, newAlarms) => {
  newAlarms?.map(x =>
    alarms.find(a => a.id === x.id) ? null : alarms.push(x)
  );

  return alarms;
};

const sortByDate = alarms => {
  return alarms.sort((a, b) => {
    if (a.start < b.start) {
      return -1;
    }
    if (a.start > b.start) {
      return 1;
    }
    return 0;
  });
};
