export const sortCamerasByDeviceName = cameraList => {
  if (!cameraList?.cameras) return cameraList;

  const sortedCameras = [...cameraList.cameras].sort((a, b) => {
    const nameA = (a.deviceName || a.deviceId || "").toLowerCase();
    const nameB = (b.deviceName || b.deviceId || "").toLowerCase();

    return nameA.localeCompare(nameB);
  });

  return {
    ...cameraList,
    cameras: sortedCameras
  };
};
