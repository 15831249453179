import { useFeatureIsOn } from "@growthbook/growthbook-react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Tooltip,
  Typography
} from "@mui/material";
import cloneDeep from "lodash/cloneDeep";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import { getFloatingNumber } from "@kuva/ui-helpers";

import CameraAPI from "~/common/apis/CameraAPI";
import CustomDialog from "~/common/components/Dialog/CustomDialog";
import DetectionBox from "~/common/components/Dialog/Detectionbox/DetectionBox";
import { selectIsEditing } from "~/common/selectors/AlarmSelector";
import { getSelectedCamera } from "~/common/selectors/CameraSelector";
import { calculateOverallConf } from "~/common/utils/dataUtils";
import { handleImgUrl } from "~/common/utils/functionUtils";
import { flags } from "~/constants/feature-flags";
import LeakSourceBox from "~/containers/LeakSourceLocationTool/components/LeakSourceBox";
import LeakSourcePoint from "~/containers/LeakSourceLocationTool/components/LeakSroucePoint";

import { useStyles } from "./Styles";

const Image = ({
  selected,
  scan,
  imgType,
  event,
  handleFullscreenImage,
  creatingAlarm,
  handleCreatingAlarm,
  setScanResults,
  setCurrentImageConfidence,
  selectedPoi,
  handleOpenDistanceMapper
}) => {
  const isSemiAutomaticQuantOn = useFeatureIsOn(flags.SEMI_AUTOMATIC_QUANT);

  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [restoreDialogOpen, setRestoreDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [confidence, setConfidence] = useState(0.0);
  const isAlarmEditing = useSelector(selectIsEditing);

  useEffect(() => {
    setConfidence(getFloatingNumber(calculateOverallConf(scan)));
    if (selected) {
      setCurrentImageConfidence(
        getFloatingNumber(calculateOverallConf(scan), 0)
      );
    }
  }, [scan?.detections]);

  const img = useRef(null);

  const showDetectionBoxes =
    sessionStorage.getItem("showDetectionBoxes") === "false" ? false : true;

  const open = Boolean(anchorEl);
  const id = open ? "more-popover" : undefined;

  const selectedCamera = useSelector(getSelectedCamera, shallowEqual);

  const { enqueueSnackbar } = useSnackbar();

  const handleCreateAlarm = () => {
    handleCreatingAlarm(scan?.createdOn);
    setAnchorEl(null);
  };

  const handleCreateDistanceSegments = () => {
    handleOpenDistanceMapper();
    setAnchorEl(null);
  };

  const handleImg = imgUrl => {
    return handleImgUrl(imgUrl, imgType);
  };

  const restoreDetections = scanId => {
    setLoading(true);

    CameraAPI.restoreDetections(selectedCamera.deviceId, scanId)
      .then(() => {
        setScanResults(results => {
          const newResults = cloneDeep(results);
          const index = newResults.findIndex(
            currentScan => currentScan.id === scanId
          );
          newResults[index].detections = newResults[index].detections.map(
            detection => {
              return { ...detection, tag: "valid" };
            }
          );
          return newResults;
        });
        enqueueSnackbar("DETECTIONS RESTORED!", {
          variant: "success"
        });
        setConfidence(getFloatingNumber(calculateOverallConf(scan)));
        if (selected) {
          setCurrentImageConfidence(
            getFloatingNumber(calculateOverallConf(scan), 0)
          );
        }
      })
      .catch(error => {
        console.log(error.message);
        enqueueSnackbar("Error, restore detections failed!", {
          variant: "error"
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRestoreDialogShow = show => {
    setRestoreDialogOpen(show);
  };

  const handleRestoreDetections = () => {
    restoreDetections(scan?.id);
    setRestoreDialogOpen(false);
  };

  const utcTime = scan?.createdOn?.substring(11, 19);
  const hasInvalidTag = scan?.detections?.find(
    detection => detection.tag === "invalid"
  )
    ? true
    : false;

  return (
    <div
      style={{
        display: "flex",
        margin: "0 0.5em",
        flexDirection: "column",
        width: selected ? "35%" : "24%"
      }}
    >
      <CustomDialog
        show={restoreDialogOpen}
        title={"Confirm Restore"}
        text={"Are you sure you want to restore all detection?"}
        handleClose={handleRestoreDialogShow.bind(null, false)}
        onCancel={handleRestoreDialogShow.bind(null, false)}
        onConfirm={handleRestoreDetections}
        confirmButtonText={"Restore"}
      />
      <div className={classes.mainWrapper}>
        <Typography style={{ fontSize: selected ? 18 : 14, flex: 1 }}>
          {utcTime}
        </Typography>
        <div style={{ marginRight: 4 }}>
          {event && <Typography> Event {event} </Typography>}
          <Typography>Confidence: {confidence}</Typography>
        </div>
        <IconButton onClick={e => setAnchorEl(e.currentTarget)} size="small">
          <MoreHorizIcon />
        </IconButton>
        <Popover
          id={id}
          open={open}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <List>
            <Tooltip title="Shortcut: 'Ctrl + Z'" placement="right" arrow>
              <ListItem
                button
                onClick={() => handleCreateAlarm()}
                disabled={
                  creatingAlarm || isAlarmEditing || selectedPoi === "All"
                }
              >
                <ListItemText>Create Alarm</ListItemText>
              </ListItem>
            </Tooltip>

            {isSemiAutomaticQuantOn && (
              <ListItem
                button
                onClick={() => handleCreateDistanceSegments()}
                disabled={selectedPoi === "All"}
              >
                <ListItemText>Distance Segments</ListItemText>
              </ListItem>
            )}

            <ListItem
              button
              onClick={handleRestoreDialogShow.bind(null, true)}
              disabled={loading || !hasInvalidTag || !showDetectionBoxes}
            >
              <ListItemText>Restore Detections</ListItemText>
            </ListItem>
          </List>
        </Popover>
      </div>
      <div
        style={{
          position: "relative"
        }}
      >
        <img
          ref={img}
          loading="lazy"
          onLoad={() => setImageLoaded(true)}
          key={scan?.id}
          src={handleImg(scan?.jpg)}
          width="313"
          height="410"
          alt={`gas ${imgType}`}
          onClick={() => handleFullscreenImage()}
          style={{
            outline: event !== null ? "1px solid cyan" : "1px solid grey",
            width: "100%",
            height: "auto"
          }}
        />
        {imgType === "colDen" &&
          scan?.detections?.length > 0 &&
          showDetectionBoxes &&
          imageLoaded &&
          scan?.detections?.map((detection, index) => {
            return (
              <DetectionBox
                key={detection.uuid}
                img={img}
                detection={detection}
                index={index}
                scan={scan}
                editable={selected}
                setScanResults={setScanResults}
                setConfidence={setConfidence}
                setCurrentImageConfidence={setCurrentImageConfidence}
              />
            );
          })}
        {scan?.leakRois?.length && imageLoaded
          ? scan?.leakRois?.map((leakSource, index) => {
              return (
                <LeakSourceBox
                  key={leakSource.id}
                  img={img}
                  leakSource={leakSource}
                  index={index}
                  scan={scan}
                  editable={selected}
                  setScanResults={setScanResults}
                  setConfidence={setConfidence}
                  setCurrentImageConfidence={setCurrentImageConfidence}
                />
              );
            })
          : ""}

        {scan?.leakOrigin?.length > 0 && (
          <LeakSourcePoint img={img} leakOrigin={scan?.leakOrigin} />
        )}
      </div>
      <Typography>ML Score: {scan?.mlScore?.score || "N/A"}</Typography>
    </div>
  );
};

Image.propTypes = {
  creatingAlarm: PropTypes.bool.isRequired,
  event: PropTypes.number,
  handleCreatingAlarm: PropTypes.func.isRequired,
  handleFullscreenImage: PropTypes.func.isRequired,
  handleOpenDistanceMapper: PropTypes.func.isRequired,
  imgType: PropTypes.string.isRequired,
  selectedPoi: PropTypes.string.isRequired,
  scan: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  setCurrentImageConfidence: PropTypes.func,
  setScanResults: PropTypes.func.isRequired
};

export default Image;
