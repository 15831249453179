import axios from "axios";
import { isNil } from "lodash";
/********** Special note!!! **********
 * This itterator is only here because the js protobuf adds the suffex "List" to array properties for some silly reason.
 * Untill there's a better solution, all cosmos results objects should be cleaned of that pesky word
 */
const iterate = (obj, stack, prevType) => {
  for (let property in obj) {
    if (Array.isArray(obj[property])) {
      //console.log(property , "(L="  + obj[property].length + ") is an array  with parent ", prevType, stack);
      iterate(obj[property], stack + property, "array");
      if (property.endsWith("List")) {
        obj[property.substring(0, property.length - 4)] = obj[property];
        delete obj[property];
      }
    } else {
      if (
        typeof obj[property] !== "string" &&
        typeof obj[property] !== "number"
      ) {
        if (prevType === "array") {
          //console.log(stack + "["  + property + "] is an object, item of " , prevType, stack);
          iterate(obj[property], stack + "[" + property + "].", "object");
        } else {
          //console.log(stack +    property  , "is " , typeof obj[property] , " with parent ", prevType, stack );
          iterate(obj[property], stack + property + ".", "object");
        }
      }
    }
  }
  return obj;
};

class CameraAPI {
  constructor() {
    this.baseTime = new Date();
    this.getFramesCallStack = [];

    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_KUVA_API_URL}/camera/v1`,
      timeout: 10000
    });

    this.scresLimit = 4000;
    this.axiosInstance.defaults.headers.common["Ocp-Apim-Subscription-Key"] =
      process.env.REACT_APP_REVIEWER_SUBSCRIPTION;
  }

  static instance = null;

  static Instance = () => this.instance ?? new CameraAPI();

  // Allow auth header to be initialized after login
  setAuthToken = token => {
    this.axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
  };

  setBaseOrgHeader = baseOrg => {
    this.axiosInstance.defaults.headers.common["X-Organization"] = baseOrg;
  };

  setBaseURI = url => {
    if (this.axiosInstance && process.env.REACT_APP_API_ORIGIN !== "local") {
      this.axiosInstance.defaults.baseURL = `${url}/v1`;
      this.axiosInstance.defaults.baseURL;
    }
  };

  get = (orgId, baseOrgHeader) => {
    const headers = baseOrgHeader ? { "X-Organization": baseOrgHeader } : {};

    return this.axiosInstance.get(`cameras/${orgId}`, {
      headers: headers,
      timeout: 30000
    });
  };

  getFrames = async (camera, startDate, endDate, max) => {
    console.log(
      "url",
      `${this.axiosInstance.defaults.baseURL}/scans/${camera}`
    );

    return this.axiosInstance.get(`/scans/${camera}`, {
      params: {
        max_frames: max ?? (startDate || endDate ? 2000 : 100),
        ...(startDate && { start: startDate }),
        ...(endDate && { end: endDate })
      }
      // ,
      // headers: {
      //   Authorization: `Bearer ${localStorage.getItem("accessToken")}`
      // }
    });
  };

  getFovImage = (camera, degrees) => {
    if (isNil(degrees)) return this.axiosInstance.get(`fov/blobrgb/${camera}`);

    return this.axiosInstance.get(`fov/blobrgb/${camera}/${degrees}`);
  };

  getTelemetry = (camera, startDate, endDate, maxResults) =>
    this.axiosInstance.get(`cameratelemetry/${camera}/telemetry/`, {
      params: {
        ...(startDate ? { start: startDate } : {}),
        ...(endDate ? { end: endDate } : {}),
        ...(maxResults ? { max: maxResults } : {})
      },
      timeout: 60000
    });

  getLongTasks = () => {
    return this.axiosInstance.get("longtasks");
  };

  getModuleTwinForCamera = (camera, module) =>
    this.axiosInstance.get(`cameramodule/${camera}/${module}`);

  panCamera = (camera, direction, steps) => {
    console.log(`calling ${this.axiosInstance.defaults.baseURL}/pan/${camera}`);
    return this.axiosInstance.get(`pan/${camera}`, {
      params: {
        ...(direction ? { direction: direction } : {}),
        ...(steps ? { steps: steps } : {})
      }
    });
  };

  toggleCameraScan = which => {
    console.log(`calling: ${this.axiosInstance.defaults.baseURL}/togglescan`);
    return this.axiosInstance.get("togglescan", {
      params: {
        // code: "5xH8lNy6SqgjKMt6LxlAot0McOzJI9mXcAd2vKXGtnnrQJnBOLhX/A==",
        deviceId: which
      }
    });
  };

  toggleIlluminator = which => {
    console.log(
      `calling: ${this.axiosInstance.defaults.baseURL}/toggleilluminate`
    );
    return this.axiosInstance.get("toggleilluminate", {
      params: {
        // code: "U57Xgf/mBskT71mKhLjpaCLE/DnahoTnfQzXSr/FAxvLGVj/uymBkQ==",
        deviceId: which
      }
    });
  };
  darkcal = which => {
    console.log(`calling: ${this.axiosInstance.defaults.baseURL}/v1/calibrate`);
    return this.axiosInstance.get("calibrate", {
      params: {
        // code: "oUV/XsmSCLQx5J88/DYTd3gx26hya4avDBIyWzM0o4siCUOow9uRZA==",
        deviceId: which
      }
    });
  };

  capture = which => {
    console.log(`calling: ${this.axiosInstance.defaults.baseURL}/capture`);
    return this.axiosInstance.get("capture", {
      params: {
        // code: "uSy1hwZ2/iWmsUiM/L99t3MSbxtaPwpkwtN6qGuQeRjwrQCJAe4EOQ==",
        deviceId: which
      }
    });
  };

  updateDeviceAttributes = (which, camUpdate) => {
    console.log(
      `calling: ${this.axiosInstance.defaults.baseURL}/camera/${which}`
    );
    return this.axiosInstance.put(`camera/${which}`, camUpdate);
  };

  /**
   * @function setScanningSchedule - updates the device twin tags with the new schedule
   *
   * @param {string} deviceId - the device to update the schedule for
   * @param {string} onUtc - the time to switch scanning on in the format HH:MM
   * @param {string} offUtc - the time to switch scanning off in the format HH:MM
   * @returns {Promise}
   */
  setScanningSchedule = (deviceId, onUtc, offUtc) => {
    console.log(
      `calling: ${this.axiosInstance.defaults.baseURL}/schedule/${deviceId}`
    );
    return this.axiosInstance.post(`schedule/${deviceId}/`, { onUtc, offUtc });
  };
  /**
   * @function setIlluminatingSchedule - updates the device twin tags with the new schedule
   *
   * @param {string} deviceId - the device to update the schedule for
   * @param {string} illuminatoronutc - the time to switch illuminating on in the format HH:MM
   * @param {string} illuminatoroffutc - the time to switch illuminating off in the format HH:MM
   * @returns {Promise}
   */
  setIlluminatingSchedule = (deviceId, illuminatoronutc, illuminatoroffutc) => {
    console.log(
      `calling: ${this.axiosInstance.defaults.baseURL}/schedule/${deviceId}`
    );
    return this.axiosInstance.post(`schedule/${deviceId}`, {
      illuminatoronutc,
      illuminatoroffutc
    });
  };

  // This will cancel the previous call before exicuting a new one.
  // TODO: make this into an array of calls that we can loop through and cancle each time.

  makeRequestCreator = () => {
    return (
      camera,
      blobContainer,
      startDate,
      endDate,
      showDetections,
      minThreshold,
      showNonDetections,
      dontInterrupt
    ) => {
      if (this.getFramesCallStack.length > 0 && !dontInterrupt) {
        this.getFramesCallStack.forEach(call => call.cancel());
      }

      this.getFramesCallStack.push(this.axiosInstance.CancelToken.source());

      console.log("%c get scan results", "color: #9954E3");
      console.log(
        `%c time from last call: ${
          new Date().getTime() - this.baseTime.getTime()
        }ms`,
        "color: #9954E3"
      );

      this.baseTime = new Date();

      const params = {
        ...(startDate ? { start: startDate } : {}),
        ...(endDate ? { end: endDate } : {}),
        ...{ detections: showDetections },
        ...(minThreshold === ""
          ? { minThreshold: 0 }
          : { minThreshold: minThreshold }),
        nonDetections: Boolean(showNonDetections),
        max_live_frames: 20
      };

      this.baseTime = new Date();

      return new Promise((resolve, reject) => {
        this.axiosInstance
          .get(`scres/${camera}/${blobContainer}`, {
            cancelToken:
              this.getFramesCallStack[this.getFramesCallStack.length - 1].token,
            params,
            timeout: 60 * 1000
          })
          .then(res => {
            if (res.data) {
              iterate(res.data, "", "Array");

              // console.log("\n\ncleaned Obj:\n\n", cleanedObject);
              console.log(
                `%c get scan results returned after ${
                  new Date().getTime() - this.baseTime.getTime()
                } ms`,
                "color: #9954E3"
              );
              resolve(res);
            }
          })
          .catch(err => {
            if (this.axiosInstance.isCancel(err)) {
              console.log("%c Request canceled", "color: #9954E3");
            } else {
              console.error("Request canceled", err);
              // handle error
              reject(err);
            }
          });
      });
    };
  };

  getScanResults = this.makeRequestCreator();

  getEvents = async ({
    deviceId,
    blobContainer,
    showNonDetections,
    startDate,
    endDate,
    eventConf,
    poi = null,
    algoHash,
    ...body
  }) => {
    const retval = await this.axiosInstance.get(
      `events/${deviceId}/${blobContainer}`,
      {
        params: {
          ...(startDate ? { start: startDate } : {}),
          ...(endDate ? { end: endDate } : {}),
          ...(eventConf ? { evtconfthresh: eventConf } : {}),
          nonDetections: Boolean(showNonDetections),
          max_live_frames: this.scresLimit,
          poidegrees: poi,
          algoHash: algoHash === "scanresult" ? null : algoHash,
          ...body
        },
        timeout: 60 * 1000
      }
    );
    // Cleaning object from the word "List"
    return iterate(retval, "", "object"); //TODO: return retval.data here instead of the http request object
  };

  updateTags = (camera, scanID, tags) => {
    console.log(
      `calling: ${this.axiosInstance.defaults.baseURL}/v1/tags/${camera}/${scanID}`
    );
    return this.axiosInstance.post(
      `tags/${camera}/${scanID}`,
      { tags },
      {
        timeout: 5000
      }
    );
  };

  hideDetection = (camera, scres, detidx) => {
    console.log(
      `CALLING: ${this.axiosInstance.defaults.baseURL}/detections/hide/${camera}/${scres}/${detidx}`
    );
    return this.axiosInstance.post(
      `detections/hide/${camera}/${scres}/${detidx}`,
      null,
      {
        timeout: 10000
      }
    );
  };

  restoreDetections = (camera, scres) => {
    console.log(
      `CALLING: ${this.axiosInstance.defaults.baseURL}/detections/restore/${camera}/${scres}`
    );
    return this.axiosInstance.post(
      `detections/restore/${camera}/${scres}`,
      null,
      {
        timeout: 10000
      }
    );
  };

  /**
   * @name getSessionEvents
   * @description This function sends a GET request to get sessions by camera & datetime range
   * @param {string} cameraId The id of the camera or device
   * @param {string} startTime The datetime (in UTC)- start of the date range filter
   * @param {string} endTime The time (in UTC)- end of the date range filter
   * @param {string} timeslotDuration The time slot duration - eg. [1hour or 24hours]
   * @returns Promise<session> a promise that returns sessions array object
   */
  getSessionEvents = (cameraId, start, end, timeslotDuration) => {
    return this.axiosInstance.get(
      `sessions/cameras/events/scanresult?start=${start}&end=${end}&cameras=${cameraId}&max_live_frames=${this.scresLimit}&timeslotDuration=${timeslotDuration}`,
      { timeout: 10 * 1000 }
    );
  };

  /**
   * @name getSessionEventsFromMultipleCameras
   * @description This function sends a GET request to get sessions by camera & datetime range
   * @param {string} cameras The list of camera IDs
   * @param {string} startTime The datetime (in UTC)- start of the date range filter
   * @param {string} endTime The time (in UTC)- end of the date range filter
   * @param {string} timeslotDuration The time slot duration - eg. [1hour or 24hours]
   * @returns Promise<session> a promise that returns sessions array object
   */
  getSessionEventsFromMultipleCameras = (
    baseOrgHeader,
    cameras,
    start,
    end,
    timeslotDuration,
    cancelToken
  ) => {
    const headers = baseOrgHeader ? { "X-Organization": baseOrgHeader } : {};

    return this.axiosInstance.get(
      `sessions/cameras/events/scanresult?start=${start}&end=${end}&cameras=${cameras}&max_live_frames=${this.scresLimit}&timeslotDuration=${timeslotDuration}`,
      { headers: headers, cancelToken: cancelToken, timeout: 60 * 1000 }
    );
  };

  getPoiDistanceSegments = (deviceId, organizationId) => {
    return this.axiosInstance.get(`cameras/${deviceId}/distance-segments`, {
      params: { organizationId }
    });
  };

  createPoiDistanceSegments = (deviceId, distanceSegments) => {
    return this.axiosInstance.post(
      `cameras/${deviceId}/distance-segments`,
      distanceSegments
    );
  };

  updatePoiDistanceSegments = (id, deviceId, distanceSegments) => {
    return this.axiosInstance.put(
      `cameras/${deviceId}/distance-segments/${id}`,
      distanceSegments
    );
  };
}
export default CameraAPI.Instance();
