import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    height: "100%"
  }
});

export { useStyles };
