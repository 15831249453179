import { shiftStatuses } from "~/constants/reviewerSessions";

export const selectIsLoading = state => state.shiftState?.isLoading;

export const getShifts = state => state.shiftState?.shifts;

export const selectIsStarted = state => state.shiftState?.isStarted;

export const selectShiftError = state => state.shiftState?.error;

export const getCurrentShift = state => state.shiftState?.current;

export const getOpenShifts = state =>
  state.shiftState?.shifts?.filter(
    shift => shift.status === shiftStatuses.STARTED
  );

export const getShiftByUser = userId => state =>
  state.shiftState?.shifts.filter(
    shift => !!shift.endTime && shift.userId === userId
  );
