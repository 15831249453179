import axios from "axios";

import { shiftStatuses } from "~/constants/reviewerSessions";
class SessionAPI {
  constructor() {
    this.baseTime = new Date();
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_KUVA_API_URL}/session/v1`,
      timeout: 10000
    });
    this.axiosInstance.defaults.headers.common["Ocp-Apim-Subscription-Key"] =
      process.env.REACT_APP_REVIEWER_SUBSCRIPTION;
    this.axiosInstance.defaults.headers.common["X-Organization"] = "kuva";
  }

  static instance = null;

  static Instance = () => this.instance ?? new SessionAPI();

  // Allow auth header to be initialized after login
  setAuthToken = token => {
    this.axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
  };

  setBaseURI = url => {
    if (this.axiosInstance && process.env.REACT_APP_API_ORIGIN !== "local") {
      this.axiosInstance.defaults.baseURL = `${url}/v1`;
      console.log(
        "SessionAPI url set to: ",
        this.axiosInstance.defaults.baseURL
      );
    }
  };

  /**
   * @name getShifts
   * @description This function sends a get request to retrive all the shifts for a given period
   *
   * @returns Promise<shifts> a promise that returns all the shifts for a given period
   */
  getShifts = (start, end) =>
    this.axiosInstance.get("shifts", { params: { start, end } });

  getShiftByStatus = status => {
    return this.axiosInstance.get(`shifts/status/${status}`);
  };

  createShift = () => {
    return this.axiosInstance.post(`shifts`, {
      startTime: new Date().toISOString()
    });
  };

  completeShift = (id, startTime, endTime = new Date().toISOString()) => {
    return this.axiosInstance.put(`shifts`, {
      id,
      startTime,
      endTime,
      status: shiftStatuses.COMPLETED
    });
  };

  /**
   * @name upsertSession
   * @description This function sends a post request to create/update a session
   * @param {object} session  The object to save
   * @returns Promise<session> a promise that returns a session object
   */
  upsertSession = session => {
    console.log(
      `%c calling: ${this.axiosInstance.defaults.baseURL}/session/upsert`,
      "color: #9954E3"
    );

    return this.axiosInstance.post("session/upsert", session);
  };

  /**
   * @name getSessions
   * @description This function sends a GET request to get sessions by camera & datetime range
   * @param {string} cameraId The id of the camera or device
   * @param {string} startTime The datetime (in UTC)- start of the date range filter
   * @param {string} endTime The time (in UTC)- end of the date range filter
   * @returns Promise<session> a promise that returns sessions array object
   */
  getSessions = (cameraId, start, end) => {
    console.log(
      `%c calling: ${this.axiosInstance.defaults.baseURL}/sessions/${cameraId}?start=${start}&end=${end}`,
      "color: #9954E3"
    );

    return this.axiosInstance.get(
      `sessions/${cameraId}?start=${start}&end=${end}`,
      {
        timeout: 10 * 1000
      }
    );
  };

  /**
   * @name deleteSession
   * @description This function sends a DELETE request to delete a session by cameraId and startTime
   * @param {string} cameraId The id of the camera or device | PartionKey
   * @param {string} sessionId The id of the session to be deleted
   * @param {string} startTime The datetime (in UTC)- start of the session duration | RowKey
   * @returns Promise<session> a promise that returns deleted session reponse object
   */
  deleteSession = (cameraId, sessionId, startTime) => {
    return this.axiosInstance.delete(
      `sessions/${cameraId}/${sessionId}/${startTime}`,
      {
        timeout: 10 * 1000
      }
    );
  };

  /**
   * @name getSessionsFromMultipleCameras
   * @description This function sends a GET request to get sessions by camera & datetime range
   * @param {string} cameras The list of camera IDs
   * @param {string} startTime The datetime (in UTC)- start of the date range filter
   * @param {string} endTime The time (in UTC)- end of the date range filter
   * @returns Promise<session> a promise that returns sessions array object
   */
  getSessionsFromMultipleCameras = (cameras, start, end, cancelToken) => {
    console.log(
      `%c calling: ${this.axiosInstance.defaults.baseURL}/cameras/sessions?start=${start}&end=${end}&cameras=${cameras}`,
      "color: #9954E3"
    );

    return this.axiosInstance.get(
      `cameras/sessions?start=${start}&end=${end}&cameras=${cameras}`,
      {
        cancelToken,
        timeout: 60 * 1000
      }
    );
  };

  /**
   * @name upsertCameraList
   * @description This function sends a post request to create/update a camera list
   * @param {object} cameraList  The object to save
   * @returns Promise<cameraList> a promise that returns a cameraList object
   */
  upsertCameraList = cameraList => {
    return this.axiosInstance.post("camera-lists", cameraList);
  };

  /**
   * @name getCameraListsByHub
   * @description This function sends a get request to retrive camera lists by ioT hub
   *
   * @returns Promise<cameraLists> a promise that returns all the camera list(s) by ioT hub
   */
  getCameraListsByHub = hub => {
    return this.axiosInstance.get(`camera-lists/${hub}`);
  };

  /**
   * @name getCameraLists
   * @description This function sends a get request to retrieve all camera lists.
   *
   * @returns {Promise<cameraLists>} A promise that returns all camera lists.
   */
  getCameraLists = () => {
    return this.axiosInstance.get("camera-lists");
  };

  /**
   * @name deleteCameraList
   * @description This function sends a DELETE request to delete a camera list by cameraListId
   * @param {string} cameraListId The id of the cameraList
   */
  deleteCameraList = cameraListId => {
    return this.axiosInstance.delete(`camera-lists/${cameraListId}`);
  };
}

export default SessionAPI.Instance();
