import {
  Button,
  Typography,
  Tooltip,
  Input,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup
} from "@mui/material";
import PropTypes from "prop-types";

import { useStyles } from "./styles";

export const EditAlarmDetails = ({
  humanActivity,
  handleHumanActivityChange,
  detectionReportID,
  handleDetectionReportIDChange,
  emissionSourceID,
  handleEmissionSourceIDChange,
  equipmentUnit,
  handleEquipmentUnit,
  handleEditingAlarmDate,
  editingAlarmDate,
  startDate,
  showWarning,
  endDate,
  saveChanges,
  handleReset,
  handleAlarmSave,
  disabledAlarmText
}) => {
  const { classes } = useStyles({ editingAlarmDate, showWarning, saveChanges });

  return (
    <div className={classes.root}>
      <div className={classes.humanActivity}>
        <Typography className={classes.label} variant="subtitle2">
          Human Activity
        </Typography>
        <FormControl variant="standard" component="fieldset">
          <RadioGroup
            row
            aria-label="humanactivity"
            name="humanactivity"
            value={humanActivity}
            onChange={handleHumanActivityChange}
          >
            <FormControlLabel
              className={classes.formControlLabelNo}
              value="no"
              control={<Radio size="small" />}
              label="No"
            />
            <FormControlLabel
              value="yes"
              control={<Radio size="small" />}
              label="Yes"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div className={classes.alarmMetadata}>
        <Typography className={classes.label} variant="subtitle2">
          Report ID
        </Typography>
        <Input
          disableUnderline={true}
          value={detectionReportID}
          onChange={handleDetectionReportIDChange}
          inputProps={{ min: 0, style: { textAlign: "center" } }}
          className={classes.metadataInput}
        />
      </div>
      <div className={classes.alarmMetadata}>
        <Typography className={classes.label} variant="subtitle2">
          Source ID
        </Typography>
        <Input
          disableUnderline={true}
          value={emissionSourceID}
          onChange={handleEmissionSourceIDChange}
          inputProps={{ min: 0, style: { textAlign: "center" } }}
          className={classes.metadataInput}
        />
      </div>
      <div className={classes.alarmMetadata}>
        <Typography className={classes.label} variant="subtitle2">
          Equipment Unit
        </Typography>
        <Input
          disableUnderline={true}
          value={equipmentUnit}
          onChange={handleEquipmentUnit}
          inputProps={{ min: 0, style: { textAlign: "center" } }}
          className={classes.metadataInput}
        />
      </div>
      <Button
        onClick={() => handleEditingAlarmDate("start")}
        className={classes.editStartTime}
      >
        <div>
          <Typography className={classes.label} variant="subtitle2">
            Start Time
          </Typography>
          {startDate && (
            <Typography className={classes.timeValue}>
              {startDate.toISOString().substring(11, 19)}
            </Typography>
          )}
        </div>
      </Button>
      <Button
        onClick={() => handleEditingAlarmDate("end")}
        className={classes.editEndTime}
      >
        <div>
          <Typography className={classes.label} variant="subtitle2">
            End Time
          </Typography>
          {endDate && (
            <Typography className={classes.timeValue}>
              {endDate.toISOString().substring(11, 19)}
            </Typography>
          )}
          {showWarning && (
            <div>
              <div className={classes.customTooltipTriangle} />
              <Typography className={classes.customTooltipRectangle}>
                End time must be later than start time.
              </Typography>
            </div>
          )}
        </div>
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        disabled={!saveChanges && editingAlarmDate === ""}
        onClick={handleReset}
        className={classes.cancel}
      >
        cancel
      </Button>
      {saveChanges ? (
        <Button
          variant="outlined"
          color="primary"
          onClick={handleAlarmSave}
          className={classes.saveEnabled}
        >
          save
        </Button>
      ) : (
        <Tooltip
          title={disabledAlarmText}
          placement="top-end"
          arrow
          PopperProps={{
            modifiers: {
              offset: {
                enabled: true,
                offset: "0, -28px"
              },
              flip: {
                enabled: false
              }
            }
          }}
        >
          <span
            style={{
              display: "flex",
              height: "100%",
              marginLeft: "auto"
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              disabled
              className={classes.saveDisabled}
              style={{ border: "1px solid grey" }} // need to override inherited style
            >
              save
            </Button>
          </span>
        </Tooltip>
      )}
    </div>
  );
};

EditAlarmDetails.propTypes = {
  humanActivity: PropTypes.string.isRequired,
  handleHumanActivityChange: PropTypes.func.isRequired,
  detectionReportID: PropTypes.string.isRequired,
  handleDetectionReportIDChange: PropTypes.func.isRequired,
  emissionSourceID: PropTypes.string.isRequired,
  handleEmissionSourceIDChange: PropTypes.func.isRequired,
  equipmentUnit: PropTypes.string.isRequired,
  handleEquipmentUnit: PropTypes.func.isRequired,
  handleEditingAlarmDate: PropTypes.func.isRequired,
  editingAlarmDate: PropTypes.string.isRequired,
  startDate: PropTypes.object.isRequired,
  showWarning: PropTypes.bool.isRequired,
  endDate: PropTypes.object.isRequired,
  saveChanges: PropTypes.bool.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleAlarmSave: PropTypes.func.isRequired,
  disabledAlarmText: PropTypes.string.isRequired
};
