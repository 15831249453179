import { createAsyncThunk } from "@reduxjs/toolkit";

import SessionAPI from "~/common/apis/SessionAPI";

const thunk = async cameraListId => {
  const response = await SessionAPI.deleteCameraList(cameraListId);
  return { cameraListId, response };
};

export const deleteCameraList = createAsyncThunk(
  "sessions/deleteCameraList",
  thunk
);
