import { Typography, Tooltip, Checkbox, FormControlLabel } from "@mui/material";
import PropTypes from "prop-types";

import { Button } from "@kuva/ui-components";

import { useStyles } from "./styles";

const AlarmActions = ({
  alarm,
  poi,
  editingAlarmDate,
  handleNotification,
  disableNotification,
  handleBackToAlarm,
  hoveredScan,
  setDeleteDialogOpen,
  handleDialogOpen,
  handleToggleAlarmStatus,
  isLoadingAlarm = false
}) => {
  const { classes } = useStyles({
    editingAlarmDate,
    hoveredScan
  });

  return (
    <div className={classes.root}>
      <Tooltip title={alarm?.id}>
        <Typography variant="h6" className={classes.smallMargin}>
          Alarm {alarm.id.substring(0, 7)}
        </Typography>
      </Tooltip>
      <Typography variant="subtitle2" className={classes.smallMargin}>
        POI: {poi ?? "All"}
      </Typography>
      <Tooltip
        className={classes.smallMargin}
        title={
          !editingAlarmDate
            ? "Start editing alarm date to toggle."
            : "Disables alarm duration threshold email notification"
        }
        placement="top"
      >
        <span>
          <FormControlLabel
            disabled={!editingAlarmDate}
            label={
              <Typography className={classes.disableEmailNotif}>
                Disable Email Notification
              </Typography>
            }
            control={
              <Checkbox
                checked={disableNotification}
                onChange={handleNotification}
                inputProps={{ "aria-label": "disable-notification" }}
              />
            }
          />
        </span>
      </Tooltip>
      <div className={classes.actionButtonWrapper}>
        <Button
          className={classes.actionButton}
          onClick={() => handleBackToAlarm("MODIFY_ALARM")}
          disabled={hoveredScan ? false : true}
          variant="outlined"
          color="green"
          size="small"
        >
          Requantify Alarm
        </Button>
        <Button
          className={classes.actionButton}
          onClick={() => handleBackToAlarm("GO_TO_EVENTS")}
          disabled={hoveredScan ? false : true}
          variant="outlined"
          color="green"
          size="small"
        >
          Go to Event
        </Button>
        <Button
          className={classes.actionButton}
          variant="outlined"
          color="green"
          size="small"
          onClick={handleDialogOpen}
        >
          Export
        </Button>
        <Button
          className={classes.actionButton}
          variant="outlined"
          color="red"
          size="small"
          onClick={handleToggleAlarmStatus}
          loading={isLoadingAlarm}
          disabled={isLoadingAlarm}
        >
          {alarm?.falseAlarm === true
            ? "Remove false alarm flag"
            : "Report as false alarm"}
        </Button>
        <Button
          className={classes.actionButton}
          variant="outlined"
          color="red"
          size="small"
          onClick={() => setDeleteDialogOpen(true)}
        >
          Delete Alarm Permanently
        </Button>
      </div>
    </div>
  );
};

AlarmActions.propTypes = {
  alarm: PropTypes.object.isRequired,
  poi: PropTypes.number.isRequired,
  editingAlarmDate: PropTypes.string.isRequired,
  handleNotification: PropTypes.func.isRequired,
  disableNotification: PropTypes.bool.isRequired,
  handleBackToAlarm: PropTypes.func.isRequired,
  hoveredScan: PropTypes.object.isRequired,
  setDeleteDialogOpen: PropTypes.func.isRequired,
  handleDialogOpen: PropTypes.func.isRequired,
  handleToggleAlarmStatus: PropTypes.func.isRequired,
  isLoadingAlarm: PropTypes.bool
};

export default AlarmActions;
