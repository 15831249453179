import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TextField
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { useEffect, useState } from "react";

import { useOrganization } from "~/hooks";

import data from "../../data.json";
import AddTagDialog from "../AddTagDialog";
import ConfirmDialog from "../ConfirmDialog";
import EditDialog from "../EditDialog";
import TagRow from "../TagRow";

const TagList = () => {
  let rawData = window.localStorage.getItem("tagsData");

  if (rawData === null) {
    rawData = JSON.stringify(data.tags);
    window.localStorage.setItem("TagID", 3);
    console.log("Reading default JSON tag data");
  } else {
    console.log("Using saved browser tag data");
  }

  const initialTagsData = JSON.parse(rawData);
  const [tagList, setTagList] = useState(initialTagsData);
  const [displayedTags, setDisplayedTags] = useState(initialTagsData);

  const { selectedOrg } = useOrganization();

  const addTag = (tagName, tagType) => {
    // ensure the next tag's ID will be greater than 15
    const nextTag =
      tagList[tagList.length - 1].id > 14
        ? tagList[tagList.length - 1].id + 1
        : 15;
    const currTime = new Date().toISOString();

    const newTag = {
      id: nextTag,
      orgId: selectedOrg ? selectedOrg.orgId : "N/A",
      text: tagName,
      type: tagType,
      createdOn: currTime,
      updatedOn: currTime
    };

    // Adds a new tag object to the local state list of tags
    let newTagList = [...tagList, newTag];
    setTagList(newTagList);
  };

  // TODO (lily): update this functionality once azure works
  const removeTag = id => {
    const updatedTagList = tagList.filter(tag => tag.id !== id);
    setTagList(updatedTagList);
  };

  const editTag = (id, newText, newType, newOrgId) => {
    const updatedTagList = tagList.map(tag => {
      if (tag.id === id) {
        return {
          ...tag,
          text: newText,
          type: newType,
          updatedOn: new Date().toISOString(),
          orgId: newOrgId
        };
      }
      return tag;
    });

    setTagList(updatedTagList);
  };

  const revertTag = id => {
    const originalTag = initialTagsData.find(tag => tag.id === id);

    const updatedTagList = tagList.map(tag => {
      if (tag.id === id) {
        return {
          ...tag,
          text: originalTag.text,
          type: originalTag.type,
          updatedOn: new Date().toISOString(),
          orgId: originalTag.orgId
        };
      }
      return tag;
    });
    setTagList(updatedTagList);
  };

  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openRevertDialog, setOpenRevertDialog] = useState(false);
  const [selectedTagId, setSelectedTagId] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchContent, setSearchContent] = useState("");

  const handleRemoveClick = tagId => {
    setSelectedTagId(tagId);
    setOpenRemoveDialog(true);
  };

  const handleClose = () => {
    setOpenRemoveDialog(false);
  };

  const handleEditClick = tagId => {
    setSelectedTagId(tagId);
    setOpenEditDialog(true);
  };

  const handleEditClose = () => {
    setOpenEditDialog(false);
  };

  const handleRevertClick = tagId => {
    setSelectedTagId(tagId);
    setOpenRevertDialog(true);
  };

  const handleRevertClose = () => {
    setOpenRevertDialog(false);
  };

  const handleSearchChange = event => {
    setSearchInput(event.target.value);
    setSearchContent(event.target.value.trim().toLowerCase());
  };

  const tableHeaders = {
    id: "Tag ID",
    orgId: "Organization ID",
    text: "Text",
    type: "Tag Type",
    createdOn: "Created On",
    updatedOn: "Updated On"
  };

  // organize tagList by ID
  useEffect(() => {
    const sortedTags = tagList.sort((a, b) => a.id - b.id);
    setTagList(sortedTags);
  }, [tagList]);

  // update displayed tags when search content changes
  useEffect(() => {
    const searchLowercased = searchContent.toLowerCase();
    const options = { month: "long", day: "numeric", year: "numeric" };

    const tagsLocalTime = tagList.map(tag => {
      return {
        ...tag,
        createdOn: new Date(tag.createdOn).toLocaleDateString("en-US", options),
        updatedOn: new Date(tag.updatedOn).toLocaleDateString("en-US", options)
      };
    });

    const filteredTags = tagsLocalTime.filter(tag => {
      const values = Object.values(tag);
      return values.some(value => {
        if (typeof value === "string") {
          const valueLower = value.toLowerCase();
          return valueLower.includes(searchLowercased);
        } else {
          // it is an int, convert it to a string and compare
          return value.toString().includes(searchContent);
        }
      });
    });

    setDisplayedTags(filteredTags);
  }, [tagList, searchContent]);

  return (
    <>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}
      >
        <TextField
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              <SearchIcon style={{ marginRight: "10px" }} />
              Search Tags
            </div>
          }
          value={searchInput}
          variant="outlined"
          color="primary"
          role="text field"
          InputProps={{ style: { color: "white", fontSize: "18px" } }}
          onChange={handleSearchChange}
          InputLabelProps={{ style: { color: "gray", fontSize: "18px" } }}
          margin="dense"
          fullWidth
        />
      </div>
      <TableContainer
        style={{ backgroundColor: "rgb(48, 50, 52)" }}
        component={Paper}
      >
        <Table style={{ tableLayout: "fixed" }} aria-label="simple table">
          <TableHead>
            <TagRow
              tag={tableHeaders}
              handleRemoveClick={null}
              handleEditClick={null}
              handleRevertClick={null}
            />
          </TableHead>
          <TableBody>
            {displayedTags.map(tag => (
              <TagRow
                key={tag.id}
                tag={tag}
                handleRemoveClick={handleRemoveClick}
                handleEditClick={handleEditClick}
                handleRevertClick={handleRevertClick}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddTagDialog addTag={addTag} />
      <ConfirmDialog
        tag={tagList.find(tag => tag.id === selectedTagId)}
        handleClose={handleClose}
        tagFunction={removeTag}
        open={openRemoveDialog}
        confirmType="Remove"
      />
      <EditDialog
        role="dialog"
        tag={tagList.find(tag => tag.id === selectedTagId)}
        handleEditClose={handleEditClose}
        editTag={editTag}
        open={openEditDialog}
      />
      <ConfirmDialog
        tag={tagList.find(tag => tag.id === selectedTagId)}
        handleClose={handleRevertClose}
        tagFunction={revertTag}
        open={openRevertDialog}
        confirmType="Revert"
      />
    </>
  );
};

export default TagList;
