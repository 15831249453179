import { format, isValid } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

export const toUTC = date => {
  const parsedDate = new Date(date);
  const isValidDate = isValid(parsedDate);
  if (!isValidDate) {
    return undefined;
  }
  const utcDate = utcToZonedTime(parsedDate, "Etc/UTC");
  return format(utcDate, "MMMM dd, yyyy");
};
