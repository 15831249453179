export const getNextOrPreviousAvailableFrame = (
  dates,
  frameDateTimeFromLastSelectedPoi
) => {
  if (!frameDateTimeFromLastSelectedPoi) {
    // Return the earliest datetime on the list
    return dates.length - 1;
  } else if (frameDateTimeFromLastSelectedPoi instanceof Date) {
    // Returns the number of milliseconds
    frameDateTimeFromLastSelectedPoi =
      frameDateTimeFromLastSelectedPoi.getTime();
  }

  let nearestPossibility = Infinity;
  let nearestFrame = -1;

  dates.forEach(function (date, index) {
    if (date instanceof Date) {
      date = date.getTime();
    }
    let distance = Math.abs(date - frameDateTimeFromLastSelectedPoi);
    if (distance < nearestPossibility) {
      nearestPossibility = distance;
      nearestFrame = index;
    }
  });

  return nearestFrame;
};
// usage:
// const index = getNextOrPreviousAvailableFrame(dates, timestamp)

export const getNearestTimeFrame = (scres, selectedPoi) => {
  const frameDatetimes = scres?.map(item => new Date(item?.createdOn));
  const lastSelectedFrame = sessionStorage.getItem("lastSelectedFrame");
  let lastSelectedScres = {};
  if (lastSelectedFrame) {
    lastSelectedScres = JSON.parse(lastSelectedFrame);
  }

  if (selectedPoi === "All") {
    const selectedFrameIndex = scres.findIndex(
      scres => scres.id === lastSelectedScres?.id
    );
    // Return the first frame if the last selected frame ID does not exist,
    // otherwise return the last selected frame index
    if (selectedFrameIndex < 0) {
      return scres.length - 1;
    } else {
      return selectedFrameIndex;
    }
  } else {
    const lastFrameDatetime = new Date(lastSelectedScres?.createdOn);
    const nearestFrameIndex = getNextOrPreviousAvailableFrame(
      frameDatetimes,
      lastFrameDatetime
    );

    return nearestFrameIndex;
  }
};

export const filterScansByDateRanges = (scans, seriesOfDateRange) => {
  const scansGroupByTimeslots = {};

  scans.forEach(scan => {
    const scanDate = new Date(scan.createdOn);
    const matchedRange = seriesOfDateRange.find(({ start, end }) => {
      const startDate = new Date(start);
      const endDate = new Date(end);
      return scanDate >= startDate && scanDate <= endDate;
    });

    if (matchedRange) {
      const { start, end } = matchedRange;
      const startTime = new Date(start).toISOString().substr(11, 5);
      const endTime = new Date(end).toISOString().substr(11, 5);
      const rangeLabel = `${startTime}-${endTime}`;

      if (!scansGroupByTimeslots[rangeLabel]) {
        scansGroupByTimeslots[rangeLabel] = [];
      }

      scansGroupByTimeslots[rangeLabel].push(scan);
    }
  });

  // Return the filtered scans
  const filteredScans = Object.values(scansGroupByTimeslots).flat();
  return { filteredScans, scansGroupByTimeslots };
};

export const checkIfTimeslotsHaveScres = timeslots => {
  for (let i = 0; i < timeslots.length; i++) {
    if (timeslots[i].scres !== 0) {
      return true;
    }
  }
  return false;
};
