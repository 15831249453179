import { makeStyles } from "@kuva/ui-components";

export const useStyles = makeStyles()(theme => ({
  controlMenuContainer: {
    position: "relative"
  },
  buttonsWrapper: {
    position: "absolute",
    top: theme.spacing(1),
    left: theme.spacing(2),
    borderRadius: 12,
    backgroundColor: "#000000"
  },
  timeWrapper: {
    position: "absolute",
    left: "25px",
    top: "250px",
    zIndex: 4,
    padding: "8px",
    backgroundColor: "#000000",
    "& .MuiTypography-root": {
      fontSize: "14px"
    },
    "& span": {
      display: "block"
    }
  },
  closeIcon: {
    fontSize: "4rem",
    position: "absolute",
    right: "20px",
    top: "20px",
    cursor: "pointer",
    color: "#ffffff"
  },
  arrowButtonsWrapper: {
    margin: "auto",
    width: "98vw",
    left: 0,
    right: 0,
    top: "50%",
    display: "flex",
    justifyContent: "space-between"
  }
}));
