import compose from "lodash/fp/compose";
import sortBy from "lodash/fp/sortBy";

export const getCameras = state => state.cameraState.cameras;

export const getOrgCameras = orgIds => state => {
  const filterByOrg = cameras =>
    cameras.filter(
      camera => orgIds?.includes("all") || orgIds?.includes(camera.orgID)
    );

  const select = compose(
    filterByOrg,
    sortBy(({ name }) => name.toLowerCase())
  );

  return select(state.cameraState.cameras);
};

export const getSelectedCamera = state =>
  state.cameraState.cameras.find(
    c => c.deviceId === state.cameraState.selectedDeviceId
  );

export const getReportedAttributesForCamera = deviceId => state =>
  state.cameraState.reported.find(camera => camera.deviceId === deviceId);

export const getReportedAttributes = () => state => state.cameraState.reported;
