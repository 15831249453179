import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import {
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import { GlobalStyle } from "GlobalStyle";
import { noop } from "lodash";
import PropTypes from "prop-types";

import DistanceTable from "./DistanceTable";
import { CanvasControls } from "./styled-components";
import { useCommonStyles, useControlPannelContentStyles } from "./styles";

export const ControlPannelContent = ({
  formikProps,
  handleZoomIn = noop,
  handleZoomOut = noop,
  poiKeys,
  handleSegmentingPoiChange = noop,
  selectedPoi
}) => {
  const { classes: globalStyles } = GlobalStyle();
  const { classes: commonStyles } = useCommonStyles();
  const { classes } = useControlPannelContentStyles();
  const handleChange = event => {
    handleSegmentingPoiChange(event.target.value);
    formikProps.resetForm({ segments: [] });
  };

  const filteredPoiKeys = poiKeys.filter(poi => poi !== "All");

  return (
    <CanvasControls>
      <div>
        <Typography className={globalStyles.font14}>Scene Canvas</Typography>
        <div>
          <span>Zoom:</span>
          <IconButton onClick={handleZoomOut} size="large">
            <ZoomOutIcon fontSize="small" />
          </IconButton>
          <IconButton onClick={handleZoomIn} size="large">
            <ZoomInIcon fontSize="small" />
          </IconButton>
          <span className={classes.poiLabel}>POI:</span>
          <FormControl variant="outlined" className={commonStyles.formControl}>
            <Select
              variant="standard"
              label="POI"
              labelwidth={0}
              value={selectedPoi}
              onChange={handleChange}
            >
              {filteredPoiKeys.map(poi => (
                <MenuItem key={poi} value={poi}>
                  {poi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <Divider />
      <DistanceTable formikProps={formikProps} />
    </CanvasControls>
  );
};

ControlPannelContent.propTypes = {
  formikProps: PropTypes.shape({
    dirty: PropTypes.bool,
    errors: PropTypes.object,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    isValid: PropTypes.bool,
    resetForm: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    setValues: PropTypes.func,
    touched: PropTypes.object,
    values: PropTypes.object
  }),
  handleZoomIn: PropTypes.func,
  handleZoomOut: PropTypes.func,
  loading: PropTypes.bool,
  poiKeys: PropTypes.array,
  selectedPoi: PropTypes.string
};
