import { Box, Typography, Switch } from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { selectAppSettings } from "~/common/selectors/AppSettingsSelector";

export const UnitsSettings = () => {
  const dispatch = useDispatch();
  const { isImperialUnits } = useSelector(selectAppSettings, shallowEqual);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        margin: "5px 0 5px 0"
      }}
    >
      <Typography sx={{ fontSize: "14px" }}>Use Imperial units</Typography>
      <Switch
        checked={isImperialUnits}
        onChange={() =>
          dispatch({
            type: "SET_IS_IMPERIAL_UNITS",
            isImperialUnits: !isImperialUnits
          })
        }
        color="success"
        name="isImperialUnits"
      />
    </Box>
  );
};
