import { makeStyles } from "@kuva/ui-components";

const useStyles = makeStyles()(
  (theme, { editingAlarmDate, showWarning, saveChanges }) => ({
    root: {
      flex: "0 0 4em",
      width: "95%",
      marginLeft: "2.5%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center"
    },
    label: {
      textTransform: "uppercase"
    },
    formControlLabelNo: {
      marginBottom: theme.spacing(-2),
      marginTop: theme.spacing(-0.1)
    },
    humanActivity: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "20px",
      maxWidth: "90px"
    },
    alarmMetadata: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "15%"
    },
    metadataInput: {
      width: "50%",
      fontSize: "20px",
      color: "white",
      margin: "auto"
    },
    editStartTime: {
      height: "100%",
      width: "20%",
      backgroundColor: editingAlarmDate === "start" ? "#222222" : "transparent",
      borderRadius: "15px" // for consistencies when hovering between start and end
    },
    editEndTime: {
      height: "100%",
      width: "20%",
      backgroundColor: editingAlarmDate === "end" ? "#222222" : "transparent",
      outline: showWarning ? "2px solid red" : "none",
      outlineOffset: "-10px",
      borderRadius: "15px"
    },
    timeValue: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      fontSize: "20px",
      color: "white"
    },
    customTooltipTriangle: {
      position: "absolute",
      top: "-10px",
      left: "50%",
      transform: "translateX(-50%)",
      width: 0,
      height: 0,
      borderLeft: "5px solid transparent",
      borderRight: "5px solid transparent",
      borderBottom: "5px solid gray",
      marginTop: "84px",
      zIndex: 3
    },
    customTooltipRectangle: {
      backgroundColor: "gray",
      color: "black",
      position: "absolute",
      marginTop: "15px",
      marginLeft: "-50px",
      marginRight: "10px",
      padding: "5px",
      borderRadius: "5px",
      zIndex: 2
    },
    cancel: {
      height: "50%",
      marginLeft: "auto",
      marginRight: "12px",
      opacity: saveChanges || editingAlarmDate !== "" ? "1" : "0.5",
      border: "1px solid grey"
    },
    saveEnabled: {
      height: "50%",
      marginLeft: "auto",
      border: "1px solid grey"
    },
    saveDisabled: {
      height: "50%",
      margin: "auto",
      opacity: "0.5"
    }
  })
);

export { useStyles };
