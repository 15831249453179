import { initialState } from "./initialState";

function updateObjectInArray(array, toUpdate) {
  return array.map(item => {
    if (item.deviceId !== toUpdate.deviceId) {
      // This isn't the item we care about - keep it as-is
      return item;
    }
    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...toUpdate
    };
  });
}

export default function camerasReducer(
  state = initialState.cameraState,
  action
) {
  let deviceId;
  switch (action.type) {
    case "FETCH_CAMERAS_SUCCESS":
      return { ...state, cameras: action.cameras };
    case "UPDATE_CAMERAS_SUCCESS":
      // get the data from the message
      // find the camera instance to update
      if (state.cameras?.length) {
        const toUpdate = state.cameras.find(
          cam => cam.deviceId === action.deviceId
        );
        if (toUpdate != null) {
          console.log(
            "saving new camera heartbeat for device: ",
            action.deviceId
          );
          console.log("+++++++++++++++++++++++++++++++++++++++++\n");
          // update the camera instance
          const attributes = toUpdate.updateCameraAttributes(action.attributes);
          const updated = { ...toUpdate, ...attributes };
          //send the updated camera instance back to redux to handle
          return {
            ...state,
            cameras: updateObjectInArray(state.cameras, updated)
          };
        }
      }
      return state;
    case "SET_SELECTED_CAMERA":
      return { ...state, selectedDeviceId: action.deviceId };
    case "FETCH_REPORTED_SUCCESS":
      console.log("got reported attributes: ", action.reported);
      return { ...state, reported: action.reported };
    case "UPSERT_REPORTED_SUCCESS": {
      // case "SET_REPORTED_CAMERA_ATTR":
      // get the data from the message
      deviceId = action.reported.deviceId;
      console.log("saving new camera attributes for device: ", deviceId);
      console.log("+++++++++++++++++++++++++++++++++++++++++\n");
      // find the camera instance to update

      const toUpdate = state.reported.find(cam => cam.deviceId === deviceId);

      const updated = toUpdate
        ? { ...toUpdate, ...action.reported }
        : action.reported;

      //send the updated camera instance back to redux to handle
      return {
        ...state,
        reported: [
          ...state.reported.filter(
            prev => prev.deviceId !== action.reported.deviceId
          ),
          updated
        ]
      };
    }

    // // update the camera instance
    // if (toUpdate) {
    //   // update the camera instance

    //   const attributes = TwinChangeMessage;

    //   toUpdate.updateReportedAttributes(action.attributes);
    //   const updated = { ...toUpdate, ...attributes };
    //   //send the updated camera instance back to redux to handle
    //   return { ...state, cameras: sortById(updateObjectInArray(state.cameras, updated)) };
    // }
    // return state;
    case "SET_LAST_CAMERA_HEARTBEAT": {
      // get the data from the message
      deviceId = action.attributes.deviceId;
      // find the camera instance to update
      const cameras = state.cameras;
      if (cameras?.length) {
        const toUpdate = cameras.find(cam => cam.deviceId === deviceId);
        if (toUpdate != null) {
          console.log("saving new camera heartbeat for device: ", deviceId);
          console.log("+++++++++++++++++++++++++++++++++++++++++\n");
          // update the camera instance
          const attributes = toUpdate.updateHeartbeat(action.attributes);
          const updated = { ...toUpdate, ...attributes };
          //send the updated camera instance back to redux to handle
          return {
            ...state,
            cameras: updateObjectInArray(state.cameras, updated)
          };
        }
      }

      return state;
    }

    default:
      return state;
  }
}
