import { createAsyncThunk } from "@reduxjs/toolkit";

import CameraAPI from "~/common/apis/CameraAPI";

const thunk = async payload => {
  const { deviceId, organizationId, eventDate } = payload;

  const response = await CameraAPI.getPoiDistanceSegments(
    deviceId,
    organizationId
  );
  return response;
};

export const getPoiDistanceSegments = createAsyncThunk(
  "camera/getPoiDistanceSegments",
  thunk
);
