import { Typography, Divider } from "@mui/material";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

import { flags } from "~/constants/feature-flags";

import TagList from "./components/TagList";

const TagManagement = () => {
  const isTagsManagementReady = useFeatureIsOn(flags.TAGS_MANAGEMENT);
  if (isTagsManagementReady) {
    return (
      <>
        {/* TODO (lily): decide whether to make the default/organization tables separate, or
        just have an option to filter using the search bar */}
        <Typography style={{ marginTop: "100px", fontSize: "24px" }}>
          Tag Management
        </Typography>
        <Divider style={{ marginTop: "10px" }}></Divider>
        <br />
        <TagList />
      </>
    );
  } else {
    return <></>;
  }
};

export default TagManagement;
