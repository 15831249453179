import { useState } from "react";
import { noop } from "lodash";
import PropTypes from "prop-types";

import { Anchor } from "./Anchor";
import { originAnchorSettings } from "./graphic-settings";

const { defaultFillColor, invalidFillColor, validFillColor } =
  originAnchorSettings;

export const SegmentOriginAnchor = ({
  validateMouseEvents = noop,
  point,
  onValidClick = noop,
  onValidMouseOver = noop
}) => {
  const isValid = validateMouseEvents();
  const [fill, setFill] = useState(defaultFillColor);

  return (
    <Anchor
      point={point}
      fill={fill}
      onClick={() => {
        if (isValid) {
          onValidClick();
        }
      }}
      onMouseOver={() => {
        if (isValid) {
          setFill(validFillColor);
          onValidMouseOver();
        } else {
          setFill(invalidFillColor);
        }
      }}
      onMouseOut={() => {
        setFill(defaultFillColor);
      }}
    />
  );
};

SegmentOriginAnchor.propTypes = {
  validateMouseEvents: PropTypes.func.isRequired,
  point: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  }),
  onValidClick: PropTypes.func.isRequired,
  onValidMouseOver: PropTypes.func.isRequired
};
