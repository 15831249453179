import OrganizationAPI from "~/common/apis/OrganizationAPI";
import { request } from "~/request";

export const getUsersList = async (
  orgId,
  page,
  pageSize,
  fields,
  roles,
  cancelToken
) => {
  try {
    const response = await OrganizationAPI.listUsers(
      orgId,
      page,
      pageSize,
      fields,
      roles,
      cancelToken
    );
    if (response.data.length) return response.data;
  } catch (error) {
    console.error("Fetch shifts error:", error);
  }
};

export const getBaseOrganization = async payload => {
  try {
    const httpResult = await request(
      `${process.env.REACT_APP_KUVA_API_URL}/organization/v1/baseorg/${payload}`,
      { withCredentials: false }
    );

    localStorage.setItem("kcc/base_org", httpResult.data);

    return httpResult.data;
  } catch (error) {
    console.error(error);
  }
};
