import { Box, Typography } from "@mui/material";

const ImageTimestamp = ({ createdOn }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        margin: "5px 0 5px 0"
      }}
    >
      <Typography variant="body2" sx={{ color: "#2EC2CC" }}>
        Time:
      </Typography>
      <Typography variant="body2" sx={{ color: "#2EC2CC" }}>
        {createdOn?.split("T")[1] ?? "N/A"}
      </Typography>
    </Box>
  );
};

export default ImageTimestamp;
