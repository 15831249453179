import CloseIcon from "@mui/icons-material/Close";
import { Badge } from "@mui/material";
import cloneDeep from "lodash/cloneDeep";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import { makeStyles } from "@kuva/ui-components";
import { getFloatingNumber } from "@kuva/ui-helpers/src/utils";

import CameraAPI from "../../../apis/CameraAPI";
import { getSelectedCamera } from "../../../selectors/CameraSelector";
import { calculateOverallConf } from "../../../utils/dataUtils";
import DeleteDialog from "../DeleteDialog";

const useStyles = makeStyles()({
  cyanRectangle: {
    border: "1px solid cyan",
    position: "absolute"
  },
  redRectangle: {
    border: "1px solid red",
    position: "absolute"
  }
});

const DetectionBox = ({
  detection,
  img,
  fullScreenMode,
  index,
  scan,
  editable,
  setScanResults,
  setConfidence,
  setCurrentImageConfidence
}) => {
  const { classes } = useStyles();
  const [coords, setCoords] = useState({});
  const [hover, setHover] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [validTag, setValidTag] = useState(false);
  const [loading, setLoading] = useState(false);

  const selectedCamera = useSelector(getSelectedCamera, shallowEqual);

  const { enqueueSnackbar } = useSnackbar();

  const hideDetection = async (scanId, detectionIndex) => {
    setLoading(true);

    CameraAPI.hideDetection(selectedCamera?.deviceId, scanId, detectionIndex)
      .then(() => {
        setValidTag(false);
        setScanResults(results => {
          const newResults = cloneDeep(results);
          const foundIndex = newResults.findIndex(
            currentScan => currentScan.id === scan.id
          );
          newResults[foundIndex].detections[detectionIndex].tag = "invalid";
          return newResults;
        });
        if (setConfidence) {
          setConfidence(getFloatingNumber(calculateOverallConf(scan)));
        }
        if (editable) {
          setCurrentImageConfidence(
            getFloatingNumber(calculateOverallConf(scan), 0)
          );
        }
        enqueueSnackbar("DETECTION DELETED!", {
          variant: "success"
        });
      })
      .catch(error => {
        console.log(error);
        enqueueSnackbar(`Delete detection failed! ${error}`, {
          variant: "error",
          autoHideDuration: 10000
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  /** translates from an XY coordinate system to css attributes we can work with
   * the original object has an 8 integer array representing 4 pairs of X/Y pixel coordinates
   * We translate the four corners to top, left, height and width integers and multiply by the aspect ratio (clientDimensions/naturalDimentions).
   */
  const handleCoords = () => {
    const imgWidth = img?.current?.naturalWidth || 313;
    const imgHeight = img?.current?.naturalHeight || 410;
    const imgRatio = imgWidth / imgHeight;
    const imgScaleX = scan?.imgscaleX || 1;
    if (fullScreenMode) {
      const screenRatio = window.innerWidth / window.innerHeight;
      const predictedWidth =
        screenRatio > imgRatio
          ? parseInt(window.innerHeight * imgRatio)
          : window.innerWidth;
      const predictedHeight =
        screenRatio < imgRatio
          ? parseInt(window.innerWidth / imgRatio)
          : window.innerHeight;
      const xOffset = (window.innerWidth - predictedWidth) / 2;
      const yOffset = (window.innerHeight - predictedHeight) / 2;
      setCoords({
        left:
          (detection.roicoords[0] * (predictedWidth * imgScaleX)) / imgWidth +
          xOffset,
        width:
          (detection.roicoords[2] - detection.roicoords[0]) *
          ((predictedWidth * imgScaleX) / imgWidth),
        top: (detection.roicoords[1] * predictedHeight) / imgHeight + yOffset,
        height:
          (detection.roicoords[5] - detection.roicoords[1]) *
          (predictedHeight / imgHeight)
      });
    } else {
      let width = img?.current?.width * imgScaleX;
      let height = img?.current?.height;
      setCoords({
        left: detection.roicoords[0] * (width / imgWidth),
        width:
          (detection.roicoords[2] - detection.roicoords[0]) *
          (width / imgWidth),
        top: detection.roicoords[1] * (height / imgHeight),
        height:
          (detection.roicoords[5] - detection.roicoords[1]) *
          (height / imgHeight)
      });
    }
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteDetection = async () => {
    hideDetection(scan.id, index);
    setDeleteDialogOpen(false);
  };

  const handleDeleteDialogOpen = () => {
    if (editable && sessionStorage.getItem("deleteDetection") !== "hide") {
      setDeleteDialogOpen(true);
    } else if (editable) {
      handleDeleteDetection();
    }
  };

  useEffect(() => {
    setValidTag(detection.tag !== "invalid" ? true : false);
  }, [detection.tag]);

  useEffect(() => {
    handleCoords();

    window.addEventListener("resize", handleCoords);

    return () => {
      window.removeEventListener("resize", handleCoords);
    };
  }, [window.innerWidth]);

  if (!validTag) {
    return (
      <div data-testid="invalid-detection" style={{ display: "none" }}></div>
    );
  }
  return (
    <div data-testid="valid-detection">
      <DeleteDialog
        show={deleteDialogOpen}
        tile={"Confirm Delete"}
        text={"Are you sure you want to delete this detection?"}
        handleClose={handleDeleteDialogClose}
        onCancel={handleDeleteDialogClose}
        onDelete={handleDeleteDetection}
        hidePromptAction={"deleteDetection"}
      />
      <Badge
        data-testid="detection-badge"
        className={classes.redRectangle}
        style={{
          ...coords,
          display: "flex",
          cursor: editable && "pointer"
        }}
        badgeContent={
          editable &&
          hover &&
          !loading && (
            <CloseIcon
              fontSize="medium"
              style={{
                fill: "white"
              }}
            />
          )
        }
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={handleDeleteDialogOpen}
      />
    </div>
  );
};

DetectionBox.propTypes = {
  detection: PropTypes.object.isRequired,
  img: PropTypes.PropTypes.oneOfType([
    PropTypes.shape({
      current: PropTypes.instanceOf(HTMLInputElement)
    }),
    PropTypes.oneOf([null])
  ]).isRequired,
  fullScreenMode: PropTypes.bool,
  index: PropTypes.number.isRequired,
  scan: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  setScanResults: PropTypes.func,
  setConfidence: PropTypes.func,
  setCurrentImageConfidence: PropTypes.func
};

export default DetectionBox;
