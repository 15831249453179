import { IconButton, Tooltip } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import FastForwardIcon from "@mui/icons-material/FastForward";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import PropTypes from "prop-types";
import { GlobalStyle } from "GlobalStyle";

export const ImageNavPanel = ({
  isDisabled,
  scanIndex,
  handlePrev,
  handleNext,
  handleLeftArrow,
  handleRightArrow,
  children
}) => {
  const { classes: globalStyle } = GlobalStyle();
  const isMac = navigator.appVersion.indexOf("Mac") !== -1;

  return (
    <div className={globalStyle.imageNavWrapper}>
      <Tooltip
        title={isMac ? "Ctrl + ⌘ + Arrow Left Key" : "Ctrl + Arrow Left Key"}
        placement="bottom"
        arrow
      >
        <IconButton
          disabled={isDisabled}
          onClick={handlePrev}
          aria-label="rewind"
          size="large"
        >
          <FastRewindIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Arrow Left Key" placement="bottom" arrow>
        <IconButton
          disabled={isDisabled}
          onClick={handleLeftArrow}
          aria-label="left"
          size="large"
        >
          <ArrowLeftIcon />
        </IconButton>
      </Tooltip>

      {children}

      <Tooltip title="Arrow Right Key" placement="bottom" arrow>
        <IconButton
          disabled={scanIndex === 0}
          onClick={handleRightArrow}
          aria-label="right"
          size="large"
        >
          <ArrowRightIcon />
        </IconButton>
      </Tooltip>

      <Tooltip
        title={isMac ? "Ctrl + ⌘ + Arrow Right Key" : "Ctrl + Arrow Right Key"}
        placement="bottom"
        arrow
      >
        <IconButton
          disabled={scanIndex === 0}
          onClick={handleNext}
          aria-label="fast forward"
          size="large"
        >
          <FastForwardIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

ImageNavPanel.propTypes = {
  isDisabled: PropTypes.bool,
  scanIndex: PropTypes.number.isRequired,
  handlePrev: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleLeftArrow: PropTypes.func.isRequired,
  handleRightArrow: PropTypes.func.isRequired,
  children: PropTypes.node
};
