import { makeStyles } from "@kuva/ui-components";

import Theme from "~/common/Theme";

export const useStyles = makeStyles()(
  ({ spacing, palette }, { alarmStart, alarmEnd, isAlarmStateValid }) => ({
    dialog: {
      pointerEvents: "none"
    },
    dialogPaper: {
      position: "absolute",
      right: spacing(12),
      bottom: spacing(4),
      backgroundColor: Theme.kuvaColors.black,
      width: "250px"
    },
    dialogTitle: {
      cursor: "move"
    },
    closeButton: {
      position: "absolute",
      right: spacing(1),
      top: spacing(1),
      color: palette.grey[500]
    },
    dialogContentButtons: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      marginBottom: spacing(1)
    },
    dialogContent: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around"
    },
    alarmStart: {
      fontSize: "15px",
      color: alarmStart ? "white" : "gray"
    },
    alarmEnd: {
      fontSize: "15px",
      color: alarmEnd ? "white" : "gray"
    },
    setEnd: {
      display: "block"
    },
    dialogActions: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      width: "100%",
      marginBottom: spacing(3)
    },
    quantificationSection: {
      display: "flex",
      justifyContent: "space-between"
    },
    leakSourceDistance: {
      marginTop: "15px",
      marginBottom: "5px",
      width: "100px"
    },
    leakSourceActionWrapper: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      marginRight: "20px",
      marginTop: "5px",
      "& .MuiFormLabel-root": {
        marginBottom: "15px"
      }
    },
    dialogSection: {
      marginLeft: "10px",
      marginTop: "5px"
    },
    humanActivity: {
      display: "flex",
      gap: "40px"
    },
    disableNotif: {
      fontSize: "12px"
    },
    saveButtonEnabled: {
      width: "30%",
      color: "black",
      backgroundColor: "#2EC2CC",
      transition: "filter 0.3s ease",
      "&:hover": {
        backgroundColor: "#2EC2CC",
        filter: "brightness(80%)"
      }
    },
    actionBtn: {
      width: "30%"
    },
    redBox: {
      outline: isAlarmStateValid ? "none" : "2px solid red",
      outlineOffset: "6px",
      borderRadius: "1px"
    },
    warningBoxPointer: {
      position: "absolute",
      top: "-10px",
      left: "50%",
      transform: "translateX(-50%)",
      width: 0,
      height: 0,
      borderLeft: "5px solid transparent",
      borderRight: "5px solid transparent",
      borderBottom: "5px solid gray",
      marginTop: "62px",
      zIndex: 3
    },
    warningBox: {
      marginTop: "12px",
      marginLeft: "-30px",
      backgroundColor: "gray",
      position: "absolute",
      padding: "5px",
      borderRadius: "5px",
      zIndex: 2
    },
    tagsSelect: {
      marginBottom: spacing(1)
    },
    chip: {
      margin: 2
    },
    chips: {
      display: "flex",
      flexWrap: "wrap"
    },
    formControl: {
      margin: spacing(1),
      width: "100%"
    },
    tagsInputLabel: {
      marginLeft: "4px",
      marginTop: "3px",
      fontSize: "12px"
    },
    toggle: {
      top: 0,
      zIndex: 10,
      position: "sticky",
      textAlign: "center",
      background: "black",
      padding: "5px"
    }
  })
);
