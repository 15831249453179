import { createAsyncThunk } from "@reduxjs/toolkit";

import AlgorithmAPI from "~/common/apis/AlgorithmAPI";

export const getAlgorithmRecords = createAsyncThunk(
  "get-algorithm-records",

  async () => {
    return await AlgorithmAPI.getAlgorithmRecords();
  }
);
