import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { GlobalStyle } from "GlobalStyle";
import PropTypes from "prop-types";

export const PoiSelect = ({
  displayType,
  selectedPoi,
  setSelectedPoi,
  poiKeys,
  setScanIndex
}) => {
  const { classes: globalStyle } = GlobalStyle();
  const poiList =
    displayType === "panorama" ? poiKeys.filter(poi => poi !== "All") : poiKeys;
  const handleChange = event => {
    setSelectedPoi(event.target.value);
    setScanIndex(0);
  };

  return (
    <FormControl
      variant="standard"
      className={globalStyle.formControlSelect}
      style={{
        flex: displayType === "grid" && 1
      }}
    >
      <InputLabel
        className={[globalStyle.font14, globalStyle.uppercase]}
        id="poi-select-label"
      >
        poi select
      </InputLabel>
      <Select
        variant="standard"
        id="poi-select"
        value={selectedPoi}
        label="POI"
        onChange={handleChange}
      >
        {poiList.map(poi => {
          return (
            <MenuItem key={poi} value={poi}>
              {poi}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

PoiSelect.propTypes = {
  displayType: PropTypes.string.isRequired,
  selectedPoi: PropTypes.number.isRequired,
  setSelectedPoi: PropTypes.func.isRequired,
  poiKeys: PropTypes.array.isRequired,
  setScanIndex: PropTypes.func.isRequired
};
