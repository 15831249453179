import { makeStyles } from "@kuva/ui-components";

import { scrollbarStyles } from "~/common/styles";

export const useDistanceMapperStyles = makeStyles()(() => ({
  closeIcon: {
    fontSize: "20px",
    position: "absolute",
    right: "20px",
    top: "20px",
    cursor: "pointer",
    color: "#ffffff"
  }
}));

export const useCommonStyles = makeStyles()(({ spacing }) => ({
  formControl: {
    minWidth: 80,
    "& .MuiOutlinedInput-input": {
      fontSize: "14px",
      color: "white",
      padding: spacing(1.4, 1.2, 1, 1.2),
      borderRadius: "4px",
      border: "0.5px solid #A6A6A6",
      background: " rgba(34, 34, 34, 0.00)"
    }
  }
}));

export const useControlPannelContentStyles = makeStyles()({
  poiLabel: {
    margin: "0 10px 0 30px"
  }
});

export const useDistanceTableStyles = makeStyles()(({ spacing }) => ({
  root: {
    marginTop: spacing(5),
    "& .MuiAutocomplete-inputRoot": {
      paddingTop: spacing(0.1),
      paddingBottom: spacing(0.2)
    },
    "& .MuiInputBase-input": {
      fontSize: "14px"
    }
  },
  tableContainer: {
    maxHeight: 410,
    ...scrollbarStyles,
    marginTop: spacing(2),
    marginBottom: spacing(2)
  },
  table: {
    minWidth: 600
  },
  selectedRow: {
    backgroundColor: "#222222",
    boxShadow: "0px 0px 4px 4px rgba(255,255, 255, 0.6)"
  },
  deleteIcon: {
    color: "#FE5431",
    cursor: "pointer"
  },
  distanceField: {
    width: "80px"
  },
  submitButton: {
    minWidth: "140px"
  }
}));
