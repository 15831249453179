export const normalizeData = data => {
  const normalizedData = {};

  data.forEach(item => {
    const poi = item.poi;
    normalizedData[poi] = item;
  });

  return normalizedData;
};
