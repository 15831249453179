import { createAsyncThunk } from "@reduxjs/toolkit";

import CameraAPI from "~/common/apis/CameraAPI";

const thunk = async payload => {
  const { id, deviceId, distanceSegments } = payload;
  const response = await CameraAPI.updatePoiDistanceSegments(
    id,
    deviceId,
    distanceSegments
  );
  return response;
};

export const updatePoiDistanceSegments = createAsyncThunk(
  "camera/updatePoiDistanceSegments",
  thunk
);
