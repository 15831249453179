import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { useSnackbar } from "notistack";
import {
  Checkbox,
  Box,
  Button,
  Dialog,
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import { isEmpty, max } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Circle, Layer, Rect, Stage } from "react-konva";
import { useSelector, shallowEqual } from "react-redux";
import useImage from "use-image";

import { DEFAULT_POINT_SIZE } from "~/containers/LeakSourceLocationTool/components/LeakSroucePoint/constants";
import { selectIsEditing } from "~/common/selectors/AlarmSelector";
import { selectDistanceSegmentsState } from "~/common/selectors/distance-segments-selector";
import Theme from "~/common/Theme";
import {
  defaultImageSize,
  getDistanceSegment,
  Segment,
  SegmentOpacitySlider
} from "~/containers/DistanceMapper";

import CanvasWrapper from "./components/CanvasWrapper";
import { DateInRangeWarningTooltip } from "./components/DateInRangeWarningTooltip";
import { useStyles } from "./styles";

const DEFAULT_SCALE = 1.4;

const LeakSourceLocationTool = ({
  isInAlarmDateRange,
  showLeakSourceTool,
  alarmLeakSources,
  setAlarmLeakSources,
  setShowLeakSourceTool,
  selectedScan,
  fullScreenImgUrl,
  scanIndex,
  scanResults,
  handleExtendAlarmPeriod,
  handleLeftArrow,
  handleRightArrow,
  selectedPoi,
  quantificationProps
}) => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    setSelectedSource,
    setLeakDistance,
    setDistanceSegment,
    showDistanceSegments,
    setShowDistanceSegments
  } = quantificationProps;

  const poiDistanceSegmentState = useSelector(
    selectDistanceSegmentsState,
    shallowEqual
  );

  const [leakSourcesCanvasCoords, setLeakSourcesCanvasCoords] = useState([]);
  const [newLeakSource, setNewLeakSource] = useState([]);
  const [leakClassification, setLeakClassification] = useState("origin");
  const [scale, setScale] = useState(DEFAULT_SCALE);
  const [segmentOpacity, setSegmentOpacity] = useState(0.5);

  const [image] = useImage(fullScreenImgUrl);

  const { poiDistanceSegments } = poiDistanceSegmentState;
  const segments = poiDistanceSegments[selectedPoi]?.segments || [];

  const isInteractive = isInAlarmDateRange(selectedScan.createdOn);

  const isAlarmEditing = useSelector(selectIsEditing);
  const imgscaleX = selectedScan?.imgscaleX || 1;

  useEffect(() => {
    initialize();
  }, [fullScreenImgUrl, selectedScan, scanIndex]);

  const handleMouseDown = event => {
    if (isInteractive) {
      const { x, y } = event.target.getStage().getPointerPosition();
      if (leakClassification === "roi" && newLeakSource.length === 0) {
        setNewLeakSource([{ x, y, width: 0, height: 0, key: "0" }]);
      } else if (leakClassification === "origin") {
        const distanceSegment = getDistanceSegment(segments, [{ x, y }]);
        if (!isEmpty(distanceSegment)) {
          const { distance, sourceTag } = distanceSegment;
          setDistanceSegment(distanceSegment);
          setLeakDistance(distance);
          setSelectedSource(sourceTag);
        } else {
          setDistanceSegment({});
          setLeakDistance("");
          setSelectedSource(null);
          enqueueSnackbar("Distance not found. Please enter it manually.", {
            variant: "info"
          });
        }
        setAlarmLeakSources({ ...alarmLeakSources, leakOrigin: [x, y] });
      }
    }
  };

  const handleMouseUp = event => {
    if (!isInteractive || !newLeakSource.length) {
      return;
    }

    const sx = newLeakSource[0].x;
    const sy = newLeakSource[0].y;
    const { x, y } = event.target.getStage().getPointerPosition();
    const minX = Math.min(sx, x);
    const minY = Math.min(sy, y);

    // Canvas coordinates
    const leakSourceToAdd = {
      key: leakSourcesCanvasCoords.length,
      x: minX,
      y: minY,
      width: Math.max(sx, x) - minX,
      height: Math.max(sy, y) - minY
    };

    setLeakSourcesCanvasCoords([leakSourceToAdd]);
    setNewLeakSource([]);

    // Translate x,y, width, height to 8 coordinates
    //
    // e.g.
    // height: 14
    // key: 1
    // width: 24
    // x: 92.5
    // y: 170
    // ================
    // 1 = x       92.5
    // 2 = y       170
    // 3 = x + w   116.5
    // 4 = y       170
    // 5 = x + w   116.5
    // 6 = y + h   184
    // 7 = x       92.5
    // 8 = y + h   184

    /*   
      The X dimensions need to be divided by the same scale property (imgScaleX) 
      to match the actual location in the fast scan image. 
    */
    const coords = [
      leakSourceToAdd.x / imgscaleX,
      leakSourceToAdd.y,
      (leakSourceToAdd.x + leakSourceToAdd.width) / imgscaleX,
      leakSourceToAdd.y,
      (leakSourceToAdd.x + leakSourceToAdd.width) / imgscaleX,
      leakSourceToAdd.y + leakSourceToAdd.height,
      leakSourceToAdd.x / imgscaleX,
      leakSourceToAdd.y + leakSourceToAdd.height
    ];

    const leakSources = {
      ...alarmLeakSources,
      leakRois: [
        {
          id: leakSourceToAdd.key,
          coords
        }
      ]
    };
    setAlarmLeakSources(leakSources);
  };

  const handleMouseMove = event => {
    if (newLeakSource.length === 1) {
      const sx = newLeakSource[0].x;
      const sy = newLeakSource[0].y;
      const { x, y } = event.target.getStage().getPointerPosition();
      const minX = Math.min(sx, x);
      const minY = Math.min(sy, y);

      setLeakSourcesCanvasCoords([
        {
          x: minX,
          y: minY,
          width: Math.max(sx, x) - minX,
          height: Math.max(sy, y) - minY,
          key: "0"
        }
      ]);
    }
  };

  const initialize = () => {
    if (selectedScan?.leakRois?.length > 0) {
      // Translate  to Canvas coordinates
      // height: 14
      // key: 1
      // width: 24
      // x: 92.5
      // y: 170
      // ==============
      let canvasCoordinates = [];
      selectedScan?.leakRois?.map(value => {
        canvasCoordinates.push({
          x: value?.coords[0] * imgscaleX,
          y: value?.coords[1],
          width: (value?.coords[2] - value?.coords[0]) * imgscaleX,
          height: value?.coords[5] - value?.coords[3]
        });
      });

      setLeakSourcesCanvasCoords(canvasCoordinates);
    } else {
      setLeakSourcesCanvasCoords([]);
    }
  };

  const handleOnChangeType = event => {
    setLeakClassification(event.target.value);
  };

  const handleStartOver = () => {
    setLeakSourcesCanvasCoords([]);
    setNewLeakSource([]);
    setAlarmLeakSources({
      leakRois: [],
      leakOrigin: []
    });

    setSelectedSource(null);
    setLeakDistance("");
    setDistanceSegment({});
  };

  const handleZoomIn = () => setScale(scale => scale + 0.5);

  const handleZoomOut = () =>
    setScale(scale => max([scale - 0.5, DEFAULT_SCALE]));

  return (
    <Dialog
      open={showLeakSourceTool}
      onClose={() => setShowLeakSourceTool(true)}
      // Style override is required here since MUI applies the zIndex style
      // after the class styles are applied, so a class override won't work.
      sx={{
        zIndex: 1200,
        "& .MuiDialog-paper": { backgroundImage: "unset" }
      }}
      fullScreen
      disableEnforceFocus
    >
      <DateInRangeWarningTooltip
        handleExtendAlarmPeriod={handleExtendAlarmPeriod}
        date={selectedScan.createdOn}
        open={!isInteractive}
        placement="top"
        interactive
        isAlarmEditing={isAlarmEditing}
      >
        <CanvasWrapper scale={scale} $interactive={isInteractive}>
          <Stage
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            width={image?.width || defaultImageSize.width}
            height={image?.height || defaultImageSize.height}
          >
            <Layer>
              <Rect
                width={image?.width}
                height={image?.height}
                shadowBlur={20}
                fillPatternImage={image}
              />
              {showDistanceSegments && (
                <Segment
                  segments={segments}
                  disabled={true}
                  opacity={segmentOpacity}
                />
              )}
              {!isEmpty(selectedScan?.leakOrigin) && (
                <Circle
                  x={selectedScan?.leakOrigin?.[0]}
                  y={selectedScan?.leakOrigin?.[1]}
                  width={DEFAULT_POINT_SIZE}
                  height={DEFAULT_POINT_SIZE}
                  fill={Theme.kuvaColors.red}
                />
              )}
              {leakSourcesCanvasCoords.map(value => {
                return (
                  <Rect
                    key={value.key}
                    x={value.x}
                    y={value.y}
                    width={value.width}
                    height={value.height}
                    fill="transparent"
                    stroke={Theme.kuvaColors.orange}
                  />
                );
              })}
            </Layer>
          </Stage>
        </CanvasWrapper>
      </DateInRangeWarningTooltip>

      <div className={classes.controlMenuWrapper}>
        <div className={classes.buttonsWrapper}>
          <IconButton onClick={handleZoomOut} size="large">
            <ZoomOutIcon fontSize="large" />
          </IconButton>
          <IconButton onClick={handleZoomIn} size="large">
            <ZoomInIcon fontSize="large" />
          </IconButton>
          <Button
            onClick={() => setScale(DEFAULT_SCALE)}
            style={{
              color: "#ffffff"
            }}
          >
            Reset Screen
          </Button>
          |
          <Button
            onClick={handleStartOver}
            style={{
              color: Theme.kuvaColors.orange
            }}
          >
            <RefreshIcon fontSize="medium" /> Start Over
          </Button>
          <div style={{ marginLeft: 16 }}>
            <RadioGroup
              row
              aria-label="position"
              name="position"
              value={leakClassification}
              onChange={handleOnChangeType}
            >
              <FormControlLabel
                value="roi"
                control={<Radio color="primary" />}
                label="ROI"
                labelPlacement="right"
              />
              <FormControlLabel
                value="origin"
                control={<Radio color="primary" />}
                label="ORIGIN"
                labelPlacement={"right"}
              />
            </RadioGroup>
            <Divider />
            <Box sx={{ width: 250, marginTop: "20px" }}>
              <Typography>Segments Settings:</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showDistanceSegments}
                    onChange={event =>
                      setShowDistanceSegments(event.target.checked)
                    }
                    color="primary"
                  />
                }
                label="Show"
              />
              <Typography>Opacity:</Typography>
              <SegmentOpacitySlider
                opacity={segmentOpacity}
                handleChange={(_event, value) => setSegmentOpacity(value)}
              />
            </Box>
          </div>
        </div>
        <div className={classes.timeWrapper}>
          <Typography>
            <span>TIME:</span>
            {selectedScan?.createdOn?.substring(11, 19) /* Get UTC time */}
          </Typography>
        </div>
      </div>

      <CloseIcon
        onClick={() => setShowLeakSourceTool(false)}
        className={classes.closeIcon}
      />
      <div className={classes.arrowButtonsWrapper}>
        <IconButton
          disabled={scanIndex === scanResults.length - 1}
          onClick={handleLeftArrow}
          aria-label="left"
          style={{
            border: scanIndex !== scanResults.length - 1 && "5px solid black"
          }}
          size="large"
        >
          <ArrowLeftIcon fontSize="large" />
        </IconButton>
        <IconButton
          disabled={scanIndex === 0}
          onClick={handleRightArrow}
          aria-label="right"
          style={{
            border: scanIndex !== 0 && "5px solid black"
          }}
          size="large"
        >
          <ArrowRightIcon fontSize="large" />
        </IconButton>
      </div>
    </Dialog>
  );
};

LeakSourceLocationTool.defaultProps = {
  selectedScan: {},
  scanResults: [],
  fullScreenImgUrl: ""
};

LeakSourceLocationTool.propTypes = {
  isInAlarmDateRange: PropTypes.func.isRequired,
  showLeakSourceTool: PropTypes.bool.isRequired,
  setAlarmLeakSources: PropTypes.func.isRequired,
  setShowLeakSourceTool: PropTypes.func.isRequired,
  alarmLeakSources: PropTypes.object.isRequired,
  selectedScan: PropTypes.object.isRequired,
  fullScreenImgUrl: PropTypes.string.isRequired,
  scanIndex: PropTypes.number.isRequired,
  scanResults: PropTypes.array.isRequired,
  setScanResults: PropTypes.func,
  handleExtendAlarmPeriod: PropTypes.func.isRequired,
  handleLeftArrow: PropTypes.func.isRequired,
  handleRightArrow: PropTypes.func.isRequired,
  selectedPoi: PropTypes.number
};

export default LeakSourceLocationTool;
