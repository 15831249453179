import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import MinimizeIcon from "@mui/icons-material/Minimize";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  Paper,
  Typography
} from "@mui/material";
import { addDays, isAfter } from "date-fns";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useElapsedTime } from "use-elapsed-time";

import { createShift, completeShift } from "~/common/actions/shiftsActions";
import {
  getCurrentShift,
  selectIsLoading,
  selectIsStarted,
  selectShiftError
} from "~/common/selectors/ShiftSelector";

import { useStyles } from "./Styles";
import PopoverAlert from "./components/Popover";
import { convertToHHMMSS, getStartDateTimeInSecs } from "./utils";

/** @function ShiftWidget
* The Shift Widget returns a draggable dialog that shows the time elapsed since the start of the shift
* and a function/button that start/end the shift.
* 
  @param {bool} open: [optional] boolean that determines whether ShiftWidget is open or closed
  @param {func} onClose: [optional] function to be called when 'Cancel' button is pressed
  @param {string} redirectUrl: string that contains the URL used for redirection after the session is ended
*/

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const ShiftWidget = ({ onClose }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const currentShift = useSelector(getCurrentShift, shallowEqual);
  const isStarted = useSelector(selectIsStarted, shallowEqual);
  const isLoading = useSelector(selectIsLoading, shallowEqual);
  const shiftError = useSelector(selectShiftError, shallowEqual);
  const { elapsedTime, reset } = useElapsedTime({
    isPlaying: isStarted
  });
  const startTime = currentShift?.startTime;
  const timeInHHMMSS = convertToHHMMSS(elapsedTime?.toFixed(2));
  const endTime = addDays(new Date(startTime), 1);
  const is24HoursPassed = isAfter(new Date(), endTime);

  const handleStartShift = () => {
    dispatch(createShift()).then(res => {
      if (!res) {
        enqueueSnackbar("Error creating shift!", {
          variant: "error"
        });
      }
    });
  };

  const handleEndShift = () => {
    const { id, startTime } = currentShift;
    dispatch(completeShift(id, startTime));
  };

  const handleShiftTimeout = (startTime, is24HoursPassed) => {
    if (startTime && is24HoursPassed) {
      const oneDayLater = addDays(new Date(startTime), 1);
      handleEndShift(oneDayLater);
      history.push("/sessions-landing");
      enqueueSnackbar(
        `The last shift started more than a day ago and will automatically end 24 hours from the start time, which is ${oneDayLater}.`,
        { variant: "warning" }
      );
    }
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleShiftTimeout(startTime, is24HoursPassed);
  }, [is24HoursPassed]);

  // Check if user already started his/her shift
  useEffect(() => {
    if (!isEmpty(currentShift)) {
      reset(getStartDateTimeInSecs(new Date(currentShift?.startTime)));
    }
  }, [currentShift, isStarted]);

  const openPopover = Boolean(anchorEl);

  return (
    <div className={classes.root}>
      <PopoverAlert
        id="simple-popover"
        anchorEl={anchorEl}
        handleClose={handleClosePopover}
        openPopover={false}
        style={{ borderRadius: "100%" }}
      />
      {!openPopover ? (
        <Dialog
          disableEnforceFocus
          hideBackdrop
          classes={{
            root: classes.dialogRoot,
            paper: classes.dialogMinimized
          }}
          open
          onClose={onClose}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle
            className={classes.dialogTitle}
            style={{
              cursor: openPopover ? "move" : "unset",
              borderLeft: isStarted ? "4px solid green" : "4px solid gray"
            }}
            id="draggable-dialog-title"
          >
            <IconButton
              aria-label="close"
              size="small"
              className={classes.closeButton}
              onClick={handleClosePopover}
            >
              <MinimizeIcon />
            </IconButton>
            <div className={classes.dialogContentMinimized}>
              <IconButton
                data-testid="expand-session-status"
                className={classes.openButton}
                onClick={e => setAnchorEl(e.currentTarget)}
                size="large"
              >
                <ArrowBackIosIcon style={{ fontSize: "12px" }} />
              </IconButton>
            </div>
          </DialogTitle>
        </Dialog>
      ) : (
        <Dialog
          disableEnforceFocus
          hideBackdrop
          classes={{
            root: classes.dialogRoot,
            paper: classes.dialog
          }}
          open
          onClose={onClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle
            className={classes.dialogTitle}
            style={{
              cursor: openPopover ? "move" : "unset",
              borderLeft: isStarted ? "4px solid green" : "4px solid gray"
            }}
            id="draggable-dialog-title"
          >
            <IconButton
              aria-label="close"
              size="small"
              className={classes.closeButton}
              onClick={handleClosePopover}
            >
              <MinimizeIcon />
            </IconButton>
            <div className={classes.dialogContent}>
              {isLoading ? (
                <CircularProgress role="spinner" size={24} />
              ) : (
                <>
                  {!openPopover && (
                    <IconButton
                      style={{ marginLeft: "-30px" }}
                      onClick={e => setAnchorEl(e.currentTarget)}
                      size="large"
                    >
                      <ArrowBackIosIcon />
                    </IconButton>
                  )}
                  <div style={{ marginRight: 20 }}>
                    <Typography variant="subtitle2">ELAPSED TIME</Typography>
                    <Typography variant="body2">
                      {(isStarted && elapsedTime && timeInHHMMSS) || "HH:MM:SS"}
                    </Typography>
                  </div>
                  <div className={classes.buttonContainer}>
                    {isStarted ? (
                      <Button
                        className={classes.actionButton}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => handleEndShift()}
                      >
                        END SHIFT
                      </Button>
                    ) : (
                      <Button
                        className={classes.actionButton}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={handleStartShift}
                        disabled={Boolean(shiftError)}
                      >
                        START SHIFT
                      </Button>
                    )}
                  </div>
                </>
              )}
            </div>
            {Boolean(shiftError) && (
              <Typography variant="subtitle2">
                Unable to retrieve shifts data.
              </Typography>
            )}
          </DialogTitle>
        </Dialog>
      )}
    </div>
  );
};

ShiftWidget.propTypes = {
  onClose: PropTypes.func
};

export default ShiftWidget;
