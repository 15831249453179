import { useAuth0 } from "@auth0/auth0-react";

const useAuthenticatedUser = () => {
  const { user } = useAuth0();
  const userId = user?.sub;
  const roles = user["kcc/roles"];
  const isSuperuser = roles?.includes("superuser");
  const isAdmin = roles?.includes("admin");
  const isReviewer = roles?.includes("reviewer");
  const isQA = roles?.includes("qa");
  const isReviewerLead = roles?.includes("reviewer-lead");
  const hasReviewerAccessOrQA = isQA || isReviewer || isReviewerLead;

  return {
    isAdmin,
    isQA,
    isReviewer,
    isReviewerLead,
    isSuperuser,
    hasReviewerAccessOrQA,
    user,
    userId,
    roles
  };
};

export default useAuthenticatedUser;
