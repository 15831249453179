import { createSlice } from "@reduxjs/toolkit";

import { getPoiDistanceSegments } from "../thunks/get-poi-distance-segments";
import { createPoiDistanceSegments } from "../thunks/create-poi-distance-segments";
import { updatePoiDistanceSegments } from "../thunks/update-poi-distance-segments";

import { normalizeData } from "./utils";

const initialState = {
  error: null,
  loading: false,
  poiDistanceSegments: {}
};

const slice = createSlice({
  name: "distance-segments",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getPoiDistanceSegments.pending, state => {
        state.loading = true;
      })
      .addCase(getPoiDistanceSegments.fulfilled, (state, action) => {
        state.loading = false;
        state.poiDistanceSegments = normalizeData(action?.payload?.data);
      })
      .addCase(getPoiDistanceSegments.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })

      .addCase(createPoiDistanceSegments.pending, state => {
        state.loading = true;
      })
      .addCase(createPoiDistanceSegments.fulfilled, (state, action) => {
        state.loading = false;
        const { data } = action.payload;
        state.poiDistanceSegments[data.poi] = data;
      })
      .addCase(createPoiDistanceSegments.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })

      .addCase(updatePoiDistanceSegments.pending, state => {
        state.loading = true;
      })
      .addCase(updatePoiDistanceSegments.fulfilled, (state, action) => {
        state.loading = false;
        const { data } = action.payload;
        state.poiDistanceSegments[data.poi] = data;
      })
      .addCase(updatePoiDistanceSegments.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  }
});

export const PoiDistanceSegmentsActions = {
  ...slice.actions,
  createPoiDistanceSegments,
  getPoiDistanceSegments,
  updatePoiDistanceSegments
};

export const PoiDistanceSegmentsReducer = slice.reducer;
