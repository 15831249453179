import { CameraAppModuleTwin } from "../models/TwinChangeMessage";
import { Camera } from "../models/Camera";
import CameraAPI from "../apis/CameraAPI";

/**
 * @export function getCameras
 * @param {string} which - the device id for a camera to be fetched,
 * if null, returns all cameras
 *
 * sets an array of camera class instances in the redux store
 */
export const getCameras = (orgId, which) => {
  return (dispatch, getState) => {
    CameraAPI.get(orgId, which)
      .then(response => {
        if (response.data) {
          const _retVal = response.data;
          const cameras = _retVal.map(cam => new Camera(cam));
          dispatch({ type: "FETCH_CAMERAS_SUCCESS", cameras });

          response.data.map(cam => {
            if (
              cam?.properties?.reported?.camera?.scanning ||
              cam?.properties?.reported?.camera?.illuminating
            ) {
              const upadteObject = new CameraAppModuleTwin(
                cam.properties.reported.camera,
                cam.deviceId
              );
              setCameraReportedAttributes(upadteObject)(dispatch, getState);
            }
          });
        }
      })
      .catch(error => {
        console.error(error);
        // throw error;
      });
  };
};

/**
 * @export function updateCamera
 * @param {string} deviceId - the camera's identifier
 * @param {ICamera} attributes - the tags to be updated
 *
 * This function updates a camera with the provided attributes
 */
export const updateCamera = (deviceId, attributes) => {
  return dispatch => {
    CameraAPI.updateDeviceAttributes(deviceId, attributes)
      .then(response => {
        if (response.data) {
          dispatch({ type: "UPDATE_CAMERAS_SUCCESS", deviceId, attributes });
        }
      })
      .catch(error => {
        throw error;
      });
  };
};

/**
 *
 * @description this function is called when we want to update the name, location, orgId etc...
 * @author Sean W.
 * @date 16/10/2021
 * @export function setCameraReportedAttributes
 * @param {object} newAttributes - the attrributes to update sent from the iot hub
 *
 * Check if the reported properties changed
 * finds the camera object instance  these properties belong too
 * updates the camera object instance properties
 * sends the camera object instance  to redux to update the store
 *
 */
export function setCameraReportedAttributes(newAttributes) {
  return function (dispatch, getState) {
    // find the previous row if it exists
    const row = getState().reported?.find(
      r => r.deviceId === newAttributes.deviceId
    );

    // check if there's allready a newer update
    if (row && !row.checkIfNewerMessage(newAttributes)) return;

    //send the updated message to redux to handle
    dispatch({
      type: "UPSERT_REPORTED_SUCCESS",
      reported: {
        // deviceId: attr.deviceId,
        ...newAttributes
      }
    });
  };
}
