import { useState } from "react";
import { noop } from "lodash";
import PropTypes from "prop-types";
import { Circle } from "react-konva";

import { anchorSettings } from "./graphic-settings";

export const Anchor = ({
  point,
  fill,
  onMouseOver = noop,
  onMouseOut = noop,
  onClick = noop,
  defaultStrokeWidth = anchorSettings.strokeWidth,
  defaultAnchorColor = anchorSettings.anchorColor
}) => {
  const [strokeWidth, setStrokeWidth] = useState(defaultStrokeWidth);

  return (
    <Circle
      x={point.x}
      y={point.y}
      radius={anchorSettings.anchorRadius}
      stroke={defaultAnchorColor}
      fill={fill}
      strokeWidth={strokeWidth}
      onMouseOver={() => {
        setStrokeWidth(defaultStrokeWidth);
        onMouseOver();
      }}
      onMouseOut={() => {
        setStrokeWidth(defaultStrokeWidth);
        onMouseOut();
      }}
      onClick={() => {
        onClick();
      }}
    />
  );
};

Anchor.propTypes = {
  point: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  }),
  fill: PropTypes.string.isRequired,
  onMouseOver: PropTypes.func.isRequired,
  onMouseOut: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  defaultStrokeWidth: PropTypes.number,
  defaultAnchorColor: PropTypes.string
};
