import MuiFrameIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import NoMLScoreIcon from "@mui/icons-material/ImageNotSupported";
import PropTypes from "prop-types";

import { mlScoreSliderSettings } from "~/constants/ml-score";

import { determineColor } from "./color-utils";

function ImageIcon({
  scanResultId,
  getScanIndexById,
  setScanIndex,
  isActive,
  score,
  scoreRanges = mlScoreSliderSettings.mlScoreRanges,
  scoreColors = mlScoreSliderSettings.mlScoreColors
}) {
  const handleClick = ({ scanResultId }) => {
    const scanIndex = getScanIndexById(scanResultId);
    setScanIndex(scanIndex);
  };

  if (!score) {
    return (
      <NoMLScoreIcon
        key={scanResultId}
        sx={{
          fontSize: "16px",
          color: "white",
          borderTop: isActive ? "4px solid green" : ""
        }}
        onClick={() => handleClick({ scanResultId })}
      />
    );
  }

  return (
    <MuiFrameIcon
      key={scanResultId}
      sx={{
        fontSize: "16px",
        color: determineColor(score, scoreRanges, scoreColors),
        borderTop: isActive ? "4px solid green" : ""
      }}
      onClick={() => handleClick({ scanResultId })}
    />
  );
}

ImageIcon.propTypes = {
  scanResultId: PropTypes.string.isRequired,
  getScanIndexById: PropTypes.func.isRequired,
  setScanIndex: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  score: PropTypes.number,
  scoreRanges: PropTypes.arrayOf(PropTypes.number).isRequired,
  scoreColors: PropTypes.arrayOf(PropTypes.string).isRequired
};

export { ImageIcon };
