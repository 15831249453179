import produce from "immer";

import { initialState } from "./initialState";

export default function shiftsReducer(state = initialState.shiftState, action) {
  switch (action.type) {
    case "SET_SHIFT_IS_LOADING":
      return { ...state, isLoading: action.isLoading };

    case "FETCH_SHIFTS_SUCCESS":
      return {
        ...state,
        isStarted: state.isStarted,
        shifts: sortByStartTime(action.shifts),
        current: state.current
      };

    case "FETCH_CURRENT_SHIFT_SUCCESS":
      return { ...state, current: action.currentShift, error: false };

    case "FETCH_CURRENT_SHIFT_ERROR":
      return { ...state, error: action.error };

    case "SET_SHIFT_IS_STARTED":
      return { ...state, isStarted: action.isStarted };

    case "START_NEW_SHIFT_SUCCESS": {
      const withNewShift = produce(state, draftState => {
        draftState.shifts.push(action.shift);
        sortByStartTime(draftState.shifts);
      });
      return {
        ...state,
        shifts: withNewShift.shifts,
        current: action.shift,
        isStarted: true
      };
    }

    case "CONTINUE_PREVIOUS_SHIFT_SUCCESS":
      return { ...state, shifts: state.shifts, current: action.shift };

    case "COMPLETE_SHIFT_SUCCESS": {
      const withUpdatedShift = produce(state, draftState => {
        let shiftIndex = draftState.shifts.findIndex(
          shift => shift.id === action.shift.id
        );
        draftState.shifts.splice(shiftIndex, 1, action.shift);
        sortByStartTime(draftState.shifts);
      });
      return { ...state, shifts: withUpdatedShift.shifts, current: undefined };
    }

    default: {
      const sortedShiftState = produce(state, draftState => {
        sortByStartTime(draftState.shifts);
      });
      return {
        ...state,
        shifts: sortedShiftState.shifts,
        current: state.current
      };
    }
  }
}

const sortByStartTime = shifts =>
  shifts.sort((a, b) => +b.startTime - +a.startTime);
