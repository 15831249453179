import { isEmpty } from "lodash";
import produce from "immer";

import { cameraSelectionModes, hours } from "~/constants/reviewerSessions";

import { initialState } from "./initialState";
import { groupCameraListsByIotHub } from "./utils/sessionsReducerUtils";

const updateSelectedDeviceHourlySessions = (
  selectedSessions,
  updatedHourlySessions
) => {
  const updatedSessions = selectedSessions.map(session => {
    const matchingHourly = updatedHourlySessions.find(
      hourly =>
        hourly.cameraId === session.cameraId &&
        new Date(hourly.startTime).toISOString() ===
          new Date(session.startTime).toISOString()
    );

    if (matchingHourly) {
      // Update the session properties with matching hourly data
      session = { ...session, ...matchingHourly };
    }

    return session;
  });

  return updatedSessions;
};

export default function sessionsReducer(
  state = initialState.sessionState,
  action
) {
  return produce(state, draft => {
    switch (action.type) {
      case "SET_SESSION_IS_LOADING":
        draft.isLoading = action.isLoading;
        break;

      case "SET_SESSION_SELECTED_CAMERA":
        appendSelectedCamera(
          draft.selectedCameras,
          action.orgId,
          action.selectedCamera
        );
        break;

      case "SET_SESSION_SELECTED_CAMERAS":
        appendSelectedCamera(
          draft.selectedCameras,
          action.orgId,
          action.selectedCameras,
          true
        ); // selectAll = true
        break;

      case "DELETE_SESSION_SELECTED_CAMERA": {
        // Remove the camera sesssions on the sessions list
        const sessions = draft.sessions.filter(
          session => session.cameraId !== action.deviceId
        );
        draft.sessions = sessions;
        // Delete camera on the camera list
        deleteCamera(draft.selectedCameras, action.orgId, action.deviceId);
        break;
      }

      case "RESET_SESSION_ORGS_SELECTED_CAMERAS":
        resetCameraList(draft.selectedCameras, action.orgId);
        break;

      case "SET_SESSION_SELECTED_DURATION":
        draft.selectedDuration = action.selectedDuration;
        break;

      case "SET_SESSION_SELECTED_DATE":
        draft.selectedDate = action.selectedDate;
        break;

      case "SET_SESSION_SELECTED_DATE_RANGE":
        draft.selectedDateRange = action.selectedDateRange;
        break;

      case "SET_SESSION_SELECTED_TIME":
        draft.selectedTime = action.selectedTime;
        break;

      case "SET_CURRENT_SESSION":
        draft.current = action.session;
        break;

      case "SET_SESSIONS":
        draft.sessions = action.sessions;
        break;

      case "UPSERT_SESSIONS":
        draft.sessions = appendCameraSessions(draft.sessions, action.sessions);
        break;

      case "RESET_SESSIONS":
        draft.sessions = [];
        break;
      case "SET_SESSION_EVENTS":
        draft.events = action.sessionEvents;
        break;

      case "UPSERT_SESSION_EVENTS":
        draft.events = appendCameraSessionEvents(
          draft.events,
          action.sessionEvents
        );
        break;

      case "RESET_SESSION_EVENTS":
        draft.events = [];
        break;

      case "SET_SESSIONS_WITH_SCRES":
        draft.sessionsWithScres = action.sessionsWithScres;
        break;

      case "SET_OPEN_HOURLY_DIALOG":
        draft.openHourlyDialog = action.openHourlyDialog;
        break;

      case "SET_ACTIVE_HOURLY_SESSIONS":
        draft.activeHourlySessions = action.activeHourlySessions;
        break;

      case "SET_SELECTED_HOURLY_TIMESLOTS":
        draft.selectedHourlyTimeslots = action.selectedHourlyTimeslots;
        if (draft.isSelectedAllHourlyTimeslots === true) {
          draft.isSelectedAllHourlyTimeslots = false;
        }
        break;

      case "SET_SELECTED_ALL_HOURLY_TIMESLOTS":
        draft.isSelectedAllHourlyTimeslots =
          action.isSelectedAllHourlyTimeslots;
        draft.selectedHourlyTimeslots = action.selectedHourlyTimeslots;
        break;

      case "SET_SELECTED_DEVICE_HOURLY_SESSIONS":
        draft.selectedDeviceHourlySessions =
          action.selectedDeviceHourlySessions;
        break;

      case "APPEND_SELECTED_DEVICE_HOURLY_SESSIONS": {
        /* Update selectedDeviceHourlySessions using the Upsert API response */
        const newSelectedDeviceHourlySessions =
          updateSelectedDeviceHourlySessions(
            state.selectedDeviceHourlySessions,
            action?.updatedSessions
          );
        draft.selectedDeviceHourlySessions = newSelectedDeviceHourlySessions;
        break;
      }

      case "SET_SELECTED_DEVICE_SCRES_PER_HOUR":
        draft.selectedDeviceScresPerHour = action.deviceScresPerHour;
        break;

      case "RESET_ACTIVE_SESSIONS":
        draft.current = {};
        draft.selectedDeviceHourlySessions = [];
        draft.selectedDeviceScresPerHour = {};
        break;

      case "SET_SESSION_SELECTION_MODE": {
        const { selectionMode } = action;
        const { selectedCameraList } = state;
        draft.selectionMode = selectionMode;
        if (
          selectionMode === cameraSelectionModes.LIST_SELECTION &&
          !isEmpty(selectedCameraList)
        ) {
          draft.selectedDuration = hours[selectedCameraList.duration];
        }
        break;
      }

      case "SET_SESSION_CAMERA_LISTS":
        draft.cameraLists = groupCameraListsByIotHub(action.cameraLists);
        break;

      case "SET_SESSION_SELECTED_CAMERA_LIST": {
        const duration =
          action.selectedCameraList.duration === 1 ? "1 Hour" : "24 Hours";
        draft.selectedCameraList = action.selectedCameraList;
        draft.selectedDuration = duration;
        break;
      }

      default:
        break;
    }
  });
}

/*
 * This function appends selected camera into the organization camaera list array
 */
const appendSelectedCamera = (
  cameraList,
  orgId,
  selectedCamera,
  selectAll = false
) => {
  const orgIsExist = cameraList.some(item => item.org === orgId);
  //If the organization does not exists in our array, create new org object
  if (!orgIsExist) {
    cameraList.push({
      org: orgId,
      orgsCameraList: selectAll
        ? [...selectedCamera]
        : [
            {
              deviceName: selectedCamera.deviceName,
              deviceId: selectedCamera.deviceId,
              orgId: selectedCamera.orgId
            }
          ]
    });
  } else {
    //If the organization already exists in our array, append the selected camera(s) only if it's not exist
    if (selectAll) {
      for (const obj of cameraList) {
        if (obj.org === orgId) {
          obj.orgsCameraList = selectedCamera;
          break;
        }
      }
    } else {
      for (const obj of cameraList) {
        if (obj.org === orgId) {
          !obj.orgsCameraList.some(
            item => item.deviceId === selectedCamera.deviceId
          ) && obj.orgsCameraList.push(selectedCamera);
          break;
        }
      }
    }
  }
  return cameraList;
};

const deleteCamera = (cameraList, orgId, deviceId) => {
  let index = cameraList.findIndex(item => item.org === orgId);
  cameraList[index].orgsCameraList = cameraList[index].orgsCameraList.filter(
    obj => obj.deviceId !== deviceId
  );
  return cameraList;
};

const resetCameraList = (cameraList, orgId) => {
  let index = cameraList.findIndex(item => item.org === orgId);
  return (cameraList[index].orgsCameraList = []);
};

const appendCameraSessions = (sessions, cameraSessions) => {
  // Remove or replace the existing sessions of the selected camera
  const newSessions = sessions.filter(
    session => session.cameraId !== cameraSessions[0].cameraId
  );
  newSessions.push(...cameraSessions);

  return newSessions;
};

const appendCameraSessionEvents = (events, cameraSessionEvents) => {
  // Remove or replace the existing session events of the selected camera
  const newSessions = events.filter(
    event => event.deviceId !== cameraSessionEvents[0].deviceId
  );
  newSessions.push(...cameraSessionEvents);

  return newSessions;
};
