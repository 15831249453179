import { Line } from "react-konva";
import PropTypes from "prop-types";

import { segmentSettings } from "./graphic-settings";

const { stroke, lineJoin, fill, selectedOpacity } = segmentSettings;

export const Segment = ({ segments, disabled = false, opacity = 0.3 }) => {
  return (
    <>
      {segments.map(segment => (
        <Line
          key={segment.id}
          stroke={stroke}
          lineJoin={lineJoin}
          fill={fill}
          opacity={segment.isSelected && !disabled ? selectedOpacity : opacity}
          closed={true}
          points={segment.points.flatMap(point => [point.x, point.y])}
        />
      ))}
    </>
  );
};

Segment.propTypes = {
  disabled: PropTypes.bool,
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      poi: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      deviceId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      sourceTag: PropTypes.string.isRequired,
      distance: PropTypes.string.isRequired,
      points: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.number.isRequired,
          y: PropTypes.number.isRequired
        })
      ).isRequired,
      isSelected: PropTypes.bool.isRequired,
      eventDate: PropTypes.string.isRequired
    })
  )
};
